// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorporaTextData
 */
export interface CorporaTextData {
    /**
     * Corpora text id.
     * @type {number}
     * @memberof CorporaTextData
     */
    textId: number;
    /**
     * Corpora text name.
     * @type {string}
     * @memberof CorporaTextData
     */
    textName: string;
    /**
     * Folder name.
     * @type {Date}
     * @memberof CorporaTextData
     */
    folderName: Date;
}

export function CorporaTextDataFromJSON(json: any): CorporaTextData {
    return CorporaTextDataFromJSONTyped(json, false);
}

export function CorporaTextDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporaTextData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textId': json['textId'],
        'textName': json['textName'],
        'folderName': new Date(json['folderName']),
    };
}

export function CorporaTextDataToJSON(value?: CorporaTextData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'textId': value.textId,
        'textName': value.textName,
        'folderName': value.folderName.toISOString(),
    };
}


