import { RecommendationApi } from "../../client/src";
import BaseClient from "./BaseClient";

export class RecommendationClient extends BaseClient {
  public id = "RecommendationClient";
  private recommendationApi = new RecommendationApi(BaseClient.DEFAULT_CLIENT_CONFIGURATION);

  constructor() {
    super();
    return super.cache(this);
  }

  public async getModelRecommendation() {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.recommendationApi.getModelRecommendation({ authorization: authorizationHeader })
    ));
  }

  public async postModelRecommendation(file: File) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.recommendationApi.postModelRecommendation({
        authorization: authorizationHeader,
        file,
      })
    ));
  }

  public async postSendEmail(recommendation: string, file: File, email: string) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.recommendationApi.postSendEmail({
        authorization: authorizationHeader,
        file,
        email,
        recommendation,
      })
    ));
  }
}
