// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FolderName
 */
export interface FolderName {
    /**
     * Specify the id associated with the folder.
     * @type {number}
     * @memberof FolderName
     */
    folderId: number;
    /**
     * Name of the corpus. Max length: 60 characters. Name should not include ./~\\ symbols.
     * @type {string}
     * @memberof FolderName
     */
    name: string;
}

export function FolderNameFromJSON(json: any): FolderName {
    return FolderNameFromJSONTyped(json, false);
}

export function FolderNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): FolderName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folderId': json['folderId'],
        'name': json['name'],
    };
}

export function FolderNameToJSON(value?: FolderName): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folderId': value.folderId,
        'name': value.name,
    };
}


