// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DownloadCsvFile
 */
export interface DownloadCsvFile {
    /**
     * Type of the model item. Possible item types: folder, entity text, adhoc text, url.
     * @type {string}
     * @memberof DownloadCsvFile
     */
    itemType: string;
    /**
     * Specify the id associated with the entity.
     * @type {number}
     * @memberof DownloadCsvFile
     */
    entityId: number;
    /**
     * Unit parameter for analysis. Possible unit parameters: probability, weight, word.
     * @type {string}
     * @memberof DownloadCsvFile
     */
    unit: string;
    /**
     * Sentiment parameter for analysis. Possible sentiment parameters: combined, separate, positive, negative.
     * @type {string}
     * @memberof DownloadCsvFile
     */
    sentiment: string;
}

export function DownloadCsvFileFromJSON(json: any): DownloadCsvFile {
    return DownloadCsvFileFromJSONTyped(json, false);
}

export function DownloadCsvFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): DownloadCsvFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemType': json['itemType'],
        'entityId': json['entityId'],
        'unit': json['unit'],
        'sentiment': json['sentiment'],
    };
}

export function DownloadCsvFileToJSON(value?: DownloadCsvFile): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemType': value.itemType,
        'entityId': value.entityId,
        'unit': value.unit,
        'sentiment': value.sentiment,
    };
}


