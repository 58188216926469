import React, { FC } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { UNSET_INT, EntityType } from "../../../constants";
import { OpenModalFunction } from "../Modal";
import { ModalType } from "../Modal/constants";
import ReactTooltip from "react-tooltip";

export interface HeaderProps {
  entityType: EntityType;
  modalTypes: ModalType[];
  itemIcon: JSX.Element;
  buttonText: string;
  buttonIcon: JSX.Element;
  plural: string;
  openModalWindow: OpenModalFunction;
  tip: string;
}

const Header: FC<HeaderProps> = ({
  entityType,
  modalTypes,
  itemIcon,
  buttonText,
  buttonIcon,
  plural,
  openModalWindow,
  tip,
}) => (
  <Row>
    <Col md={7}>
      <h2>
        {`${plural.charAt(0).toUpperCase()}${plural.slice(1)} `}
        {itemIcon}
      </h2>
    </Col>
    <Col>
      <div className="table-modal-button">
        {modalTypes.map((modalType, i) =>
          <Button
            data-tip={tip}
            key={`${i}-modal-button`}
            variant="primary"
            className="btn-sm"
            onClick={() => openModalWindow(modalType, entityType, UNSET_INT)}
          >
            {buttonIcon} {buttonText}
            <ReactTooltip />
          </Button>,
        )}
      </div>
    </Col>
  </Row>
);

export default Header;
