// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdhocText,
    AdhocTextFromJSON,
    AdhocTextToJSON,
    AdhocTextContentOutput,
    AdhocTextContentOutputFromJSON,
    AdhocTextContentOutputToJSON,
    AdhocTextOutput,
    AdhocTextOutputFromJSON,
    AdhocTextOutputToJSON,
    AnalysisOutputSuccess,
    AnalysisOutputSuccessFromJSON,
    AnalysisOutputSuccessToJSON,
    CreateModel,
    CreateModelFromJSON,
    CreateModelToJSON,
    Download,
    DownloadFromJSON,
    DownloadToJSON,
    DownloadCsvFile,
    DownloadCsvFileFromJSON,
    DownloadCsvFileToJSON,
    EntityFolderName,
    EntityFolderNameFromJSON,
    EntityFolderNameToJSON,
    EntityId,
    EntityIdFromJSON,
    EntityIdToJSON,
    EntityOutputSuccess,
    EntityOutputSuccessFromJSON,
    EntityOutputSuccessToJSON,
    EntityTextName,
    EntityTextNameFromJSON,
    EntityTextNameToJSON,
    HeatmapOutput,
    HeatmapOutputFromJSON,
    HeatmapOutputToJSON,
    ItemType,
    ItemTypeFromJSON,
    ItemTypeToJSON,
    JoinTextOption,
    JoinTextOptionFromJSON,
    JoinTextOptionToJSON,
    ListOfUrls,
    ListOfUrlsFromJSON,
    ListOfUrlsToJSON,
    ModelAllOutput,
    ModelAllOutputFromJSON,
    ModelAllOutputToJSON,
    ModelEntitiesOutputSuccess,
    ModelEntitiesOutputSuccessFromJSON,
    ModelEntitiesOutputSuccessToJSON,
    ModelError,
    ModelErrorFromJSON,
    ModelErrorToJSON,
    ModelOutputError,
    ModelOutputErrorFromJSON,
    ModelOutputErrorToJSON,
    ModelOutputSuccess,
    ModelOutputSuccessFromJSON,
    ModelOutputSuccessToJSON,
    PredictOutputError,
    PredictOutputErrorFromJSON,
    PredictOutputErrorToJSON,
    RenameModel,
    RenameModelFromJSON,
    RenameModelToJSON,
    TextEntityOutputError,
    TextEntityOutputErrorFromJSON,
    TextEntityOutputErrorToJSON,
    TextEntityOutputSuccess,
    TextEntityOutputSuccessFromJSON,
    TextEntityOutputSuccessToJSON,
    TokenExpiredOutput,
    TokenExpiredOutputFromJSON,
    TokenExpiredOutputToJSON,
    TypeOfModelItem,
    TypeOfModelItemFromJSON,
    TypeOfModelItemToJSON,
    UrlAllInfoOutput,
    UrlAllInfoOutputFromJSON,
    UrlAllInfoOutputToJSON,
    UrlName,
    UrlNameFromJSON,
    UrlNameToJSON,
} from '../models';

export interface DeleteModelAdhocTextAdhocTextIdRequest {
    adhocTextId: number;
    authorization: string;
}

export interface DeleteModelFolderItemEntityIdRequest {
    entityId: number;
    authorization: string;
}

export interface DeleteModelModelIdRequest {
    modelId: number;
    authorization: string;
}

export interface DeleteModelTextItemEntityIdRequest {
    entityId: number;
    authorization: string;
}

export interface DeleteModelUrlOperationsRequest {
    authorization: string;
    payload: EntityId;
}

export interface GetModelAdhocTextAdhocTextIdRequest {
    adhocTextId: number;
    authorization: string;
}

export interface GetModelModelIdRequest {
    modelId: number;
    authorization: string;
}

export interface GetModelOperationsRequest {
    authorization: string;
}

export interface GetModelUrlInfoRequest {
    entityId: number;
    authorization: string;
}

export interface PostModelAdhocTextModelIdRequest {
    modelId: number;
    authorization: string;
    payload: AdhocText;
}

export interface PostModelDownloadPredictionRequest {
    authorization: string;
    payload: Array<DownloadCsvFile>;
}

export interface PostModelFolderItemRequest {
    authorization: string;
    payload: EntityFolderName;
}

export interface PostModelHeatmapRequest {
    entityId: number;
    authorization: string;
    payload: ItemType;
}

export interface PostModelOperationsRequest {
    authorization: string;
    payload: CreateModel;
}

export interface PostModelPredictRequest {
    entityId: number;
    authorization: string;
    payload: TypeOfModelItem;
}

export interface PostModelTextItemModelIdRequest {
    modelId: number;
    authorization: string;
    payload: JoinTextOption;
}

export interface PostModelUrlModelIdRequest {
    modelId: number;
    authorization: string;
    payload: ListOfUrls;
}

export interface PutModelAdhocTextAdhocTextIdRequest {
    adhocTextId: number;
    authorization: string;
    payload: AdhocText;
}

export interface PutModelFolderItemEntityIdRequest {
    entityId: number;
    authorization: string;
    payload: EntityTextName;
}

export interface PutModelOperationsRequest {
    authorization: string;
    payload: RenameModel;
}

export interface PutModelTextItemEntityIdRequest {
    entityId: number;
    authorization: string;
    payload: EntityTextName;
}

export interface PutModelUrlOperationsRequest {
    authorization: string;
    payload: UrlName;
}

/**
 * no description
 */
export class ModelApi extends runtime.BaseAPI {

    /**
     * Returns info about deleted adhoc text.
     * Delete specific adhoc text
     */
    async deleteModelAdhocTextAdhocTextIdRaw(requestParameters: DeleteModelAdhocTextAdhocTextIdRequest): Promise<runtime.ApiResponse<AdhocTextOutput>> {
        if (requestParameters.adhocTextId === null || requestParameters.adhocTextId === undefined) {
            throw new runtime.RequiredError('adhocTextId','Required parameter requestParameters.adhocTextId was null or undefined when calling deleteModelAdhocTextAdhocTextId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteModelAdhocTextAdhocTextId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/adhoc_texts/{adhocTextId}`.replace(`{${"adhocTextId"}}`, encodeURIComponent(String(requestParameters.adhocTextId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdhocTextOutputFromJSON(jsonValue));
    }

   /**
    * Returns info about deleted adhoc text.
    * Delete specific adhoc text
    */
    async deleteModelAdhocTextAdhocTextId(requestParameters: DeleteModelAdhocTextAdhocTextIdRequest): Promise<AdhocTextOutput> {
        const response = await this.deleteModelAdhocTextAdhocTextIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about deleted corpora text item in the model.
     * Delete specific corpora folder item in the model
     */
    async deleteModelFolderItemEntityIdRaw(requestParameters: DeleteModelFolderItemEntityIdRequest): Promise<runtime.ApiResponse<EntityOutputSuccess>> {
        if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
            throw new runtime.RequiredError('entityId','Required parameter requestParameters.entityId was null or undefined when calling deleteModelFolderItemEntityId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteModelFolderItemEntityId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/folder_entities/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters.entityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about deleted corpora text item in the model.
    * Delete specific corpora folder item in the model
    */
    async deleteModelFolderItemEntityId(requestParameters: DeleteModelFolderItemEntityIdRequest): Promise<EntityOutputSuccess> {
        const response = await this.deleteModelFolderItemEntityIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about deleted model.
     * Delete specific model
     */
    async deleteModelModelIdRaw(requestParameters: DeleteModelModelIdRequest): Promise<runtime.ApiResponse<ModelOutputSuccess>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteModelModelId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteModelModelId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about deleted model.
    * Delete specific model
    */
    async deleteModelModelId(requestParameters: DeleteModelModelIdRequest): Promise<ModelOutputSuccess> {
        const response = await this.deleteModelModelIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about deleted corpora text item in the model.
     * Delete specific corpora text item in the model
     */
    async deleteModelTextItemEntityIdRaw(requestParameters: DeleteModelTextItemEntityIdRequest): Promise<runtime.ApiResponse<EntityOutputSuccess>> {
        if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
            throw new runtime.RequiredError('entityId','Required parameter requestParameters.entityId was null or undefined when calling deleteModelTextItemEntityId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteModelTextItemEntityId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/text_entities/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters.entityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about deleted corpora text item in the model.
    * Delete specific corpora text item in the model
    */
    async deleteModelTextItemEntityId(requestParameters: DeleteModelTextItemEntityIdRequest): Promise<EntityOutputSuccess> {
        const response = await this.deleteModelTextItemEntityIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about deleted url item.
     * Delete specific url item
     */
    async deleteModelUrlOperationsRaw(requestParameters: DeleteModelUrlOperationsRequest): Promise<runtime.ApiResponse<EntityOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteModelUrlOperations.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling deleteModelUrlOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/url/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: EntityIdToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about deleted url item.
    * Delete specific url item
    */
    async deleteModelUrlOperations(requestParameters: DeleteModelUrlOperationsRequest): Promise<EntityOutputSuccess> {
        const response = await this.deleteModelUrlOperationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns adhoc text content and info.
     * Get content and info about specific adhoc text
     */
    async getModelAdhocTextAdhocTextIdRaw(requestParameters: GetModelAdhocTextAdhocTextIdRequest): Promise<runtime.ApiResponse<AdhocTextContentOutput>> {
        if (requestParameters.adhocTextId === null || requestParameters.adhocTextId === undefined) {
            throw new runtime.RequiredError('adhocTextId','Required parameter requestParameters.adhocTextId was null or undefined when calling getModelAdhocTextAdhocTextId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getModelAdhocTextAdhocTextId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/adhoc_texts/{adhocTextId}`.replace(`{${"adhocTextId"}}`, encodeURIComponent(String(requestParameters.adhocTextId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdhocTextContentOutputFromJSON(jsonValue));
    }

   /**
    * Returns adhoc text content and info.
    * Get content and info about specific adhoc text
    */
    async getModelAdhocTextAdhocTextId(requestParameters: GetModelAdhocTextAdhocTextIdRequest): Promise<AdhocTextContentOutput> {
        const response = await this.getModelAdhocTextAdhocTextIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns all entities in model: corpora folder, corpora text, adhoc text, url.
     * Get model entities
     */
    async getModelModelIdRaw(requestParameters: GetModelModelIdRequest): Promise<runtime.ApiResponse<ModelEntitiesOutputSuccess>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getModelModelId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getModelModelId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelEntitiesOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns all entities in model: corpora folder, corpora text, adhoc text, url.
    * Get model entities
    */
    async getModelModelId(requestParameters: GetModelModelIdRequest): Promise<ModelEntitiesOutputSuccess> {
        const response = await this.getModelModelIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns user\'s models (all models for admin).
     * Get user\'s models
     */
    async getModelOperationsRaw(requestParameters: GetModelOperationsRequest): Promise<runtime.ApiResponse<ModelAllOutput>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getModelOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelAllOutputFromJSON(jsonValue));
    }

   /**
    * Returns user\'s models (all models for admin).
    * Get user\'s models
    */
    async getModelOperations(requestParameters: GetModelOperationsRequest): Promise<ModelAllOutput> {
        const response = await this.getModelOperationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about specific url item.
     * Get info about specific url item
     */
    async getModelUrlInfoRaw(requestParameters: GetModelUrlInfoRequest): Promise<runtime.ApiResponse<UrlAllInfoOutput>> {
        if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
            throw new runtime.RequiredError('entityId','Required parameter requestParameters.entityId was null or undefined when calling getModelUrlInfo.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getModelUrlInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/url/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters.entityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UrlAllInfoOutputFromJSON(jsonValue));
    }

   /**
    * Returns info about specific url item.
    * Get info about specific url item
    */
    async getModelUrlInfo(requestParameters: GetModelUrlInfoRequest): Promise<UrlAllInfoOutput> {
        const response = await this.getModelUrlInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about created item in the model.
     * Create adhoc text item in the model
     */
    async postModelAdhocTextModelIdRaw(requestParameters: PostModelAdhocTextModelIdRequest): Promise<runtime.ApiResponse<AdhocTextOutput>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling postModelAdhocTextModelId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelAdhocTextModelId.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postModelAdhocTextModelId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/adhoc_texts/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdhocTextToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdhocTextOutputFromJSON(jsonValue));
    }

   /**
    * Returns info about created item in the model.
    * Create adhoc text item in the model
    */
    async postModelAdhocTextModelId(requestParameters: PostModelAdhocTextModelIdRequest): Promise<AdhocTextOutput> {
        const response = await this.postModelAdhocTextModelIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns prediction statistic for different item types: folder, entity text, adhoc text, url.
     * Get prediction statistic for item
     */
    async postModelDownloadPredictionRaw(requestParameters: PostModelDownloadPredictionRequest): Promise<runtime.ApiResponse<Download>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelDownloadPrediction.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postModelDownloadPrediction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/predict/download/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.payload.map(DownloadCsvFileToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadFromJSON(jsonValue));
    }

   /**
    * Returns prediction statistic for different item types: folder, entity text, adhoc text, url.
    * Get prediction statistic for item
    */
    async postModelDownloadPrediction(requestParameters: PostModelDownloadPredictionRequest): Promise<Download> {
        const response = await this.postModelDownloadPredictionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about created item in the model.
     * Create folder item in the model
     */
    async postModelFolderItemRaw(requestParameters: PostModelFolderItemRequest): Promise<runtime.ApiResponse<EntityOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelFolderItem.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postModelFolderItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/folder_entities/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityFolderNameToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about created item in the model.
    * Create folder item in the model
    */
    async postModelFolderItem(requestParameters: PostModelFolderItemRequest): Promise<EntityOutputSuccess> {
        const response = await this.postModelFolderItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns heatmap analysis for different item types: folder, entity text, adhoc text, url.
     * Create heatmap for item
     */
    async postModelHeatmapRaw(requestParameters: PostModelHeatmapRequest): Promise<runtime.ApiResponse<HeatmapOutput>> {
        if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
            throw new runtime.RequiredError('entityId','Required parameter requestParameters.entityId was null or undefined when calling postModelHeatmap.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelHeatmap.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postModelHeatmap.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/heatmap/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters.entityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemTypeToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HeatmapOutputFromJSON(jsonValue));
    }

   /**
    * Returns heatmap analysis for different item types: folder, entity text, adhoc text, url.
    * Create heatmap for item
    */
    async postModelHeatmap(requestParameters: PostModelHeatmapRequest): Promise<HeatmapOutput> {
        const response = await this.postModelHeatmapRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns model info if creating is successful.
     * Create model
     */
    async postModelOperationsRaw(requestParameters: PostModelOperationsRequest): Promise<runtime.ApiResponse<ModelOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelOperations.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postModelOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateModelToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns model info if creating is successful.
    * Create model
    */
    async postModelOperations(requestParameters: PostModelOperationsRequest): Promise<ModelOutputSuccess> {
        const response = await this.postModelOperationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns prediction for different item types: folder, entity text, adhoc text, url.
     * Make prediction for item
     */
    async postModelPredictRaw(requestParameters: PostModelPredictRequest): Promise<runtime.ApiResponse<AnalysisOutputSuccess>> {
        if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
            throw new runtime.RequiredError('entityId','Required parameter requestParameters.entityId was null or undefined when calling postModelPredict.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelPredict.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postModelPredict.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/predict/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters.entityId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeOfModelItemToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns prediction for different item types: folder, entity text, adhoc text, url.
    * Make prediction for item
    */
    async postModelPredict(requestParameters: PostModelPredictRequest): Promise<AnalysisOutputSuccess> {
        const response = await this.postModelPredictRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about created item in the model.
     * Create corpora text item in the model
     */
    async postModelTextItemModelIdRaw(requestParameters: PostModelTextItemModelIdRequest): Promise<runtime.ApiResponse<TextEntityOutputSuccess>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling postModelTextItemModelId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelTextItemModelId.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postModelTextItemModelId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/text_entities/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JoinTextOptionToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TextEntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about created item in the model.
    * Create corpora text item in the model
    */
    async postModelTextItemModelId(requestParameters: PostModelTextItemModelIdRequest): Promise<TextEntityOutputSuccess> {
        const response = await this.postModelTextItemModelIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about created item in the model.
     * Create url item in the model
     */
    async postModelUrlModelIdRaw(requestParameters: PostModelUrlModelIdRequest): Promise<runtime.ApiResponse<TextEntityOutputSuccess>> {
        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling postModelUrlModelId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelUrlModelId.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postModelUrlModelId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/url/{modelId}`.replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListOfUrlsToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TextEntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about created item in the model.
    * Create url item in the model
    */
    async postModelUrlModelId(requestParameters: PostModelUrlModelIdRequest): Promise<TextEntityOutputSuccess> {
        const response = await this.postModelUrlModelIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about updated adhoc text.
     * Update content or name of specific adhoc text
     */
    async putModelAdhocTextAdhocTextIdRaw(requestParameters: PutModelAdhocTextAdhocTextIdRequest): Promise<runtime.ApiResponse<AdhocTextContentOutput>> {
        if (requestParameters.adhocTextId === null || requestParameters.adhocTextId === undefined) {
            throw new runtime.RequiredError('adhocTextId','Required parameter requestParameters.adhocTextId was null or undefined when calling putModelAdhocTextAdhocTextId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling putModelAdhocTextAdhocTextId.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling putModelAdhocTextAdhocTextId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/adhoc_texts/{adhocTextId}`.replace(`{${"adhocTextId"}}`, encodeURIComponent(String(requestParameters.adhocTextId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdhocTextToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdhocTextContentOutputFromJSON(jsonValue));
    }

   /**
    * Returns info about updated adhoc text.
    * Update content or name of specific adhoc text
    */
    async putModelAdhocTextAdhocTextId(requestParameters: PutModelAdhocTextAdhocTextIdRequest): Promise<AdhocTextContentOutput> {
        const response = await this.putModelAdhocTextAdhocTextIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about renamed entity folder item in the model.
     * Rename specific entity folder item in the model
     */
    async putModelFolderItemEntityIdRaw(requestParameters: PutModelFolderItemEntityIdRequest): Promise<runtime.ApiResponse<EntityOutputSuccess>> {
        if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
            throw new runtime.RequiredError('entityId','Required parameter requestParameters.entityId was null or undefined when calling putModelFolderItemEntityId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling putModelFolderItemEntityId.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling putModelFolderItemEntityId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/folder_entities/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters.entityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EntityTextNameToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about renamed entity folder item in the model.
    * Rename specific entity folder item in the model
    */
    async putModelFolderItemEntityId(requestParameters: PutModelFolderItemEntityIdRequest): Promise<EntityOutputSuccess> {
        const response = await this.putModelFolderItemEntityIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns model info if renaming is successful.
     * Rename model
     */
    async putModelOperationsRaw(requestParameters: PutModelOperationsRequest): Promise<runtime.ApiResponse<ModelOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling putModelOperations.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling putModelOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RenameModelToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns model info if renaming is successful.
    * Rename model
    */
    async putModelOperations(requestParameters: PutModelOperationsRequest): Promise<ModelOutputSuccess> {
        const response = await this.putModelOperationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about renamed entity text item in the model.
     * Rename specific entity text item in the model
     */
    async putModelTextItemEntityIdRaw(requestParameters: PutModelTextItemEntityIdRequest): Promise<runtime.ApiResponse<EntityOutputSuccess>> {
        if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
            throw new runtime.RequiredError('entityId','Required parameter requestParameters.entityId was null or undefined when calling putModelTextItemEntityId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling putModelTextItemEntityId.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling putModelTextItemEntityId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/text_entities/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters.entityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EntityTextNameToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about renamed entity text item in the model.
    * Rename specific entity text item in the model
    */
    async putModelTextItemEntityId(requestParameters: PutModelTextItemEntityIdRequest): Promise<EntityOutputSuccess> {
        const response = await this.putModelTextItemEntityIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns info about renamed url item.
     * Rename specific url item
     */
    async putModelUrlOperationsRaw(requestParameters: PutModelUrlOperationsRequest): Promise<runtime.ApiResponse<EntityOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling putModelUrlOperations.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling putModelUrlOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/models/url/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UrlNameToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about renamed url item.
    * Rename specific url item
    */
    async putModelUrlOperations(requestParameters: PutModelUrlOperationsRequest): Promise<EntityOutputSuccess> {
        const response = await this.putModelUrlOperationsRaw(requestParameters);
        return await response.value();
    }

}
