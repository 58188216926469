// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PredictData,
    PredictDataFromJSON,
    PredictDataFromJSONTyped,
    PredictDataToJSON,
    SingleRecommendationData,
    SingleRecommendationDataFromJSON,
    SingleRecommendationDataFromJSONTyped,
    SingleRecommendationDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnalysisData
 */
export interface AnalysisData {
    /**
     * 
     * @type {PredictData}
     * @memberof AnalysisData
     */
    predict: PredictData;
    /**
     * 
     * @type {Array<SingleRecommendationData>}
     * @memberof AnalysisData
     */
    recommendations: Array<SingleRecommendationData>;
}

export function AnalysisDataFromJSON(json: any): AnalysisData {
    return AnalysisDataFromJSONTyped(json, false);
}

export function AnalysisDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'predict': PredictDataFromJSON(json['predict']),
        'recommendations': (json['recommendations'] as Array<any>).map(SingleRecommendationDataFromJSON),
    };
}

export function AnalysisDataToJSON(value?: AnalysisData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'predict': PredictDataToJSON(value.predict),
        'recommendations': (value.recommendations as Array<any>).map(SingleRecommendationDataToJSON),
    };
}


