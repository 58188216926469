import { Navigation } from "../../common/Navigation";
import { Container, Table as T } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import React, { Component } from "react";
import { CorporaClient, linkName, isNotEmptyObject, isSuccessfulResponse } from "../../../utility";
import { CorporaRouterProps, CorporaState } from "./index";

class ListOfCorporaDocuments extends Component<RouteComponentProps<CorporaRouterProps>> {
  public state: CorporaState = {
    corpus: {},
  };

  public componentDidMount(): void {
    const corporaClient = new CorporaClient();
    const folderId = parseInt(this.props.match.params.corpus, 10);
    corporaClient.getListOfDocuments(folderId).then((response: any) => {
      if (isSuccessfulResponse(response)) {
        const corpus = response.data;
        this.setState({
          corpus,
        });
      }
    });
  }

  public render() {
    const corpus = this.state.corpus;
    const texts = corpus.texts;
    return (
      <Container>
        {isNotEmptyObject(corpus) &&
        <>
          <Navigation
            {...this.props}
            elementName={(url, index) => index === 1 ? corpus.folderName : linkName(url[index])}
          />
          <h1>Documents in {corpus.folderName}</h1>
          <T size="sm">
            <thead>
            <tr>
              <th className="list_of_documents">Name</th>
            </tr>
            </thead>
            <tbody>
            {texts && texts.length > 0 && texts.map((item) => {
              return (
                <tr key={item.textId}>
                  <td className="list_of_documents">
                    <Link to={`/corpora/${corpus.folderId}/documents/${item.textId}`}>{item.textName}</Link>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </T>
        </>}
      </Container>
    );
  }
}

export default ListOfCorporaDocuments;
