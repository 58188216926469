// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendEmail
 */
export interface SendEmail {
    /**
     * There is not any data.
     * @type {string}
     * @memberof SendEmail
     */
    data: string;
    /**
     * Error message.
     * @type {string}
     * @memberof SendEmail
     */
    errors: string;
}

export function SendEmailFromJSON(json: any): SendEmail {
    return SendEmailFromJSONTyped(json, false);
}

export function SendEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
        'errors': json['errors'],
    };
}

export function SendEmailToJSON(value?: SendEmail): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'errors': value.errors,
    };
}


