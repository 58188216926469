// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendEmailReset
 */
export interface SendEmailReset {
    /**
     * Email. Max length: 120 characters.
     * @type {string}
     * @memberof SendEmailReset
     */
    email: string;
}

export function SendEmailResetFromJSON(json: any): SendEmailReset {
    return SendEmailResetFromJSONTyped(json, false);
}

export function SendEmailResetFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendEmailReset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function SendEmailResetToJSON(value?: SendEmailReset): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}


