import React, { Component, SyntheticEvent } from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Container, Form, Row } from "react-bootstrap";
import LandingHeader from "../Landing/Header";
import {
  ResetPassErrors as ResetPassModel,
  isValidForm,
  UserClient, authenticationService, isSuccessfulResponse,
} from "../../../utility";
import FormElement, { FormElementProps } from "../../common/form/FormElement";
import { RouteComponentProps } from "react-router";
import Feedback from "../../common/form/Feedback";
import { ButtonRef } from "../../common/buttons";
import { ROUTE } from "../../../constants";
import { LandingFormType } from "../Landing/constants";

interface ResetPassErrors extends ResetPassModel {
  password?: string;
  repeatedPassword?: string;
  form?: string;
}

interface ResetPassState {
  data: any;
  validated: boolean;
  errors: ResetPassErrors;
  success: boolean;
}

export default class ResetPass extends Component<RouteComponentProps, ResetPassState> {

  public readonly state: ResetPassState = {
    data: {
      token: this.props.location.search.split("=")[1],
      password: "",
      repeatedPassword: "",
    },
    validated: false,
    errors: {},
    success: false,
  };

  public handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = {
      ...this.state.errors,
      form: this.state.data.password !== this.state.data.repeatedPassword ? "The passwords do not match" : "",
    };
    const userClient = new UserClient();
    this.setState({
      validated: !errors.form,
      errors,
    });

    if (isValidForm(event.currentTarget, errors)) {
      userClient.postResetWithToken(this.state.data).then(async (response) => {
        if (isSuccessfulResponse(response)) {
          await authenticationService.loginUser(response.data);
          this.setState({
            success: true,
          });
        } else {
          this.setState({
            errors: {
              ...this.state.errors,
              form: response.message ? response.message : response.errors,
            },
          });
        }
      });
    }
  }

  public handleChange = (event: SyntheticEvent<FormElementProps<keyof ResetPassState>>) => {
    const inputName = event.currentTarget.name;
    const inputValue = event.currentTarget.value;
    this.setState({
      data: {
        ...this.state.data,
        [inputName]: inputValue,
      },
      errors: {
        ...this.state.errors,
        [inputName]: !inputValue ? "The field is required" : "",
      },
    });
  }

  public render() {
    const {
      data,
      errors,
      validated,
      success,
    } = this.state;

    return (
      <>
        <LandingHeader />
        <Container>
          <Row className="justify-content-center forgot_pass_title">
            <p><strong>
              {success ? "Your password successfully changed" : "Please make up new password"}
            </strong></p>
          </Row>
          <Row className="justify-content-center">
            {success ? <ButtonRef to={ROUTE.ROOT} variant="secondary">Back to homepage</ButtonRef> :
              <Col md={6}>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Row>
                    <FormElement
                      groupAs={Col}
                      name="password"
                      label="New password"
                      errors={errors.password}
                      value={data.password}
                      handleChange={this.handleChange}
                      type="password"
                      required={true}
                      isValid={validated ? !errors.password : undefined}
                    />
                  </Form.Row>
                  <Form.Row>
                    <FormElement
                      groupAs={Col}
                      name="repeatedPassword"
                      label="Repeat new password"
                      errors={errors.repeatedPassword}
                      value={data.repeatedPassword}
                      handleChange={this.handleChange}
                      type="password"
                      required={true}
                      isValid={validated ? !errors.repeatedPassword : undefined}
                    />
                  </Form.Row>
                  <Form.Row className="forgot_password_padding">
                    <Col>
                      <Feedback valid={false} errors={errors.form} forceShow={true} />
                    </Col>
                  </Form.Row>
                  <ButtonToolbar className="justify-content-center">
                    <ButtonGroup>
                      <ButtonRef to={ROUTE.ROOT} variant="secondary">Cancel</ButtonRef>
                      <Button variant="primary" type="submit">
                        {LandingFormType.RESET_PASS}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Form>
              </Col>}
          </Row>
        </Container>
      </>
    );
  }
}
