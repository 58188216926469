// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DictionaryOutputError,
    DictionaryOutputErrorFromJSON,
    DictionaryOutputErrorToJSON,
    DictionaryOutputSuccess,
    DictionaryOutputSuccessFromJSON,
    DictionaryOutputSuccessToJSON,
    TokenExpiredOutput,
    TokenExpiredOutputFromJSON,
    TokenExpiredOutputToJSON,
} from '../models';

export interface GetCorporaDictionaryRequest {
    authorization: string;
}

export interface PostCorporaDictionaryRequest {
    authorization: string;
    file: Blob;
}

/**
 * no description
 */
export class DictionaryApi extends runtime.BaseAPI {

    /**
     * Returns dictionary info.
     * Get dictionary (admin)
     */
    async getCorporaDictionaryRaw(requestParameters: GetCorporaDictionaryRequest): Promise<runtime.ApiResponse<DictionaryOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCorporaDictionary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/dictionaries/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DictionaryOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns dictionary info.
    * Get dictionary (admin)
    */
    async getCorporaDictionary(requestParameters: GetCorporaDictionaryRequest): Promise<DictionaryOutputSuccess> {
        const response = await this.getCorporaDictionaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns dictionary info if creation is successful.
     * Create dictionary (admin)
     */
    async postCorporaDictionaryRaw(requestParameters: PostCorporaDictionaryRequest): Promise<runtime.ApiResponse<DictionaryOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postCorporaDictionary.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling postCorporaDictionary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/dictionaries/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DictionaryOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns dictionary info if creation is successful.
    * Create dictionary (admin)
    */
    async postCorporaDictionary(requestParameters: PostCorporaDictionaryRequest): Promise<DictionaryOutputSuccess> {
        const response = await this.postCorporaDictionaryRaw(requestParameters);
        return await response.value();
    }

}
