// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RefreshTokenData,
    RefreshTokenDataFromJSON,
    RefreshTokenDataFromJSONTyped,
    RefreshTokenDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface RefreshTokenOutput
 */
export interface RefreshTokenOutput {
    /**
     * 
     * @type {RefreshTokenData}
     * @memberof RefreshTokenOutput
     */
    data: RefreshTokenData;
    /**
     * There are not any errors.
     * @type {string}
     * @memberof RefreshTokenOutput
     */
    errors: string;
}

export function RefreshTokenOutputFromJSON(json: any): RefreshTokenOutput {
    return RefreshTokenOutputFromJSONTyped(json, false);
}

export function RefreshTokenOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': RefreshTokenDataFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function RefreshTokenOutputToJSON(value?: RefreshTokenOutput): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': RefreshTokenDataToJSON(value.data),
        'errors': value.errors,
    };
}


