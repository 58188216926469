import React from "react";
import { Container } from "react-bootstrap";

export function NoMatch({ location }: any) {
  return (
    <Container>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </Container>
  );
}
