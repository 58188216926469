// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnalysisOutputError,
    AnalysisOutputErrorFromJSON,
    AnalysisOutputErrorToJSON,
    AnalysisOutputSuccess,
    AnalysisOutputSuccessFromJSON,
    AnalysisOutputSuccessToJSON,
    AnalyzerData,
    AnalyzerDataFromJSON,
    AnalyzerDataToJSON,
    TokenExpiredOutput,
    TokenExpiredOutputFromJSON,
    TokenExpiredOutputToJSON,
} from '../models';

export interface PostTextAnalyzerRequest {
    authorization: string;
    payload: AnalyzerData;
}

/**
 * no description
 */
export class AnalyzerApi extends runtime.BaseAPI {

    /**
     * Returns result of analysis for texts or urls.
     * Content Analyzer
     */
    async postTextAnalyzerRaw(requestParameters: PostTextAnalyzerRequest): Promise<runtime.ApiResponse<AnalysisOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postTextAnalyzer.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postTextAnalyzer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/analyzer/prediction/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyzerDataToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns result of analysis for texts or urls.
    * Content Analyzer
    */
    async postTextAnalyzer(requestParameters: PostTextAnalyzerRequest): Promise<AnalysisOutputSuccess> {
        const response = await this.postTextAnalyzerRaw(requestParameters);
        return await response.value();
    }

}
