// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorporaTextInfo
 */
export interface CorporaTextInfo {
    /**
     * Corpora text id.
     * @type {number}
     * @memberof CorporaTextInfo
     */
    textId: number;
    /**
     * Corpora text name.
     * @type {string}
     * @memberof CorporaTextInfo
     */
    textName: string;
}

export function CorporaTextInfoFromJSON(json: any): CorporaTextInfo {
    return CorporaTextInfoFromJSONTyped(json, false);
}

export function CorporaTextInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporaTextInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textId': json['textId'],
        'textName': json['textName'],
    };
}

export function CorporaTextInfoToJSON(value?: CorporaTextInfo): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'textId': value.textId,
        'textName': value.textName,
    };
}


