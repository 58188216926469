// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TypeOfModelItem
 */
export interface TypeOfModelItem {
    /**
     * Type of the model item.Possible item types: folder, entity text, adhoc text, url.
     * @type {string}
     * @memberof TypeOfModelItem
     */
    itemType: string;
    /**
     * Unit parameter for analysis. Possible unit parameters: probability, weight, word.
     * @type {string}
     * @memberof TypeOfModelItem
     */
    unit: string;
    /**
     * Sentiment parameter for analysis. Possible sentiment parameters: combined, separate, positive, negative.
     * @type {string}
     * @memberof TypeOfModelItem
     */
    sentiment: string;
}

export function TypeOfModelItemFromJSON(json: any): TypeOfModelItem {
    return TypeOfModelItemFromJSONTyped(json, false);
}

export function TypeOfModelItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeOfModelItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemType': json['itemType'],
        'unit': json['unit'],
        'sentiment': json['sentiment'],
    };
}

export function TypeOfModelItemToJSON(value?: TypeOfModelItem): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemType': value.itemType,
        'unit': value.unit,
        'sentiment': value.sentiment,
    };
}


