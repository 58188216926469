import React, { Component, SyntheticEvent } from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Container, Form, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../../../constants";
import {
  UserClient,
  Login as LoginModel,
  LoginErrors as LoginErrorsModel,
  authenticationService,
  isValidForm,
  isNotEmptyObject,
} from "../../../utility";
import { LandingFormType } from "../Landing/constants";
import LandingHeader from "../Landing/Header";
import FormElement, { FormElementProps } from "../../common/form/FormElement";
import { ButtonRef } from "../../common";
import Feedback from "../../common/form/Feedback";

interface LoginErrors extends LoginErrorsModel {
  login?: string;
}

interface LoginState {
  data: LoginModel;

  validated: boolean;
  errors: LoginErrors;
}

export default class Login extends Component<RouteComponentProps, LoginState> {
  public readonly state: LoginState = {
    data: {
      email: "",
      password: "",
    },
    validated: false,
    errors: {},
  };

  public handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = {
        ...this.state.errors,
        login: "",
    };
    const userClient = new UserClient();
    this.setState({
      validated: true,
      errors,
    });

    if (isValidForm(event.currentTarget, errors)) {
      userClient.login(this.state.data).then(async (response) => {
        if (isNotEmptyObject(response.errors)) {
          this.setState({
            errors: {
              ...this.state.errors,
              ...response.errors,
            },
          });
        } else {
          await authenticationService.loginUser(response.data);
          this.props.history.push(ROUTE.ROOT);
        }
      });
    }
  }

  public handleChange = (event: SyntheticEvent<FormElementProps<keyof LoginState>>) => {
    const inputName = event.currentTarget.name;
    const inputValue = event.currentTarget.value;
    this.setState({
      data: {
        ...this.state.data,
        [inputName]: inputValue,
      },
      errors: {
        ...this.state.errors,
        [inputName]: !inputValue ? "The field is required" : "",
      },
    });
  }

  public render() {
    const {
      data,
      errors,
      validated,
    } = this.state;

    return (
      <>
        <LandingHeader action="login" />
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                  <FormElement
                    groupAs={Col}
                    name="email"
                    label="Email"
                    errors={errors.email}
                    value={data.email}
                    handleChange={this.handleChange}
                    type="email"
                    required={true}
                    isValid={validated ? !errors.email : undefined}
                  />
                </Form.Row>
                <Form.Row>
                  <FormElement
                    groupAs={Col}
                    name="password"
                    label="Password"
                    errors={errors.password}
                    value={data.password}
                    handleChange={this.handleChange}
                    type="password"
                    required={true}
                    isValid={validated ? !errors.password : undefined}
                  />
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Feedback valid={false} errors={errors.login} forceShow={true} />
                  </Col>
                </Form.Row>
                <Form.Row className="forgot_pass">
                  <Col>
                    <ButtonRef
                      to={ROUTE.FORGOT_PASS}
                      variant="link"
                      size="sm"
                    >
                      Forgot password?
                  </ButtonRef>
                  </Col>
                </Form.Row>
                <ButtonToolbar className="justify-content-end">
                  <ButtonGroup>
                    <ButtonRef to={ROUTE.ROOT} variant="secondary">Cancel</ButtonRef>
                    <Button variant="primary" type="submit">
                      {LandingFormType.LOGIN}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
