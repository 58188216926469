// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FolderAllOutputSuccess,
    FolderAllOutputSuccessFromJSON,
    FolderAllOutputSuccessToJSON,
    FolderInfoOutputError,
    FolderInfoOutputErrorFromJSON,
    FolderInfoOutputErrorToJSON,
    FolderInfoSuccess,
    FolderInfoSuccessFromJSON,
    FolderInfoSuccessToJSON,
    FolderName,
    FolderNameFromJSON,
    FolderNameToJSON,
    FolderOutputError,
    FolderOutputErrorFromJSON,
    FolderOutputErrorToJSON,
    FolderOutputSuccess,
    FolderOutputSuccessFromJSON,
    FolderOutputSuccessToJSON,
    TokenExpiredOutput,
    TokenExpiredOutputFromJSON,
    TokenExpiredOutputToJSON,
} from '../models';

export interface DeleteCorporaFolderFolderIdRequest {
    folderId: number;
    authorization: string;
}

export interface GetCorporaFolderFolderIdRequest {
    folderId: number;
    authorization: string;
}

export interface GetCorporaFolderOperationsRequest {
    authorization: string;
}

export interface PostCorporaFolderOperationsRequest {
    authorization: string;
    name: string;
    files: Array<Blob>;
}

export interface PutCorporaFolderOperationsRequest {
    authorization: string;
    payload: FolderName;
}

/**
 * no description
 */
export class FolderApi extends runtime.BaseAPI {

    /**
     * Returns info about deleted corpora folder.
     * Delete specific corpora folder
     */
    async deleteCorporaFolderFolderIdRaw(requestParameters: DeleteCorporaFolderFolderIdRequest): Promise<runtime.ApiResponse<FolderOutputSuccess>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling deleteCorporaFolderFolderId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCorporaFolderFolderId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/folders/{folderId}`.replace(`{${"folderId"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about deleted corpora folder.
    * Delete specific corpora folder
    */
    async deleteCorporaFolderFolderId(requestParameters: DeleteCorporaFolderFolderIdRequest): Promise<FolderOutputSuccess> {
        const response = await this.deleteCorporaFolderFolderIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns corpora folder info.
     * Get info about specific corpora folder
     */
    async getCorporaFolderFolderIdRaw(requestParameters: GetCorporaFolderFolderIdRequest): Promise<runtime.ApiResponse<FolderInfoSuccess>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling getCorporaFolderFolderId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCorporaFolderFolderId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/folders/{folderId}`.replace(`{${"folderId"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderInfoSuccessFromJSON(jsonValue));
    }

   /**
    * Returns corpora folder info.
    * Get info about specific corpora folder
    */
    async getCorporaFolderFolderId(requestParameters: GetCorporaFolderFolderIdRequest): Promise<FolderInfoSuccess> {
        const response = await this.getCorporaFolderFolderIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns user\'s corpora folders (all corpora folders for admin).
     * Get user\'s corpora folders
     */
    async getCorporaFolderOperationsRaw(requestParameters: GetCorporaFolderOperationsRequest): Promise<runtime.ApiResponse<FolderAllOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCorporaFolderOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/folders/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderAllOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns user\'s corpora folders (all corpora folders for admin).
    * Get user\'s corpora folders
    */
    async getCorporaFolderOperations(requestParameters: GetCorporaFolderOperationsRequest): Promise<FolderAllOutputSuccess> {
        const response = await this.getCorporaFolderOperationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns folder info if creating is successful.
     * Create corpora folder
     */
    async postCorporaFolderOperationsRaw(requestParameters: PostCorporaFolderOperationsRequest): Promise<runtime.ApiResponse<FolderOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postCorporaFolderOperations.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling postCorporaFolderOperations.');
        }

        if (requestParameters.files === null || requestParameters.files === undefined) {
            throw new runtime.RequiredError('files','Required parameter requestParameters.files was null or undefined when calling postCorporaFolderOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const formData = new FormData();
        if (requestParameters.name !== undefined) {
            formData.append('Name', requestParameters.name as any);
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formData.append('Files', element as any);
            })
        }

        const response = await this.request({
            path: `/folders/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns folder info if creating is successful.
    * Create corpora folder
    */
    async postCorporaFolderOperations(requestParameters: PostCorporaFolderOperationsRequest): Promise<FolderOutputSuccess> {
        const response = await this.postCorporaFolderOperationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns corpora folder info if renaming is successful.
     * Rename corpora folder
     */
    async putCorporaFolderOperationsRaw(requestParameters: PutCorporaFolderOperationsRequest): Promise<runtime.ApiResponse<FolderOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling putCorporaFolderOperations.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling putCorporaFolderOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/folders/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FolderNameToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FolderOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns corpora folder info if renaming is successful.
    * Rename corpora folder
    */
    async putCorporaFolderOperations(requestParameters: PutCorporaFolderOperationsRequest): Promise<FolderOutputSuccess> {
        const response = await this.putCorporaFolderOperationsRaw(requestParameters);
        return await response.value();
    }

}
