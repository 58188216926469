// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextEntityDataSuccess,
    TextEntityDataSuccessFromJSON,
    TextEntityDataSuccessFromJSONTyped,
    TextEntityDataSuccessToJSON,
} from './';

/**
 * 
 * @export
 * @interface EntityOutputSuccess
 */
export interface EntityOutputSuccess {
    /**
     * 
     * @type {TextEntityDataSuccess}
     * @memberof EntityOutputSuccess
     */
    data: TextEntityDataSuccess;
    /**
     * Error message.
     * @type {string}
     * @memberof EntityOutputSuccess
     */
    errors: string;
}

export function EntityOutputSuccessFromJSON(json: any): EntityOutputSuccess {
    return EntityOutputSuccessFromJSONTyped(json, false);
}

export function EntityOutputSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityOutputSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': TextEntityDataSuccessFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function EntityOutputSuccessToJSON(value?: EntityOutputSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': TextEntityDataSuccessToJSON(value.data),
        'errors': value.errors,
    };
}


