// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WordAnalysis
 */
export interface WordAnalysis {
    /**
     * Predicted category.
     * @type {string}
     * @memberof WordAnalysis
     */
    cs: string;
    /**
     * Word in dictionary.
     * @type {string}
     * @memberof WordAnalysis
     */
    dictionary: string;
    /**
     * Sentiment of the word.
     * @type {string}
     * @memberof WordAnalysis
     */
    sentiment: string;
    /**
     * Weight of the word.
     * @type {number}
     * @memberof WordAnalysis
     */
    weight: number;
}

export function WordAnalysisFromJSON(json: any): WordAnalysis {
    return WordAnalysisFromJSONTyped(json, false);
}

export function WordAnalysisFromJSONTyped(json: any, ignoreDiscriminator: boolean): WordAnalysis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cs': json['cs'],
        'dictionary': json['dictionary'],
        'sentiment': json['sentiment'],
        'weight': json['weight'],
    };
}

export function WordAnalysisToJSON(value?: WordAnalysis): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cs': value.cs,
        'dictionary': value.dictionary,
        'sentiment': value.sentiment,
        'weight': value.weight,
    };
}


