import { DictionaryApi } from "../../client/src";
import BaseClient from "./BaseClient";

export class DictionaryClient extends BaseClient {
  public id = "DictionaryClient";
  private dictionaryApi = new DictionaryApi(BaseClient.DEFAULT_CLIENT_CONFIGURATION);

  constructor() {
    super();
    return super.cache(this);
  }

  public async getTable() {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.dictionaryApi.getCorporaDictionary({ authorization: authorizationHeader })
    ));
  }

  public async add(file: File) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.dictionaryApi.postCorporaDictionary({
        authorization: authorizationHeader,
        file,
      })
    ));
  }
}
