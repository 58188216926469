import { Navigation } from "../../common";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  CorporaClient,
  TextClient,
  linkName,
  isNotEmptyObject, isSuccessfulResponse,
} from "../../../utility";
import { Container } from "react-bootstrap";
import { CorporaEntity, CorporaRouterProps, DocumentEntity, DocumentRouteProps } from "./";

export interface DocumentState {
  corpus: Partial<CorporaEntity>;
  document: DocumentEntity;
  text: any;
}

class DocumentDetails extends Component<RouteComponentProps<CorporaRouterProps & DocumentRouteProps>> {
  public state: DocumentState = {
    corpus: {},
    document: {},
    text: {},
  };

  public componentDidMount(): void {
    const params = this.props.match.params;
    const folderId = parseInt(params.corpus, 10);
    const textId = parseInt(params.document, 10);
    const corporaClient = new CorporaClient();
    const textClient = new TextClient();
    corporaClient.getListOfDocuments(folderId).then((response: any) => {
      if (isSuccessfulResponse(response)) {
        const corpus = response.data;
        textClient.getTextById(textId).then((res: any) => {
          if (isSuccessfulResponse(res)) {
            const document = res.data;
            textClient.getTextContent(textId).then((r: any) => {
              if (isSuccessfulResponse(r)) {
                const text = r.data;
                this.setState({
                  corpus,
                  document,
                  text,
                });
              }
            });
          }
        });
      }
    });
  }

  public render() {
    const corpus = this.state.corpus;
    const document = this.state.document;
    return (
      <Container>
        {isNotEmptyObject(corpus, document) &&
          <>
            <Navigation
              {...this.props}
              elementName={(url, index) => index === 1 ? corpus.folderName
                : index === 3 ? document.textName : linkName(url[index])}
            />
            <h1>{document.textName}</h1>
            <pre className="corpus_text">{this.state.text.content}</pre>
          </>
        }
      </Container>
    );
  }
}

export default DocumentDetails;
