// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UrlData,
    UrlDataFromJSON,
    UrlDataFromJSONTyped,
    UrlDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface UrlEntity
 */
export interface UrlEntity {
    /**
     * Entity id.
     * @type {number}
     * @memberof UrlEntity
     */
    entityId: number;
    /**
     * Entity name.
     * @type {string}
     * @memberof UrlEntity
     */
    entityName: string;
    /**
     * 
     * @type {Array<UrlData>}
     * @memberof UrlEntity
     */
    urls: Array<UrlData>;
}

export function UrlEntityFromJSON(json: any): UrlEntity {
    return UrlEntityFromJSONTyped(json, false);
}

export function UrlEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrlEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'entityName': json['entityName'],
        'urls': (json['urls'] as Array<any>).map(UrlDataFromJSON),
    };
}

export function UrlEntityToJSON(value?: UrlEntity): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'entityName': value.entityName,
        'urls': (value.urls as Array<any>).map(UrlDataToJSON),
    };
}


