import React, { FC } from "react";
import { ButtonToolbar, Container } from "react-bootstrap";
import { ROUTE } from "../../../constants";
import { LandingFormType } from "./constants";
import LandingHeader from "./Header";
import { ButtonRef } from "../../common";

const LandingPage: FC<never> = () => (
  <>
    <LandingHeader action="login or register" />
    <Container>
      <ButtonToolbar className="justify-content-center emoto-toolbar">
        <ButtonRef to={ROUTE.REGISTER} size="lg">
          {LandingFormType.REGISTER}
        </ButtonRef>
        <ButtonRef to={ROUTE.LOGIN} size="lg">
          {LandingFormType.LOGIN}
        </ButtonRef>
      </ButtonToolbar>
    </Container>
  </>
);

export default LandingPage;
