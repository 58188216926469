import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { ModelClient } from "../../../utility/requests";
import { isSuccessfulResponse } from "../../../utility/form";
import { Heatmap } from "./Heatmap";
import { Container } from "react-bootstrap";

interface HeatmapRouterProps {
  entityId: string;
  entityType: string;
}

class HeatmapPage extends Component<RouteComponentProps<HeatmapRouterProps>> {

  public state = {
    data: {
      text: "",
      metadata: {},
    },
  };

  public componentDidMount(): void {
    const entityId = parseInt(this.props.match.params.entityId, 10);
    const entityType = this.props.match.params.entityType;
    const modelClient = new ModelClient();
    modelClient.postModelHeatmap(entityId, {itemType: entityType})
      .then((response: any) => {
        if (isSuccessfulResponse(response)) {
          this.setState({
            data: response.data,
          });
        }
      });
  }

  public render() {
    return <Container><Heatmap data={this.state.data} /></Container>;
  }
}

export default HeatmapPage;
