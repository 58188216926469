// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryData
 */
export interface CategoryData {
    /**
     * Category name.
     * @type {string}
     * @memberof CategoryData
     */
    name: string;
    /**
     * Category value.
     * @type {string}
     * @memberof CategoryData
     */
    value: string;
}

export function CategoryDataFromJSON(json: any): CategoryData {
    return CategoryDataFromJSONTyped(json, false);
}

export function CategoryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': json['value'],
    };
}

export function CategoryDataToJSON(value?: CategoryData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
    };
}


