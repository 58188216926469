import React, { Component } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { MdFileUpload } from "react-icons/md";
import Feedback from "../form/Feedback";
import { EntityModalProps } from "./index";

interface UploadCorposProps {
  files: FileList | undefined;
  displayName: boolean;
  multipleFiles: boolean;
}

export class UploadBody extends Component<EntityModalProps & UploadCorposProps> {
  public render() {
    return (<>
        {this.props.displayName &&
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              onChange={this.props.handleChange}
              value={this.props.name}
              required={true}
            />
            <Feedback valid={false} errors={this.props.errors.name} forceShow={true} />
          </Form.Group>
        }
        <Form.Group>
          <Form.Label>Files</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <Button style={{position: "relative"}}>Browse...
                <Form.Control
                  title=""
                  type="file"
                  multiple={this.props.multipleFiles}
                  name="files"
                  onChange={this.props.handleChange}
                  required={true}
                  accept=".zip,.tar,.gz,.txt,.tsv"
                  className="upload_corpus_input"
                />
              </Button>
            </InputGroup.Prepend>
          <Form.Control
            title=""
            value={this.props.files && Object.keys(this.props.files).length > 0
              ? Object.keys(this.props.files)
                .map((item: string, i: number) => (this.props.files as FileList)[i].name).join(",")
              : ""
            }
            onChange={(e: any) => e.target.value = this.props.files && Object.keys(this.props.files).length > 0
              ? Object.keys(this.props.files)
                .map((item: string, i: number) => (this.props.files as FileList)[i].name).join(",")
              : ""}
          />
          </InputGroup>
          <Feedback valid={false} errors={this.props.errors.file} />
          <Feedback valid={false} errors={this.props.errors.form} forceShow={true} />
        </Form.Group>
      </>
    );
  }
}

export const UploadFooter = () => {
  return (
    <Button variant="primary" type="submit">
      <MdFileUpload /> Upload
    </Button>
  );
};
