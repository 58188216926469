import React, { FC } from "react";
import { Breadcrumb, Button, Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../../../constants";
import { authenticationService, linkName } from "../../../utility";

interface CustomNavigationProps {
  elementName?: (url: string[], index: number) => string | undefined;
}

export type NavigationProps = RouteComponentProps & CustomNavigationProps;

// TODO: More rigid approach?
export const Navigation: FC<NavigationProps> = ({ match, history, elementName }) => {
  const path = match.url.substring(1);
  const url = path.split(ROUTE.SEPARATOR);
  const homepage = url[0] === "";
  return (
    <Row>
      <Col>
        <Breadcrumb style={{ position: "relative" }}>
          <Col md={11} style={{display: "flex"}}>
            <Breadcrumb.Item active={homepage} href={ROUTE.ROOT}>Home</Breadcrumb.Item>
            {!homepage && url.map((item, index, arr) => {
              const href = arr.slice(0, index + 1).join(ROUTE.SEPARATOR);
              return (
                <Breadcrumb.Item
                  href={`${ROUTE.ROOT}${href}`}
                  key={index}
                  active={index === url.length - 1}
                >
                  {elementName ? elementName(url, index) : linkName(item)}
                </Breadcrumb.Item>
              );
            })}
          </Col>
          <Col md={1} className="logout">
            {/*TODO: User menu with dropdowns and everything*/}
            <Button
              variant="secondary"
              onClick={() =>  authenticationService.logoutUser()}
            >
              Logout
            </Button>
          </Col>
        </Breadcrumb>
      </Col>
    </Row>
  );
};
