// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdhocTextContent,
    AdhocTextContentFromJSON,
    AdhocTextContentFromJSONTyped,
    AdhocTextContentToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdhocTextContentOutput
 */
export interface AdhocTextContentOutput {
    /**
     * 
     * @type {AdhocTextContent}
     * @memberof AdhocTextContentOutput
     */
    data: AdhocTextContent;
    /**
     * Error message.
     * @type {string}
     * @memberof AdhocTextContentOutput
     */
    errors: string;
}

export function AdhocTextContentOutputFromJSON(json: any): AdhocTextContentOutput {
    return AdhocTextContentOutputFromJSONTyped(json, false);
}

export function AdhocTextContentOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdhocTextContentOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': AdhocTextContentFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function AdhocTextContentOutputToJSON(value?: AdhocTextContentOutput): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AdhocTextContentToJSON(value.data),
        'errors': value.errors,
    };
}


