// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WordAnalysis,
    WordAnalysisFromJSON,
    WordAnalysisFromJSONTyped,
    WordAnalysisToJSON,
} from './';

/**
 * 
 * @export
 * @interface HeatmapAnalysis
 */
export interface HeatmapAnalysis {
    /**
     * 
     * @type {Array<WordAnalysis>}
     * @memberof HeatmapAnalysis
     */
    analysis: Array<WordAnalysis>;
    /**
     * Start index of the word.
     * @type {number}
     * @memberof HeatmapAnalysis
     */
    start: number;
    /**
     * End index of the word.
     * @type {number}
     * @memberof HeatmapAnalysis
     */
    end: number;
}

export function HeatmapAnalysisFromJSON(json: any): HeatmapAnalysis {
    return HeatmapAnalysisFromJSONTyped(json, false);
}

export function HeatmapAnalysisFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeatmapAnalysis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'analysis': (json['analysis'] as Array<any>).map(WordAnalysisFromJSON),
        'start': json['start'],
        'end': json['end'],
    };
}

export function HeatmapAnalysisToJSON(value?: HeatmapAnalysis): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'analysis': (value.analysis as Array<any>).map(WordAnalysisToJSON),
        'start': value.start,
        'end': value.end,
    };
}


