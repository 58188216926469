// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CorporaTextContent,
    CorporaTextContentFromJSON,
    CorporaTextContentFromJSONTyped,
    CorporaTextContentToJSON,
} from './';

/**
 * 
 * @export
 * @interface CorporaTextContentOutput
 */
export interface CorporaTextContentOutput {
    /**
     * 
     * @type {CorporaTextContent}
     * @memberof CorporaTextContentOutput
     */
    data: CorporaTextContent;
    /**
     * Error message.
     * @type {string}
     * @memberof CorporaTextContentOutput
     */
    errors: string;
}

export function CorporaTextContentOutputFromJSON(json: any): CorporaTextContentOutput {
    return CorporaTextContentOutputFromJSONTyped(json, false);
}

export function CorporaTextContentOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporaTextContentOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CorporaTextContentFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function CorporaTextContentOutputToJSON(value?: CorporaTextContentOutput): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CorporaTextContentToJSON(value.data),
        'errors': value.errors,
    };
}


