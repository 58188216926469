import { Button } from "react-bootstrap";
import React from "react";
import { GoPencil } from "react-icons/go";

const EditLegendFooter = () => {
  return (
    <Button variant="primary" type="submit">
      <GoPencil /> Update
    </Button>
  );
};

export default EditLegendFooter;
