import React, { Component } from "react";
import { Container, Image, Jumbotron } from "react-bootstrap";
import { Route, RouteComponentProps } from "react-router-dom";
import { MdFileUpload, FaBook, FaCog, FaFont, FaFile, IoIosStats, FaPaperclip } from "react-icons/all";
import {
  AddItemTypes,
  CorporaItem,
  DictionaryItem,
  ModelItem,
  SentimentOptions,
  UnitOptions,
} from "../../../types";
import { COLOR_BY_INDEX, EntityType, ROUTE, UNSET_INT } from "../../../constants";
import banner from "../../../banner-large.jpg";
import {
  ChangeItemFunction,
  ModalType,
  ModalWindow,
  Navigation,
  NavigationProps,
  OpenModalFunction,
} from "../../common";
import { HomepageBlock, HomepageBlocks } from "./HomepageBlocks";
import {
  authenticationService,
  CorporaClient,
  DictionaryClient, isNotEmptyObject,
  isSuccessfulResponse,
  ModelClient,
  RecommendationClient,
  addLoadingSuffix,
} from "../../../utility";
import Corpora from "../Corpora";
import Models from "../Models";
import ModelDetail from "../Models/ModelDetail";
import { ILegend } from "../../common/chart/Legend";
import { RecommendationProps } from "../Models/RecommendationBlock";
import { format } from "d3-format";
import { AnalysisData, CategoryData } from "../../../client/src/models";

export interface NewChartData {
  data: AnalysisData;
  entityId: number;
  entityName: string;
  legendType: string;
  icon: JSX.Element;
  content?: any;
}

export type ChangeChartData = (newChartData: NewChartData[], merge?: boolean) => void;

type HomepageProps = NavigationProps & RouteComponentProps;

class Home extends Component<HomepageProps & { blocks: HomepageBlock[]}> {
  public render() {
    return (
      <>
        <Jumbotron>
          <h2>Emoto.ai - Digital Character Analyzer</h2>
          <Image
            src={banner}
            alt="Homepage banner"
            fluid={true}
            rounded={true}
            className="img-rounded img-responsive"
          />
        </Jumbotron>
        <Navigation {...this.props} />
        <HomepageBlocks
          blocks={this.props.blocks}
        />
      </>
    );
  }
}

class Homepage extends Component<HomepageProps> {
  public readonly state = {
    itemIndex: UNSET_INT,
    modalWindowShow: false,
    modalWindowType: ModalType.UNDEFINED,
    entityType: EntityType.UNDEFINED,
    [EntityType.CORPORA]: [],
    [EntityType.MODELS]: [],
    [EntityType.DICTIONARY]: [],
    [EntityType.RECOMMENDATION]: [],
    chartSettings: {
      unit: UnitOptions.PROBABILITY,
      sentiment: SentimentOptions.POSITIVE_ONLY,
    },
    chartData: new Map(),
    legend: [],
    colorIndex: 0,
    chartRecommendations: {},
    tickFormatter: format(".0%"),
    chartLoading: true,
    [addLoadingSuffix(EntityType.CORPORA)]: true,
    [addLoadingSuffix(EntityType.MODELS)]: true,
    [addLoadingSuffix(EntityType.DICTIONARY)]: true,
    [addLoadingSuffix(EntityType.RECOMMENDATION)]: true,
  };

  public openModalWindow: OpenModalFunction = (modalWindowType, entityType, itemIndex, chartSettings = {}) => {
    this.setState({
      itemIndex,
      modalWindowShow: true,
      modalWindowType,
      entityType,
      chartSettings,
    });
  }

  public componentDidMount(): void {
    const corporaClient = new CorporaClient();
    const dictionaryClient = new DictionaryClient();
    const modelClient = new ModelClient();
    const recommendationClient = new RecommendationClient();
    corporaClient.getTable().then((response) => {
      if (isSuccessfulResponse(response)) {
        this.setState({
          [EntityType.CORPORA]: response.data,
        });
      }
    }).finally(() => {
      this.setState({
        [addLoadingSuffix(EntityType.CORPORA)]: false,
      });
    });
    modelClient.getTable().then((response) => {
      if (isSuccessfulResponse(response)) {
        this.setState({
          [EntityType.MODELS]: response.data,
        });
      }
    }).finally(() => {
      this.setState({
        [addLoadingSuffix(EntityType.MODELS)]: false,
      });
    });
    if (authenticationService.checkAdmin()) {
      dictionaryClient.getTable().then((response) => {
        if (isSuccessfulResponse(response)) {
          const data = Array.isArray(response.data) ? response.data : [response.data];
          this.setState({
            [EntityType.DICTIONARY]: data,
          });
        }
      }).finally(() => {
        this.setState({
          [addLoadingSuffix(EntityType.DICTIONARY)]: false,
        });
      });
      recommendationClient.getModelRecommendation().then((response) => {
        const data = Array.isArray(response.data) ? response.data : [response.data];
        this.setState({
          [EntityType.RECOMMENDATION]: data,
        });
      }).finally(() => {
        this.setState({
          [addLoadingSuffix(EntityType.RECOMMENDATION)]: false,
        });
      });
    }
  }

  public closeModalWindow = () => {
    this.setState({
      itemIndex: UNSET_INT,
      modalWindowShow: false,
      modalWindowType: ModalType.UNDEFINED,
      entityType: EntityType.UNDEFINED,
      chartSettings: {
        ...this.state.chartSettings,
        item: null,
      },
    });
  }

  private getItems(copy: boolean): any {
    let table: CorporaItem[] | ModelItem[] | DictionaryItem[] = [];
    switch (this.state.entityType) {
      case EntityType.CORPORA: {
        table = copy ? [...this.state[EntityType.CORPORA]] : this.state[EntityType.CORPORA];
        break;
      }
      case EntityType.MODELS: {
        table = copy ? [...this.state[EntityType.MODELS]] : this.state[EntityType.MODELS];
        break;
      }
      case EntityType.DICTIONARY: {
        table = copy ? [...this.state[EntityType.DICTIONARY]] : this.state[EntityType.DICTIONARY];
        break;
      }
      case EntityType.RECOMMENDATION: {
        table = copy ? [...this.state[EntityType.RECOMMENDATION]] : this.state[EntityType.RECOMMENDATION];
        break;
      }
    }
    return table;
  }

  private renameItem(index: number, newValue: string) {
    const table = this.getItems(true);
    switch (this.state.entityType) {
      case EntityType.CORPORA:
        table.forEach((item: CorporaItem) => {
          if (item.folderId === index) {
            item.folderName = newValue;
          }
        });
        break;
      case EntityType.MODELS:
        table.forEach((item: ModelItem) => {
          if (item.modelId === index) {
            item.modelName = newValue;
          }
        });
        break;
    }
    this.setState({[this.state.entityType]: table});
  }

  private deleteItem(index: number) {
    switch (this.state.entityType) {
      case EntityType.CORPORA:
        this.setState({
          [this.state.entityType]: this.getItems(false).filter((item: CorporaItem) => item.folderId !== index),
        });
        break;
      case EntityType.MODELS:
        this.setState({
          [this.state.entityType]: this.getItems(false).filter((item: ModelItem) => item.modelId !== index),
        });
        break;
    }
  }

  private addItem(newItem: unknown) {
    if (this.state.entityType === EntityType.DICTIONARY || this.state.entityType === EntityType.RECOMMENDATION) {
      this.setState({
        [this.state.entityType]: [newItem],
      });
    } else {
      this.setState({
        [this.state.entityType]: [...this.getItems(false), newItem],
      });
    }
  }

  public changeItem: ChangeItemFunction = (index, newValue) => {
    switch (this.state.modalWindowType) {
      case ModalType.RENAME: {
        this.renameItem(index, newValue.name);
        break;
      }
      case ModalType.DELETE: {
        this.deleteItem(index);
        break;
      }
      case ModalType.UPLOAD_NEW_CORPUS:
      case ModalType.UPLOAD_NEW_RECOMMENDATION:
      case ModalType.UPLOAD_NEW_DICTIONARY:
      case ModalType.TRAIN_NEW_MODEL: {
        this.addItem(newValue);
        break;
      }
    }
  }

  public changeChartData: ChangeChartData = (newChartData, merge) => {
    const legend: ILegend[] = typeof merge !== undefined ? [...this.state.legend] : [];
    const chartRecommendations: RecommendationProps = this.state.chartRecommendations;

    // Handle new legend state
    if (merge) {
      for (const newChartDataItem of newChartData) {
        const legendItemIndex = legend.findIndex((item: ILegend) => item.entityId === newChartDataItem.entityId);
        const characterStrengths = newChartDataItem.data.predict.characterStrengths;
        legend.splice(legendItemIndex, 1, {
          ...legend[legendItemIndex],
          data: characterStrengths,
        });
      }
    } else {
      for (const { entityId, entityName, legendType, data, icon, content } of newChartData) {
        if (data) {
          const recommendations = data.recommendations ? data.recommendations : [];
          recommendations.forEach((item: any) => {
            const category = item.category;
            chartRecommendations[category] = {
              recommendation: item.recommendation,
              links: Array.isArray(item.link) ? item.link
                : item.link !== "" ? [item.link] : [],
            };
          });

          const characterStrengths = data.predict && data.predict.characterStrengths
            ? data.predict.characterStrengths
            : [];
          legend.push({
            entityId,
            entityName,
            // FIXME
            legendType: legendType as AddItemTypes,
            data: characterStrengths,
            icon,
            show: true,
            content,
            recommendations,
            color: COLOR_BY_INDEX[this.state.colorIndex],
          });

          this.changeColor();
        }
      }
    }

    // Walk through all the legend items
    // and map them for chart consumption
    const chartData: Map<number, CategoryData[]> = legend.reduce((acc, i) => acc.set(i.entityId, i.data), new Map());

    this.setState({
      chartData,
      legend,
      chartRecommendations,
      chartLoading: false,
    });
  }

  public changeColor() {
    let colorIndex = this.state.colorIndex;
    this.setState({
      colorIndex: this.state.colorIndex === COLOR_BY_INDEX.length ? 0 : ++colorIndex,
    });
  }

  public legendShow = (show: boolean): void => {
    const legend: ILegend[] = [...this.state.legend];
    legend.forEach((item) => {
      item.show = show;
    });
    this.setState({
      legend,
    });
  }

  public legendItemShow = (entityId: number): void => {
    const legend: ILegend[] = [...this.state.legend];
    legend.forEach((item) => {
      if (item.entityId === entityId) {
        item.show = !item.show;
      }
    });
    this.setState({
      legend,
    });
  }

  public legendItemDelete = (entityId: number): void => {
    const legend = this.state.legend.filter((item: ILegend) => item.entityId !== entityId);
    const chartRecommendations = isNotEmptyObject(legend) ? this.state.chartRecommendations : {};
    this.setState({
      legend,
      chartRecommendations,
    });
  }

  public legendItemEdit = (entityId: number, entityName: string, content: string, newEntityId?: number): void => {
    const legend = this.state.legend.map((item: ILegend) => {
      if (item.entityId === entityId) {
        if (newEntityId) {
          item.entityId = newEntityId;
        }
        item.entityName = entityName;
        item.content = content;
      }
      return item;
    });
    this.setState({
      legend,
    }, () => this.changeLegend());
  }

  public changeLegend = (): void => {
    const modelClient = new ModelClient();
    const items: any = Object.values(this.state.legend);
    const promises = [];
    const chartSettings = this.state.chartSettings;
    for (const item of items) {
      const payload = {
        itemType: item.legendType,
        unit: chartSettings.unit,
        sentiment: chartSettings.sentiment,
      };
      promises.push(modelClient.postModelPredict(item.entityId, payload).then((p) => {
        let icon;
        switch (item.legendType) {
          case AddItemTypes.DOCUMENTS_FROM_CORPORA:
            icon = <FaFile />;
            break;
          case AddItemTypes.ADHOC_DOCUMENT:
            icon = <FaFont />;
            break;
          case AddItemTypes.CORPUS_BASELINE:
            icon = <FaBook />;
            break;
          case AddItemTypes.FROM_URL:
            icon = <FaPaperclip />;
            break;
        }
        return ({
          data: p.data,
          entityId: item.entityId,
          entityName: item.entityName,
          legendType: item.legendType,
          icon,
        } as NewChartData);
      }));
    }
    Promise.all(promises).then((result) => {
      this.setState({
        tickFormatter: chartSettings.unit === UnitOptions.PROBABILITY
          ? format(".0%")
          : chartSettings.unit === UnitOptions.WORD_COUNT
            ? format(",.0r")
            : format("+,.0r"),
      });
      this.changeChartData(result, true);
    });
  }

  public handleClick = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      chartSettings: {
        ...this.state.chartSettings,
        [name]: value,
      },
    }, () => this.changeLegend());
  }

  public render() {
    const {
      itemIndex,
      modalWindowShow,
      modalWindowType,
      entityType,
      [EntityType.CORPORA]: corpora,
      [EntityType.MODELS]: models,
      [EntityType.DICTIONARY]: dictionary,
      [EntityType.RECOMMENDATION]: recommendations,
      chartSettings,
      chartLoading,
      [addLoadingSuffix(EntityType.CORPORA)]: corporaLoading,
      [addLoadingSuffix(EntityType.MODELS)]: modelsLoading,
      [addLoadingSuffix(EntityType.DICTIONARY)]: dictionaryLoading,
      [addLoadingSuffix(EntityType.RECOMMENDATION)]: recommendationLoading,
    } = this.state;

    // FIXME: Temporarily uses dummy data
    const blocks: HomepageBlock[] = [
      {
        table: {
          header: {
            entityType: EntityType.CORPORA,
            modalTypes: [ModalType.UPLOAD_NEW_CORPUS],
            buttonIcon: <MdFileUpload />,
            buttonText: "Upload new corpus",
            itemIcon: <FaBook />,
            plural: "corpora",
            openModalWindow: this.openModalWindow,
            tip: "Add new corpus of documents",
          },
          body: {
            entityType: EntityType.CORPORA,
            items: corpora,
            plural: "corpora",
            singular: "corpus",
            openModalWindow: this.openModalWindow,
          },
          loading: corporaLoading as boolean,
        },
      },
      {
        table: {
          header: {
            entityType: EntityType.MODELS,
            modalTypes: [ModalType.TRAIN_NEW_MODEL],
            buttonIcon: <FaCog />,
            buttonText: "Train new model",
            itemIcon: <IoIosStats />,
            plural: "models",
            openModalWindow: this.openModalWindow,
            tip: "Create new model or select an existing from corpus",
          },
          body: {
            entityType: EntityType.MODELS,
            items: models,
            plural: "models",
            singular: "model",
            openModalWindow: this.openModalWindow,
          },
          loading: modelsLoading as boolean,
        },
      },
    ];

    if (authenticationService.checkAdmin()) {
      blocks.push({
        table: {
          header: {
            entityType: EntityType.DICTIONARY,
            modalTypes: [ModalType.UPLOAD_NEW_DICTIONARY],
            buttonIcon: <div />,
            buttonText: "Upload new dictionary",
            itemIcon: <div />,
            plural: "dictionary",
            openModalWindow: this.openModalWindow,
            tip: "Add new dictionary CSV file",
          },
          body: {
            entityType: EntityType.DICTIONARY,
            items: dictionary,
            plural: "dictionary",
            singular: "dictionary",
            openModalWindow: this.openModalWindow,
          },
          loading: dictionaryLoading as boolean,
        },
      },
      {
        table: {
          header: {
            entityType: EntityType.RECOMMENDATION,
            modalTypes: [ModalType.UPLOAD_NEW_RECOMMENDATION],
            buttonIcon: <div />,
            buttonText: "Upload new recommendation",
            itemIcon: <div />,
            plural: "recommendations",
            openModalWindow: this.openModalWindow,
            tip: "Add new recommendation CSV file",
          },
          body: {
            entityType: EntityType.RECOMMENDATION,
            items: recommendations,
            plural: "recommendations",
            singular: "recommendation",
            openModalWindow: this.openModalWindow,
          },
          loading: recommendationLoading as boolean,
        },
      });
    }

    const legend = [...this.state.legend];
    return (
      <>

        <ModalWindow
          type={modalWindowType}
          closeModalWindow={this.closeModalWindow}
          show={modalWindowShow}
          itemIndex={itemIndex}
          models={models}
          corpora={corpora}
          dictionary={dictionary}
          recommendations={recommendations}
          changeItem={this.changeItem}
          entityType={entityType}
          chartSettings={chartSettings}
          changeChartData={this.changeChartData}
          legendItemEdit={this.legendItemEdit}
        />
        <Container>
          <Route
            exact={true}
            path={ROUTE.ROOT}
            render={(props) => <Home {...props} blocks={blocks} />}
          />
          <Route
            exact={true}
            path={ROUTE.CORPORA_ROOT}
            render={(props) => <Corpora {...props} block={blocks[0]} loading={corporaLoading as boolean} />}
          />
          <Route
            exact={true}
            path={ROUTE.MODELS_ROOT}
            render={(props) => <Models {...props} block={blocks[1]} loading={modelsLoading as boolean} />}
          />
          <Route
            exact={true}
            path={ROUTE.MODELS_MODEL}
            render={(props) => <ModelDetail
              {...props}
              openModalWindow={this.openModalWindow}
              chartData={this.state.chartData}
              changeChartData={this.changeChartData}
              legend={legend}
              legendShow={this.legendShow}
              legendItemShow={this.legendItemShow}
              legendItemDelete={this.legendItemDelete}
              changeLegend={this.changeLegend}
              chartSettings={this.state.chartSettings}
              handleClick={this.handleClick}
              chartRecommendations={this.state.chartRecommendations}
              tickFormatter={this.state.tickFormatter}
              loading={chartLoading}
            />}
          />
        </Container>
      </>
    );
  }
}

export default Homepage;
