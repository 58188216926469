// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntityFolderName
 */
export interface EntityFolderName {
    /**
     * Specify the id associated with the model.
     * @type {number}
     * @memberof EntityFolderName
     */
    modelId: number;
    /**
     * Specify the id associated with the corpora folder.
     * @type {number}
     * @memberof EntityFolderName
     */
    folderId: number;
    /**
     * Name of the entity. Max length: 60 characters. Name should not include ./~\\ symbols.
     * @type {string}
     * @memberof EntityFolderName
     */
    name: string;
}

export function EntityFolderNameFromJSON(json: any): EntityFolderName {
    return EntityFolderNameFromJSONTyped(json, false);
}

export function EntityFolderNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityFolderName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': json['modelId'],
        'folderId': json['folderId'],
        'name': json['name'],
    };
}

export function EntityFolderNameToJSON(value?: EntityFolderName): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'folderId': value.folderId,
        'name': value.name,
    };
}


