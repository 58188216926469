// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelData
 */
export interface ModelData {
    /**
     * Model id.
     * @type {number}
     * @memberof ModelData
     */
    modelId: number;
    /**
     * Model name.
     * @type {string}
     * @memberof ModelData
     */
    modelName: string;
    /**
     * Corpus name.
     * @type {string}
     * @memberof ModelData
     */
    trainCorpusName: string;
    /**
     * Created time.
     * @type {Date}
     * @memberof ModelData
     */
    created: Date;
}

export function ModelDataFromJSON(json: any): ModelData {
    return ModelDataFromJSONTyped(json, false);
}

export function ModelDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': json['modelId'],
        'modelName': json['modelName'],
        'trainCorpusName': json['trainCorpusName'],
        'created': new Date(json['created']),
    };
}

export function ModelDataToJSON(value?: ModelData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'modelName': value.modelName,
        'trainCorpusName': value.trainCorpusName,
        'created': value.created.toISOString(),
    };
}


