import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Table } from "../../common";
import { HomepageBlock } from "../Home/HomepageBlocks";
import { Navigation, NavigationProps } from "../../common";

export interface ModelRouterProps {
  model: string;
}

class Models extends Component<NavigationProps & RouteComponentProps & {block: HomepageBlock; loading: boolean}> {
  public render() {
    const { block, loading } = this.props;
    return (
      <>
        <Navigation {...this.props} />
        <Table
          header={block.table.header}
          body={block.table.body}
          loading={loading}
        />
      </>
    );
  }
}

export default Models;
