import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { GoPencil } from "react-icons/go";
import Feedback from "../form/Feedback";
import { EntityModalProps } from "./index";

interface RenameProps {
  setName: () => void;
}

export class RenameBody extends Component<EntityModalProps & RenameProps> {
  public componentDidMount(): void {
    this.props.setName();
  }

  public render() {
    return (
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          onChange={this.props.handleChange}
          value={this.props.name}
        />
        <Feedback valid={false} errors={this.props.errors.name} forceShow={true} />
        <Feedback valid={false} errors={this.props.errors.form} forceShow={true} />
      </Form.Group>
    );
  }
}

export const RenameFooter = () => {
  return (
    <Button variant="primary" type="submit">
      <GoPencil /> Rename
    </Button>
  );
};
