import React, { ComponentType, FC, PureComponent } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router";

import { ROUTE } from "../../../constants";
import { authenticationService } from "../../../utility";

interface PrivateRouteProps<AuthProps, UnauthProps> extends Omit<RouteProps, "component"> {
  authorizedComponent?: ComponentType<AuthProps>;
  unauthorizedComponent?: ComponentType<UnauthProps>;
}

const RedirectToLogin: FC<RouteComponentProps> = (props) =>
  <Redirect to={{ pathname: ROUTE.LOGIN, state: { from: props.location } }} />;

// FIXME: To functional component
export default class AuthorizedOrRoute<TAuth, TUnauth> extends PureComponent<PrivateRouteProps<TAuth, TUnauth>> {
  public render() {
    const {
      authorizedComponent,
      unauthorizedComponent,
      ...rest
    } = this.props;
    // TODO: Introduce authorization service
    const isAuthorized = authenticationService.checkLogin();

    return (
      <Route
        {...rest}
        component={isAuthorized
          ? authorizedComponent
          : unauthorizedComponent ? unauthorizedComponent : RedirectToLogin}
      />
    );
  }
}
