// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UrlInfo
 */
export interface UrlInfo {
    /**
     * Url id.
     * @type {number}
     * @memberof UrlInfo
     */
    urlId: number;
    /**
     * Url.
     * @type {string}
     * @memberof UrlInfo
     */
    url: string;
}

export function UrlInfoFromJSON(json: any): UrlInfo {
    return UrlInfoFromJSONTyped(json, false);
}

export function UrlInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrlInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'urlId': json['urlId'],
        'url': json['url'],
    };
}

export function UrlInfoToJSON(value?: UrlInfo): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'urlId': value.urlId,
        'url': value.url,
    };
}


