// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefreshTokenData
 */
export interface RefreshTokenData {
    /**
     * Access token.
     * @type {string}
     * @memberof RefreshTokenData
     */
    accessToken: string;
}

export function RefreshTokenDataFromJSON(json: any): RefreshTokenData {
    return RefreshTokenDataFromJSONTyped(json, false);
}

export function RefreshTokenDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
    };
}

export function RefreshTokenDataToJSON(value?: RefreshTokenData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
    };
}


