import React, { Component } from "react";
import Checkbox from "../form/Checkbox";
import Feedback from "../form/Feedback";
import FormElement from "../form/FormElement";
import { Button } from "react-bootstrap";
import { FaPlus, IoIosClose } from "react-icons/all";
import { EntityModalProps } from "./index";

interface FromUrlProps {
  name: string;
  handleChange: any;
  combineCheckbox: boolean;
  errors: { [name: string]: string };
}

interface FromUrlState {
  links: number[];
}

class FromUrl extends Component<EntityModalProps & FromUrlProps, FromUrlState> {
  public state = {
    links: [0],
  };

  public addLink = (): void => {
    this.setState({
      links: [...this.state.links, this.state.links.length],
    });
  }

  public removeLink = (linkIndex: number): void => {
    const links = this.state.links.filter((item) => item !== linkIndex);
    this.setState({
      links,
    });
  }

  public render() {
    const {
      name,
      combineCheckbox,
      handleChange,
      errors,
    } = this.props;
    const links = this.state.links;

    return (
      <>
        <FormElement
          name="name"
          label="Label"
          value={name}
          required={true}
          handleChange={handleChange}
          errors={errors.name}
        />
        {links.map((i) => (
          <div key={`link_${i}`} className={i > 0 ? "chart_link" : ""}>
             <FormElement
              type="url"
              name={`link_${i}`}
              label={i === 0 ? "Insert link" : ""}
              required={true}
              handleChange={handleChange}
            />
           {i > 0 ? <IoIosClose onClick={() => this.removeLink(i)} /> : ""}
          </div>
        ))}
        <Checkbox
          checked={combineCheckbox}
          label="Combine documents into one item"
          name="combineCheckbox"
          required={false}
          handleChange={handleChange}
          disabled={links.length < 2}
        />
        <Button variant="secondary" onClick={this.addLink}>
          <FaPlus /> Add another link
        </Button>
        <Feedback valid={false} errors={errors.form} forceShow={true} />
      </>
    );
  }
}

export default FromUrl;
