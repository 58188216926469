import React, { FC } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Homepage from "./components/route/Home";
import { ROUTE } from "./constants";
import "./App.css";
import LandingPage from "./components/route/Landing";
import { NoMatch } from "./components/route/NoMatch";
import { AuthorizedOrRoute } from "./components/common";
import Login from "./components/route/Login";
import Register from "./components/route/Register";
import TermsOfService from "./components/route/TermsOfService";
import CorpusDetails from "./components/route/Corpora/CorpusDetails";
import ListOfCorporaDocuments from "./components/route/Corpora/ListOfCorporaDocuments";
import DocumentDetails from "./components/route/Corpora/DocumentDetails";
import ForgotPass from "./components/route/ForgotPass";
import ResetPass from "./components/route/ResetPass";
import HeatmapPage from "./components/route/Heatmap";

const App: FC = () => (
  <Router>
    <Switch>
      <AuthorizedOrRoute
        path={ROUTE.ROOT}
        exact={true}
        authorizedComponent={Homepage}
        unauthorizedComponent={LandingPage}
      />
      <AuthorizedOrRoute
        path={ROUTE.CORPORA_ROOT}
        exact={true}
        authorizedComponent={Homepage}
        unauthorizedComponent={LandingPage}
      />
      <AuthorizedOrRoute
        path={ROUTE.CORPORA_CORPUS}
        exact={true}
        authorizedComponent={CorpusDetails}
        unauthorizedComponent={LandingPage}
      />
      <AuthorizedOrRoute
        path={ROUTE.CORPORA_DOCUMENT_LIST}
        exact={true}
        authorizedComponent={ListOfCorporaDocuments}
        unauthorizedComponent={LandingPage}
      />
      <AuthorizedOrRoute
        path={ROUTE.CORPORA_DOCUMENT}
        exact={true}
        authorizedComponent={DocumentDetails}
        unauthorizedComponent={LandingPage}
      />
      <AuthorizedOrRoute
        path={ROUTE.MODELS_ROOT}
        exact={true}
        authorizedComponent={Homepage}
        unauthorizedComponent={LandingPage}
      />
      <AuthorizedOrRoute
        exact={true}
        path={ROUTE.HEATMAP}
        authorizedComponent={HeatmapPage}
        unauthorizedComponent={LandingPage}
      />
      <AuthorizedOrRoute
        exact={true}
        path={ROUTE.MODELS_MODEL}
        authorizedComponent={Homepage}
        unauthorizedComponent={LandingPage}
      />
      <Route
        exact={true}
        path={ROUTE.LOGIN}
        component={Login}
      />
      <Route
        exact={true}
        path={ROUTE.REGISTER}
        component={Register}
      />
      <Route
        exact={true}
        path={ROUTE.FORGOT_PASS}
        component={ForgotPass}
      />
      <Route
        exact={true}
        path={ROUTE.TERMS_OF_SERVICE}
        component={TermsOfService}
      />
      <Route
        path={ROUTE.RESET_PASS}
        exact={true}
        component={ResetPass}
      />
      <Route component={NoMatch} />
    </Switch>
  </Router>
);

export default App;
