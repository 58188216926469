// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CorporaTextInfo,
    CorporaTextInfoFromJSON,
    CorporaTextInfoFromJSONTyped,
    CorporaTextInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CorporaTextEntity
 */
export interface CorporaTextEntity {
    /**
     * Entity id.
     * @type {number}
     * @memberof CorporaTextEntity
     */
    entityId: number;
    /**
     * Entity name.
     * @type {string}
     * @memberof CorporaTextEntity
     */
    entityName: string;
    /**
     * 
     * @type {Array<CorporaTextInfo>}
     * @memberof CorporaTextEntity
     */
    texts: Array<CorporaTextInfo>;
}

export function CorporaTextEntityFromJSON(json: any): CorporaTextEntity {
    return CorporaTextEntityFromJSONTyped(json, false);
}

export function CorporaTextEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporaTextEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'entityName': json['entityName'],
        'texts': (json['texts'] as Array<any>).map(CorporaTextInfoFromJSON),
    };
}

export function CorporaTextEntityToJSON(value?: CorporaTextEntity): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'entityName': value.entityName,
        'texts': (value.texts as Array<any>).map(CorporaTextInfoToJSON),
    };
}


