// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextEntityDataSuccess,
    TextEntityDataSuccessFromJSON,
    TextEntityDataSuccessFromJSONTyped,
    TextEntityDataSuccessToJSON,
} from './';

/**
 * 
 * @export
 * @interface TextEntityOutputSuccess
 */
export interface TextEntityOutputSuccess {
    /**
     * 
     * @type {Array<TextEntityDataSuccess>}
     * @memberof TextEntityOutputSuccess
     */
    data: Array<TextEntityDataSuccess>;
    /**
     * Error message.
     * @type {string}
     * @memberof TextEntityOutputSuccess
     */
    errors: string;
}

export function TextEntityOutputSuccessFromJSON(json: any): TextEntityOutputSuccess {
    return TextEntityOutputSuccessFromJSONTyped(json, false);
}

export function TextEntityOutputSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextEntityOutputSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': (json['data'] as Array<any>).map(TextEntityDataSuccessFromJSON),
        'errors': json['errors'],
    };
}

export function TextEntityOutputSuccessToJSON(value?: TextEntityOutputSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': (value.data as Array<any>).map(TextEntityDataSuccessToJSON),
        'errors': value.errors,
    };
}


