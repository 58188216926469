import React from "react";
import { Button } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

export const Delete = () => {
  return (
    <Button variant="danger" type="submit">
      <FaTrashAlt /> Delete
    </Button>
  );
};
