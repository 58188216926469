export const localStorageService = {
  createOrUpdateItem(key: string, value: string) {
    localStorage.setItem(key, value);
  },
  readItem(key: string) {
    return localStorage.getItem(key);
  },
  deleteItem(key: string) {
    localStorage.delItem(key);
  },
  clear() {
    localStorage.clear();
  },
};
