// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JoinTextOption
 */
export interface JoinTextOption {
    /**
     * Join text option.
     * @type {boolean}
     * @memberof JoinTextOption
     */
    joinOption: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof JoinTextOption
     */
    listOfTextId?: Array<number>;
    /**
     * Name of the entity. Max length: 60 characters. Name should not include ./~\\ symbols.
     * @type {string}
     * @memberof JoinTextOption
     */
    name: string;
}

export function JoinTextOptionFromJSON(json: any): JoinTextOption {
    return JoinTextOptionFromJSONTyped(json, false);
}

export function JoinTextOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): JoinTextOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'joinOption': json['joinOption'],
        'listOfTextId': !exists(json, 'listOfTextId') ? undefined : json['listOfTextId'],
        'name': json['name'],
    };
}

export function JoinTextOptionToJSON(value?: JoinTextOption): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'joinOption': value.joinOption,
        'listOfTextId': value.listOfTextId,
        'name': value.name,
    };
}


