import React, { Component, SyntheticEvent } from "react";
import { EntityModalProps } from "./index";
import FormElement, { FormElementProps } from "../form/FormElement";
import { Button } from "react-bootstrap";
import Feedback from "../form/Feedback";

interface SendGraphProps {
  name: string;
  handleChange: (event: SyntheticEvent<FormElementProps>) => void;
  errors: { [name: string]: string };
}

export class SendGraphBody extends Component<EntityModalProps & SendGraphProps> {
  public render() {
    const {
      name,
      handleChange,
      errors,
    } = this.props;

    return (
      <>
        <FormElement
          name="name"
          label="Email"
          value={name}
          required={true}
          handleChange={handleChange}
          errors={errors.name}
        />
        <Feedback valid={false} errors={errors.form} forceShow={true} />
      </>
    );
  }
}

export const SendGraphFooter = () => {
  return (
    <Button variant="primary" type="submit">Send</Button>
  );
};
