// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateInfo
 */
export interface UpdateInfo {
    /**
     * First name. Max length: 60 characters.
     * @type {string}
     * @memberof UpdateInfo
     */
    firstName: string;
    /**
     * Last name. Max length: 60 characters.
     * @type {string}
     * @memberof UpdateInfo
     */
    lastName: string;
    /**
     * Email. Max length: 120 characters.
     * @type {string}
     * @memberof UpdateInfo
     */
    email: string;
    /**
     * Password. Min length: 5 characters. Max length: 200 characters.
     * @type {string}
     * @memberof UpdateInfo
     */
    password: string;
    /**
     * Company name. Max length: 60 characters.
     * @type {string}
     * @memberof UpdateInfo
     */
    company: string;
    /**
     * Position. Max length: 60 characters.
     * @type {string}
     * @memberof UpdateInfo
     */
    position: string;
    /**
     * Role. Choose user or admin role, user role is default.
     * @type {string}
     * @memberof UpdateInfo
     */
    role: string;
}

export function UpdateInfoFromJSON(json: any): UpdateInfo {
    return UpdateInfoFromJSONTyped(json, false);
}

export function UpdateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'password': json['password'],
        'company': json['company'],
        'position': json['position'],
        'role': json['role'],
    };
}

export function UpdateInfoToJSON(value?: UpdateInfo): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'password': value.password,
        'company': value.company,
        'position': value.position,
        'role': value.role,
    };
}


