export function isNotEmptyObject(...objects: unknown[]): boolean {
  return objects.every((object) => typeof object === "object" && object && Object.keys(object as {}).length > 0);
}

export function convertDateToString(date?: Date) {
  if (date == null) {
    return "";
  }

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  return `${date.getFullYear()}-${(month > 9 ? "" : "0") + month}-${(day > 9 ? "" : "0") + day} ${time}`;
}

export function addLoadingSuffix(key: string) {
  return `${key}_loading`;
}
