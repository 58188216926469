import React, { Component } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart, ResponsiveContainer,
} from "recharts";
import { Trait } from "./index";
import { UnitOptions } from "../../../types";
import { UNSET_INT } from "../../../constants";
import { ILegend } from "./Legend";

interface ChartProps {
  chartData: Map<number, Trait[]>;
  legend: ILegend[];
  unit: UnitOptions;
  chartHighlight: (highLightId: number) => void;
  unHighlightChart: () => void;
  highLightId: number;
  tickFormatter: (n: number | { valueOf(): number }) => string;
  loading: boolean;
}

class Chart extends Component<ChartProps> {
  public addPaddingToTick = (props: any) => {
    const { x, y, payload, textAnchor } = props;
    const words = payload.value.split(" ");
    const value = words.map((word: string, i: number) => (
      <tspan
        key={`tick${i}`}
        x={textAnchor === "start" ? x + 10 : textAnchor === "end" ? x - 10 : x}
        dy={i === 0 && y < 400 ? `-${words.length - 1}em` : i === 0 && y === 400 ? "0em" : "1em"}
      >
        {word}
      </tspan>
    ));
    return (
      <text x={x} y={y} textAnchor={textAnchor}>
        {value}
      </text>
    );
  }

  public addPaddingToAxis = (props: any) => {
    const { x, y, payload, textAnchor } = props;
    const value = this.props.tickFormatter(payload.value);
    return (
      <text x={x + 5} y={y + 12} textAnchor={textAnchor}>
        <tspan>{value}</tspan>
      </text>
    );
  }

  public render() {
    let max = UNSET_INT;
    const data: Array<{ name: string, [k: string]: string | number }> = [];
    for (const [entityId, traits] of this.props.chartData) {
      for (let j = 0; j < traits.length; ++j) {
        const trait = traits[j];
        const value = parseFloat(trait.value);
        if (value > max) {
          max = value;
        }
        data[j] === undefined
          ? data[j] = { name: trait.name, [`value_${entityId}`]: value }
          : data[j][`value_${entityId}`] = value;
      }
    }

    return (
      <Row>
        <Col md={12} id="chart-container">
          <ResponsiveContainer height="100%" width="100%">
            {this.props.loading ?
              <div className="spinner">
                <Spinner animation="border" variant="secondary" />
              </div> :
              <RadarChart
                outerRadius={300}
                data={data}
                className="chart"
              >
                <PolarGrid
                  gridType="circle"
                />
                <PolarAngleAxis
                  dataKey="name"
                  tick={this.addPaddingToTick}
                />
                <PolarRadiusAxis
                  angle={90}
                  domain={[0, max]}
                  axisLine={false}
                  tickCount={6}
                  tick={this.addPaddingToAxis}
                  stroke="black"
                />
                {Object.keys(data[0] || {}).filter((k) => /^value_\d+$/.test(k)).map((k) => {
                  const entityId = parseInt(k.slice(6), 10);
                  const legendItem = this.props.legend.filter((item) => item.entityId === entityId);
                  const fillOpacity = entityId === this.props.highLightId ? 1
                    : this.props.highLightId === UNSET_INT ? 0.6 : 0.2;
                  return legendItem.length > 0 && legendItem[0].show && (<Radar
                     key={`radar_${k}`}
                     dataKey={k}
                     stroke={legendItem.length > 0 ? legendItem[0].color : "inherit"}
                     fill={legendItem.length > 0 ? legendItem[0].color : "inherit"}
                     fillOpacity={fillOpacity}
                     onMouseEnter={() => this.props.chartHighlight(entityId)}
                     onMouseLeave={this.props.unHighlightChart}
                   />);
                })}
              </RadarChart>
            }
          </ResponsiveContainer>
        </Col>
      </Row>
    );
  }
}
export default Chart;
