import { authenticationService } from "../authentication";
import { UserApi } from "../../client/src/apis";
import { Configuration } from "../../client/src";
import { NEW_LINE } from "../../constants";

interface Identifiable {
  id: string;
}

const _instanceCache = new Map<string, object>();

abstract class BaseClient implements Identifiable {
  public abstract id: string;

  protected static DEFAULT_CLIENT_CONFIGURATION = new Configuration({
    basePath: process.env.REACT_APP_EMOTO_API_URL,
    // fetchApi: fetch,
  });
  private static USER_API = new UserApi(BaseClient.DEFAULT_CLIENT_CONFIGURATION);

  private isRefreshingTokens = false;
  private tokens = authenticationService.getLoginData();

  protected cache<T extends Identifiable>(instance: T) {
    if (instance != null) {
      if (_instanceCache.has(instance.id)) {
        return _instanceCache.get(instance.id) as T;
      }

      _instanceCache.set(instance.id, instance);
    }

    return instance;
  }

  private async refreshTokens() {
    this.isRefreshingTokens = true;
    try {
      const response = await BaseClient.USER_API.getResfreshToken({
        authorization: this.getAuthorizationHeader(this.tokens.refreshToken),
      });
      const { data } = await response;
      if (data && data.accessToken) {
        this.tokens = {
          ...this.tokens,
          accessToken: data.accessToken,
        };
        return;
      }
      const message = "Error getting access token. Server response: " + NEW_LINE + data;
      throw new Error(message);
    } finally {
      this.isRefreshingTokens = false;
    }
  }

  protected getAuthorizationHeader(token?: string) {
    return `Bearer ${token || this.tokens.accessToken}`;
  }

  protected async doAuthorizedRequest<T>(request: (authorizationHeader: string) => Promise<T>): Promise<T> {
    try {
      const response = await request(this.getAuthorizationHeader());
      return await response;
    } catch (e) {
      if (e.status === 401 && !this.isRefreshingTokens) {
        try {
          await this.refreshTokens();
          try {
            const response = await request(this.getAuthorizationHeader());
            return await response;
          } catch (e) {
            return e;
          }
        } catch (e) {
          authenticationService.logoutUser();
          return e;
        }
      } else {
        try {
          return await e.json();
        } catch (e) {
          return e;
        }
      }
    }
  }
}

export default BaseClient;
