import React, { Component } from "react";
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, Row, Table } from "react-bootstrap";
import {
  FaBook, FaExternalLinkAlt, FaEye, FaEyeSlash, FaFire,
  FaFont, FaPlus, FaFile, IoMdClose, GoPencil,
} from "react-icons/all";
import { AddItemOptions, AddItemTypes } from "../../../types";
import { ChartColors } from "../../../constants";
import { ModelClient } from "../../../utility/requests";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { CategoryData } from "../../../client/src/models";

export interface ILegend {
  entityId: number;
  entityName: string;
  legendType: AddItemTypes;
  data: CategoryData[];
  icon: JSX.Element;
  show: boolean;
  color: ChartColors;
  content: any;
  // FIXME
  recommendations: any;
}

interface LegendProps {
  handleSelect: (eventKey: string) => void;
  legend: ILegend[];
  chartHighlight: (highLightId: number) => void;
  unHighlightChart: () => void;
  highLightId: number;
  legendItemShow: (entityId: number) => void;
  legendItemDelete: (entityId: number) => void;
  legendItemEdit: (item: {
    entityName: string;
    content: [{value: number; label: string; }];
    entityId: number;
    legendType: string;
  }) => void;
}

class Legend extends Component<LegendProps> {
  public legendItemDelete(item: ILegend) {
    const modelClient = new ModelClient();
    switch (item.legendType) {
      case AddItemTypes.ADHOC_DOCUMENT: {
        modelClient.deleteModelAdhocTextAdhocTextId(item.entityId);
        break;
      }
      case AddItemTypes.DOCUMENTS_FROM_CORPORA: {
        modelClient.deleteModelTextItemEntityId(item.entityId);
        break;
      }
      case AddItemTypes.FROM_URL: {
        modelClient.deleteModelUrlOperations({entityId: item.entityId});
        break;
      }
      case AddItemTypes.CORPUS_BASELINE: {
        modelClient.deleteModelFolderItemEntityId(item.entityId);
        break;
      }
    }
    this.props.legendItemDelete(item.entityId);
  }

  public render() {
    return (
      <Row className="legend">
        <Col md={12}>
          <Row>
            <Col md={9} className="legend_title">
              <h2>Legend</h2>
            </Col>
            <Col md={3} className="legend_add_button">
              <DropdownButton
                as={ButtonGroup}
                title={<><FaPlus /> Add item</>}
                id="bg-nested-dropdown"
                size="sm"
                onSelect={this.props.handleSelect}
              >
                <Dropdown.Item eventKey={AddItemOptions.DOCUMENTS_FROM_CORPORA}>
                  <FaFile /> {AddItemOptions.DOCUMENTS_FROM_CORPORA}
                </Dropdown.Item>
                <Dropdown.Item eventKey={AddItemOptions.ADHOC_DOCUMENT}>
                  <FaFont /> {AddItemOptions.ADHOC_DOCUMENT}
                </Dropdown.Item>
                <Dropdown.Item eventKey={AddItemOptions.CORPUS_BASELINE}>
                  <FaBook /> {AddItemOptions.CORPUS_BASELINE}
                </Dropdown.Item>
                <Dropdown.Item eventKey={AddItemOptions.FROM_URL}>
                  <FaExternalLinkAlt /> {AddItemOptions.FROM_URL}
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>

          <Table className="legend-items">
            <tbody>
            {this.props.legend.map((item) => {
              const color = this.props.highLightId === item.entityId ? item.color : "inherit";
              const spanColor = this.props.highLightId === item.entityId ? "black" : item.color;
              return (
                <tr
                  key={`legend-item-${item.entityId}`}
                  className={`legend-item-${item.entityId}`}
                  style={{backgroundColor: color}}
                  onMouseEnter={() => this.props.chartHighlight(item.entityId)}
                  onMouseLeave={this.props.unHighlightChart}
                >
                  <td>
                    <span style={{color: spanColor}}>{item.icon}</span>{item.entityName}
                  </td>
                  <td>
                    <ButtonGroup className="legend-item-buttons">
                      <Button
                        size="sm"
                        variant="link"
                        data-tip={item.show ? "Hide" : "Show"}
                        onClick={() => this.props.legendItemShow(item.entityId)}
                      >
                        {item.show ? <FaEye /> : <FaEyeSlash />}
                        <ReactTooltip />
                      </Button>
                      <Button
                        size="sm"
                        variant="link"
                        data-tip="Edit"
                        onClick={() => this.props.legendItemEdit({
                          entityName: item.entityName,
                          content: item.content,
                          entityId: item.entityId,
                          legendType: item.legendType,
                        })}
                      >
                        <GoPencil />
                        <ReactTooltip />
                      </Button>
                      <Button size="sm" variant="link">
                        <Link
                          className="heatmap_button_link"
                          to={`/models/heatmap/${item.legendType}/${item.entityId}`}
                          data-tip="Open Hitmap"
                        >
                          <FaFire />
                          <ReactTooltip />
                        </Link>
                      </Button>
                      <Button
                        size="sm"
                        variant="link"
                        data-tip="Remove from Graph"
                        onClick={() => this.legendItemDelete(item)}
                      >
                        <IoMdClose />
                        <ReactTooltip />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </Table>

        </Col>
      </Row>
    );
  }
}

export default Legend;
