// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdhocTextData,
    AdhocTextDataFromJSON,
    AdhocTextDataFromJSONTyped,
    AdhocTextDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdhocTextOutput
 */
export interface AdhocTextOutput {
    /**
     * 
     * @type {AdhocTextData}
     * @memberof AdhocTextOutput
     */
    data: AdhocTextData;
    /**
     * Error message.
     * @type {string}
     * @memberof AdhocTextOutput
     */
    errors: string;
}

export function AdhocTextOutputFromJSON(json: any): AdhocTextOutput {
    return AdhocTextOutputFromJSONTyped(json, false);
}

export function AdhocTextOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdhocTextOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': AdhocTextDataFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function AdhocTextOutputToJSON(value?: AdhocTextOutput): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AdhocTextDataToJSON(value.data),
        'errors': value.errors,
    };
}


