import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import React from "react";

const LegendFooter = () => {
  return (
    <Button variant="primary" type="submit">
      <FaPlus /> Add
    </Button>
  );
};

export default LegendFooter;
