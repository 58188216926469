import React, { ChangeEventHandler, FC, ReactNode } from "react";
import { Form } from "react-bootstrap";
import Feedback from "./Feedback";

export interface CheckboxProps {
  // FIXME
  groupAs?: any;
  checked: boolean;
  label: ReactNode;
  name: string;
  disabled?: boolean;
  errors?: string | string[];
  required: boolean;
  isValid?: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  color?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  groupAs,
  checked,
  label,
  name,
  disabled,
  errors,
  required,
  isValid,
  handleChange,
  color,
}) => (
  <Form.Group controlId={name} as={groupAs} style={{backgroundColor: color}}>
    <Form.Check type="checkbox" custom={true}>
      <Form.Check.Input
        type="checkbox"
        checked={checked}
        name={name}
        onChange={handleChange}
        required={required}
        isValid={isValid === true}
        isInvalid={isValid === false}
        disabled={disabled === true}
      />
      <Form.Check.Label>{label}</Form.Check.Label>
      <Feedback valid={false} errors={errors} />
    </Form.Check>
  </Form.Group>
);

export default Checkbox;
