import React, { ChangeEvent, PureComponent, SyntheticEvent } from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Container, Form } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { UserPosition, UserRole } from "../../../types";
import { ROUTE } from "../../../constants";
import { ButtonRef } from "../../common";
import Checkbox from "../../common/form/Checkbox";
import Feedback from "../../common/form/Feedback";
import FormElement, { FormElementProps } from "../../common/form/FormElement";
import {
  UserClient,
  Register as RegisterModel,
  RegisterErrors as RegisterErrorsModel,
  authenticationService,
  isValidForm, isNotEmptyObject,
} from "../../../utility";
import { LandingFormType } from "../Landing/constants";
import LandingHeader from "../Landing/Header";
import { Login } from "../../../client/src";

interface RegisterErrors extends RegisterErrorsModel {
  termsChecker?: string;
  unknown?: string;
}

interface RegisterState {
  data: Partial<RegisterModel>;

  termsChecker: boolean;
  validated: boolean;
  errors: RegisterErrors;
}

export default class Register extends PureComponent<RouteComponentProps, RegisterState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      data: {
        company: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        repeatedPassword: "",
        position: UserPosition.BRAND_MANAGER,
      },
      termsChecker: false,
      validated: false,
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = {
    ...this.state.errors,
        unknown: "",
    };
    this.setState({
      validated: true,
      errors,
    });
    if (isValidForm(event.currentTarget, errors)) {
      const params = {
        ...this.state.data,
        role: UserRole.USER,
      };
      const userClient = new UserClient();

      userClient.register(params as RegisterModel).then(async (response) => {
        if (isNotEmptyObject(response.errors)) {
          this.setState({
            errors: {
              ...this.state.errors,
              ...response.errors,
            },
          });
        } else {
          await userClient.login({
            email: this.state.data.email,
            password: this.state.data.password,
          } as Required<Login>).then(async (dataLogin) => authenticationService.loginUser(dataLogin.data));
          this.props.history.push(ROUTE.ROOT);
        }
      }).catch(() => {
        this.setState({
          errors: {
            ...this.state.errors,
            unknown: "Something went wrong.",
          },
        });
      });
    }
  }

  public handleChange(event: SyntheticEvent<FormElementProps<keyof RegisterState["data"]>>): void;
  public handleChange(event: ChangeEvent<HTMLInputElement>): void;
  public handleChange(event: SyntheticEvent<FormElementProps<keyof RegisterState["data"]>> | ChangeEvent<HTMLInputElement>) {
    const inputName = event.currentTarget.name;
    const inputValue = event.currentTarget.value;

    if (inputName === "termsChecker") {
      this.setState({
        termsChecker: !this.state.termsChecker,
        errors: {
          ...this.state.errors,
          termsChecker: this.state.termsChecker ? "Please accept terms of service." : "",
        },
      });

      return;
    }

    // TODO: Support optional fields
    const error = inputValue
      ? inputName === "repeatedPassword" && (this.state.data.password !== inputValue)
        ? "Your password doesn't match."
        : ""
      : "The field is required.";
    this.setState({
      data: {
        ...this.state.data,
        [inputName]: inputValue,
      },
      errors: {
        ...this.state.errors,
        [inputName]: error,
      },
    });
  }

  public render() {
    const {
      data,
      errors,
      validated,
    } = this.state;

    return (
      <>
        <LandingHeader action="register" />
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <FormElement
                groupAs={Col}
                name="firstName"
                label="First name"
                errors={errors.firstName}
                value={data.firstName}
                handleChange={this.handleChange}
                type="text"
                required={true}
                isValid={validated ? !errors.firstName : undefined}
              />
              <FormElement
                groupAs={Col}
                name="lastName"
                label="Last name"
                errors={errors.lastName}
                value={data.lastName}
                handleChange={this.handleChange}
                type="text"
                required={true}
                isValid={validated ? !errors.lastName : undefined}
              />
            </Form.Row>
            <Form.Row>
              <FormElement
                groupAs={Col}
                name="email"
                label="Email"
                errors={errors.email}
                value={data.email}
                handleChange={this.handleChange}
                type="email"
                required={true}
                isValid={validated ? !errors.email : undefined}
              />
            </Form.Row>
            <Form.Row>
              <FormElement
                groupAs={Col}
                name="password"
                label="Password"
                errors={errors.password}
                value={data.password}
                handleChange={this.handleChange}
                type="password"
                required={true}
                isValid={validated ? !errors.password : undefined}
              />
              <FormElement
                groupAs={Col}
                name="repeatedPassword"
                label="Repeat password"
                errors={errors.repeatedPassword}
                value={data.repeatedPassword}
                handleChange={this.handleChange}
                type="password"
                required={true}
                isValid={validated ? !errors.repeatedPassword : undefined}
              />
            </Form.Row>
            <Form.Row>
              <FormElement
                groupAs={Col}
                name="company"
                label="Company name"
                errors={errors.company}
                value={data.company}
                handleChange={this.handleChange}
                type="text"
                required={true}
                isValid={validated ? !errors.company : undefined}
              />
              <FormElement
                groupAs={Col}
                controlAs="select"
                name="position"
                label="I am"
                value={data.position}
                handleChange={this.handleChange}
                required={true}
              >
                {Object.values(UserPosition).map((p, idx) => <option key={idx}>{p}</option>)}
              </FormElement>
            </Form.Row>

            <Form.Row>
              <Checkbox
                groupAs={Col}
                name="termsChecker"
                label={
                  <>I have read and agree to the <Link to={ROUTE.TERMS_OF_SERVICE}>terms of
                    service</Link></>
                }
                errors={errors.termsChecker}
                checked={this.state.termsChecker}
                handleChange={this.handleChange}
                required={true}
                isValid={validated ? !errors.termsChecker : undefined}
              />
            </Form.Row>

            <Form.Row>
              <Col>
                <Feedback valid={false} errors={errors.unknown} forceShow={true} />
              </Col>
            </Form.Row>
            <ButtonToolbar className="justify-content-end">
              <ButtonGroup>
                <ButtonRef to={ROUTE.ROOT} variant="secondary">Cancel</ButtonRef>
                <Button variant="primary" type="submit">
                  {LandingFormType.REGISTER}
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Form>
        </Container>
      </>
    );
  }
}
