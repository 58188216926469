// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdhocTextData
 */
export interface AdhocTextData {
    /**
     * Text id.
     * @type {number}
     * @memberof AdhocTextData
     */
    adhocTextId: number;
    /**
     * Text name.
     * @type {string}
     * @memberof AdhocTextData
     */
    textName: string;
    /**
     * Text type: url or text.
     * @type {string}
     * @memberof AdhocTextData
     */
    type: string;
}

export function AdhocTextDataFromJSON(json: any): AdhocTextData {
    return AdhocTextDataFromJSONTyped(json, false);
}

export function AdhocTextDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdhocTextData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adhocTextId': json['adhocTextId'],
        'textName': json['textName'],
        'type': json['type'],
    };
}

export function AdhocTextDataToJSON(value?: AdhocTextData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adhocTextId': value.adhocTextId,
        'textName': value.textName,
        'type': value.type,
    };
}


