// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListOfUrls
 */
export interface ListOfUrls {
    /**
     * Join text option.
     * @type {boolean}
     * @memberof ListOfUrls
     */
    joinOption: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListOfUrls
     */
    listOfUrl?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListOfUrls
     */
    listOName?: Array<string>;
}

export function ListOfUrlsFromJSON(json: any): ListOfUrls {
    return ListOfUrlsFromJSONTyped(json, false);
}

export function ListOfUrlsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOfUrls {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'joinOption': json['joinOption'],
        'listOfUrl': !exists(json, 'listOfUrl') ? undefined : json['listOfUrl'],
        'listOName': !exists(json, 'listOName') ? undefined : json['listOName'],
    };
}

export function ListOfUrlsToJSON(value?: ListOfUrls): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'joinOption': value.joinOption,
        'listOfUrl': value.listOfUrl,
        'listOName': value.listOName,
    };
}


