import { Register } from "../client/src";

export interface CorporaItem {
  folderName: string;
  folderId: number;
}

export interface ModelItem {
  modelId: number;
  modelName: string;
}

export interface DictionaryItem {
  dictionaryName: string;
  dictionaryId: number;
}

export interface RecommendationItem {
  recommendationName: string;
  recommendationId: number;
}

export enum UserPosition {
  BRAND_MANAGER = "Brand manager",
  AGENCY_OR_PR_EXECUTIVE = "Agency or PR executive",
  PUBLISHER = "Publisher",
  CMO_OR_CSO = "CMO or CSO",
  DATA_ETHICS_SPECIALIST = "Responsible for my companies data ethics",
  CAMPAIGN_MANAGER = "Campaign manager",
  WRITER_OR_EDITOR = "Writer or editor",
  CURIOUS = "Curious",
  SOMEONE_ELSE = "Someone else",
}

export enum UserRole {
  USER = "user",
  ADMIN = "admin",
}

export interface User extends Register {
  position: UserPosition;
  role: UserRole;
}

export enum UnitOptions {
  PROBABILITY = "probability",
  WEIGHT = "weight",
  WORD_COUNT = "word",
}

export enum SentimentOptions {
  COMBINED = "combined",
  SEPARATE = "separate",
  POSITIVE_ONLY = "positive",
  NEGATIVE_ONLY = "negative",
}

export enum AddItemOptions {
  DOCUMENTS_FROM_CORPORA = "Documents from corpora",
  ADHOC_DOCUMENT = "Ad-hoc document",
  CORPUS_BASELINE = "Corpus baseline",
  FROM_URL = "Upload link",
}

export enum AddItemTypes {
  DOCUMENTS_FROM_CORPORA = "entity text",
  ADHOC_DOCUMENT = "adhoc text",
  CORPUS_BASELINE = "folder",
  FROM_URL = "url",
}

export enum ExportGraph {
  PNG_TYPE = "PNG",
  PPT_TYPE = "PPT",
}
