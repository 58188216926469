export enum ModalType {
  UNDEFINED,
  DELETE,
  RENAME,
  UPLOAD_NEW_CORPUS,
  UPLOAD_NEW_DICTIONARY,
  UPLOAD_NEW_RECOMMENDATION,
  TRAIN_NEW_MODEL,
  LEGEND_DOCUMENT_FROM_CORPORA,
  LEGEND_ADHOC_DOCUMENT,
  LEGEND_CORPUS_BASELINE,
  LEGEND_FROM_URL,
  EDIT_DOCUMENTS_FROM_CORPORA,
  EDIT_ADHOC_DOCUMENT,
  EDIT_CORPUS_BASELINE,
  EDIT_FROM_URL,
  SEND_GRAPH,
}
