import { TextApi, TextName } from "../../client/src";
import BaseClient from "./BaseClient";

export class TextClient extends BaseClient {
  public id = "TextClient";
  private textApi = new TextApi(BaseClient.DEFAULT_CLIENT_CONFIGURATION);

  constructor() {
    super();
    return super.cache(this);
  }

  public async getTextById(textId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.textApi.getCorporaTextTextId({
        authorization: authorizationHeader,
        textId,
      })),
    );
  }

  public async getAllTexts() {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.textApi.getCorporaTextOperations({ authorization: authorizationHeader })),
    );
  }

  public async delete(textId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.textApi.deleteCorporaTextTextId({
        authorization: authorizationHeader,
        textId,
      })),
    );
  }

  public async rename(props: TextName) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.textApi.putCorporaTextOperations({
        authorization: authorizationHeader,
        payload: props,
      })),
    );
  }

  public async getTextContent(textId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.textApi.getCorporaTextContent({
        authorization: authorizationHeader,
        textId,
      })),
    );
  }
}
