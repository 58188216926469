import React, { Component, ReactNode } from "react";
import Select from "react-select";
import FormElement from "../form/FormElement";
import Checkbox from "../form/Checkbox";
import { CorporaClient, isNotEmptyObject, isSuccessfulResponse } from "../../../utility";
import { FaFile } from "react-icons/all";
import Feedback from "../form/Feedback";
import { CorporaItem } from "../../../types";
import { Form } from "react-bootstrap";

interface DocumentsFromCorporaBodyProps {
  name: string;
  handleChange: any;
  handleSelect: (value: any, action: any) => void;
  combineCheckbox: boolean;
  documents: CorporaItem[];
  errors: { [name: string]: string };
  setEditLegendProps: () => void;
  entities: {value: number; label: any};
  showCheckbox: boolean;
}

export class DocumentsFromCorporaBody extends Component<DocumentsFromCorporaBodyProps> {
  public state: {selectorOptions: Array<{value: number; label: string | ReactNode; isDisabled?: boolean}>} = {
    selectorOptions: [],
  };

  public componentDidMount(): void {
    this.props.setEditLegendProps();
    const corporaClient = new CorporaClient();
    Object.keys(this.props.documents).forEach((index: any) => {
      corporaClient.getListOfDocuments(this.props.documents[index].folderId).then((response: any) => {
        if (isSuccessfulResponse(response)) {
          const selectorOptions = this.state.selectorOptions;
          if (isNotEmptyObject(response.data)) {
            selectorOptions.push({
              value: this.props.documents[index].folderId,
              label: <><FaFile />{this.props.documents[index].folderName}</>,
              isDisabled: true,
            });
            response.data.texts.forEach((text: {textId: number; textName: string}) => {
              selectorOptions.push({value: text.textId, label: text.textName});
            });
            this.setState({
              selectorOptions,
            });
          }
        }
      });
    });
  }

  public render() {
    return (
      <>
        <FormElement
          name="name"
          label="Label"
          value={this.props.name}
          required={this.props.combineCheckbox}
          handleChange={this.props.handleChange}
          disabled={!this.props.combineCheckbox}
          errors={this.props.errors.name}
        />
        {this.props.showCheckbox &&
          <Checkbox
            checked={this.props.combineCheckbox}
            label="Combine documents into one item"
            name="combineCheckbox"
            required={false}
            handleChange={this.props.handleChange}
          />
        }
        <Form.Label>Documents</Form.Label>
        <Select
          closeMenuOnSelect={false}
          isMulti={true}
          placeholder="Nothing selected"
          options={this.state.selectorOptions}
          onChange={this.props.handleSelect}
          name="entities"
          value={this.props.entities}
        />
        <Feedback valid={false} errors={this.props.errors.form} forceShow={true} />
      </>
    );
  }
}
