// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UrlData
 */
export interface UrlData {
    /**
     * Url id.
     * @type {number}
     * @memberof UrlData
     */
    urlId: number;
    /**
     * Text name.
     * @type {string}
     * @memberof UrlData
     */
    textName: string;
    /**
     * Url type.
     * @type {string}
     * @memberof UrlData
     */
    type: string;
}

export function UrlDataFromJSON(json: any): UrlData {
    return UrlDataFromJSONTyped(json, false);
}

export function UrlDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrlData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'urlId': json['urlId'],
        'textName': json['textName'],
        'type': json['type'],
    };
}

export function UrlDataToJSON(value?: UrlData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'urlId': value.urlId,
        'textName': value.textName,
        'type': value.type,
    };
}


