import React, { Component } from "react";
import { Button, ButtonGroup, Card, Col, FormControl, ListGroup, Row, DropdownButton, Dropdown } from "react-bootstrap";
import { ExportGraph, SentimentOptions, UnitOptions } from "../../../types";
import { linkName, ModelClient, isSuccessfulResponse } from "../../../utility";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { IoMdDownload } from "react-icons/io";
import { CSVLink } from "react-csv";
import { ILegend } from "./Legend";
import Feedback from "../form/Feedback";

interface LegendOptionsProps {
  handleSelect: any;
  chartSettings: {
    unit: UnitOptions;
    sentiment: SentimentOptions;
  };
  handleClick: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  legendShow: (show: boolean) => void;
  refreshLegend: () => void;
  legend: ILegend[];
  sendGraph: () => void;
}

export class LegendOptions extends Component<LegendOptionsProps> {
  private csv: any;
  constructor(props: LegendOptionsProps) {
    super(props);
    this.csv = React.createRef();
  }

  public state = {
    downloadCSV: [],
  };

  public downloadCSV = () => {
    const modelClient = new ModelClient();
    const payload = this.props.legend.map((item) => ({
      itemType: item.legendType,
      entityId: item.entityId,
      unit: this.props.chartSettings.unit,
      sentiment: this.props.chartSettings.sentiment,
    }));
    modelClient.postModelDownloadPrediction(payload).then((response) => {
      if (isSuccessfulResponse(response)) {
        this.setState({
          downloadCSV: response.data,
        }, () => this.csv.current.link.click());
      }
    });
  }

  public render() {
    return (
      <Card as={Row}>
        <Card.Header>Options</Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row>
              <Col md={3}>Unit:</Col>
              <ButtonGroup as={Col} md={9} size="sm" className="option_buttons">
                {Object.values(UnitOptions).map((button, index) => <FormControl
                    as={Button}
                    size="sm"
                    type="radio"
                    variant="light"
                    active={button === this.props.chartSettings.unit}
                    key={index}
                    name="unit"
                    value={button}
                    onClick={this.props.handleClick}
                  >{`${linkName(button)}${button === UnitOptions.WORD_COUNT ? ` count` : ``}`}</FormControl>,
                )}
              </ButtonGroup>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col md={3}>Sentiment:</Col>
              <ButtonGroup as={Col} md={9} size="sm" className="option_buttons">
                {Object.values(SentimentOptions).map((button, index) => <FormControl
                    as={Button}
                    size="sm"
                    type="radio"
                    variant="light"
                    name="sentiment"
                    active={button === this.props.chartSettings.sentiment}
                    value={button}
                    key={index}
                    onClick={this.props.handleClick}
                  >{`${linkName(button)}${button === SentimentOptions.NEGATIVE_ONLY
                  || button === SentimentOptions.POSITIVE_ONLY ? ` only` : ``}`}
                  </FormControl>,
                )}
              </ButtonGroup>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col md={9} className="option_buttons sentiment">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => this.props.legendShow(true)}
                ><FaEye /> Show all</Button>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => this.props.legendShow(false)}
                ><FaEyeSlash /> Hide all</Button>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={this.props.refreshLegend}
                ><FiRefreshCw /> Refresh</Button>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={this.downloadCSV}
                ><IoMdDownload /> Download CSV</Button>
                <CSVLink
                  ref={this.csv}
                  data={this.state.downloadCSV}
                  filename="export.csv"
                  target="_blank"
                  style={{display: "none"}}
                />
                <DropdownButton
                  as={ButtonGroup}
                  title="Export Graph"
                  id="bg-nested-dropdown"
                  size="sm"
                  variant="outline-secondary"
                  onSelect={this.props.handleSelect}
                >
                  <Dropdown.Item active={false} eventKey={ExportGraph.PNG_TYPE}>{ExportGraph.PNG_TYPE}</Dropdown.Item>
                  <Dropdown.Item active={false} eventKey={ExportGraph.PPT_TYPE}>{ExportGraph.PPT_TYPE}</Dropdown.Item>
                </DropdownButton>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={this.props.sendGraph}
                >
                  Email Graph
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    );
  }

}
