// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecommendationData,
    RecommendationDataFromJSON,
    RecommendationDataFromJSONTyped,
    RecommendationDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecommendationOutputSuccess
 */
export interface RecommendationOutputSuccess {
    /**
     * 
     * @type {RecommendationData}
     * @memberof RecommendationOutputSuccess
     */
    data: RecommendationData;
    /**
     * Error message.
     * @type {string}
     * @memberof RecommendationOutputSuccess
     */
    errors: string;
}

export function RecommendationOutputSuccessFromJSON(json: any): RecommendationOutputSuccess {
    return RecommendationOutputSuccessFromJSONTyped(json, false);
}

export function RecommendationOutputSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecommendationOutputSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': RecommendationDataFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function RecommendationOutputSuccessToJSON(value?: RecommendationOutputSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': RecommendationDataToJSON(value.data),
        'errors': value.errors,
    };
}


