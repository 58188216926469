import React, { Component, SyntheticEvent } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AddItemTypes, CorporaItem, DictionaryItem, ModelItem, RecommendationItem } from "../../../types";
import { EntityType, UNSET_INT } from "../../../constants";
import { LegendState, ModalType } from "./constants";
import { Delete } from "./Delete";
import { RenameBody, RenameFooter } from "./Rename";
import { FormElementProps } from "../form/FormElement";
import {
  CorporaClient,
  DictionaryClient,
  isNotEmptyObject,
  isSuccessfulResponse,
  isValidForm,
  ModelClient,
  RecommendationClient,
} from "../../../utility";
import { TrainBody, TrainFooter } from "./TrainModel";
import { UploadBody, UploadFooter } from "./UploadCorpus";
import Feedback from "../form/Feedback";
import { DocumentsFromCorporaBody } from "./DocumentsFromCorpora";
import { FaFile, FaPaperclip, FaBook, FaFont } from "react-icons/all";
import { AdHocDocument } from "./AdHocDocument";
import { CorpusBaseline } from "./CorpusBaseline";
import FromUrl from "./FromUrl";
import { TypeOfModelItem } from "../../../client/src";
import { EditLegendFooter, LegendFooter } from "../buttons";
import EditFromUrl from "./EditFromUrl";
import { ChangeChartData, NewChartData } from "../../route/Home";
import { SendGraphBody, SendGraphFooter } from "./SendGraph";

interface ModalWindowProps {
  type: ModalType;
  closeModalWindow(): void;
  show: boolean;
  itemIndex: number;
  changeItem: ChangeItemFunction;
  corpora: CorporaItem[];
  models: ModelItem[];
  dictionary: DictionaryItem[];
  recommendations: RecommendationItem[];
  entityType: EntityType;
  chartSettings: any;
  changeChartData: ChangeChartData;
  legendItemEdit: (entityId: number, entityName: string, content: string, newEntityId?: number) => void;
}

export interface EntityModalProps {
  handleChange: (event: SyntheticEvent<FormElementProps>) => void;
  name: string;
  errors: { [name: string]: string };
}

interface ModalWindowState {
  name: string;
  files?: FileList;
  // FIXME
  errors: any;
  validated: boolean;
  corpusItem: string;
  entities: any;
  entityId: number;
  combineCheckbox: boolean;
  adHocText: string;
  // FIXME
  [k: string]: any;
}

export type OpenModalFunction = (modalType: ModalType, entityType: EntityType, indexItem: number, chartSettings?: any) => void;

export type ChangeItemFunction = (index: number, newValue: { name: string }) => void;

export class ModalWindow extends Component<ModalWindowProps, ModalWindowState> {
  public readonly state: ModalWindowState = {
    name: "",
    files: undefined,
    errors: {},
    validated: false,
    corpusItem: "",
    combineCheckbox: false,
    entities: [],
    adHocText: "",
    entityId: UNSET_INT,
    corpusBaseline: null,
  };

  // FIXME
  public setName = () => {
    let name = "";
    switch (this.props.entityType) {
      case EntityType.MODELS:
        this.props.models.forEach((item) => {
          if (item.modelId === this.props.itemIndex) {
            name = item.modelName;
          }
        });
        break;
      case EntityType.CORPORA:
        this.props.corpora.forEach((item) => {
          if (item.folderId === this.props.itemIndex) {
            name = item.folderName;
          }
        });
        break;
      case EntityType.DICTIONARY:
        this.props.dictionary.forEach((item) => {
          if (item.dictionaryId === this.props.itemIndex) {
            name = item.dictionaryName;
          }
        });
        break;
    }
    this.setState({
      name,
    });
  }

  public handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const name = this.state.name;
    const files = this.state.files as FileList;
    const corporaClient = new CorporaClient();
    const dictionaryClient = new DictionaryClient();
    const modelClient = new ModelClient();
    const recommendationClient = new RecommendationClient();
    const errors = {
      ...this.state.errors,
      form: "",
    };
    this.setState({
      validated: true,
      errors,
    });
    const payload: TypeOfModelItem = {
      itemType: "",
      unit: this.props.chartSettings.unit,
      sentiment: this.props.chartSettings.sentiment,
    };
    if (isValidForm(event.currentTarget, errors)) {
      switch (this.props.type) {
        case ModalType.UPLOAD_NEW_CORPUS: {
          corporaClient.add(name, files).then((response: any) => {
            if (isSuccessfulResponse(response)) {
              this.props.changeItem(this.props.itemIndex, response.data);
              this.closeModalWindow();
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  form: response.errors && typeof response.errors === "string"
                    ? response.errors
                    : response.errors
                      ? Object.values(response.errors).join(" ")
                      : response.message,
                },
              });
            }
          });
          break;
        }
        case ModalType.UPLOAD_NEW_DICTIONARY: {
          dictionaryClient.add(files[0]).then((response: any) => {
            if (isSuccessfulResponse(response)) {
              if (response.data) {
                this.props.changeItem(this.props.itemIndex, response.data);
              }
              this.closeModalWindow();
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  form: response.errors && typeof response.errors === "string" ? response.errors : response.message,
                },
              });
            }
          });
          break;
        }
        case ModalType.UPLOAD_NEW_RECOMMENDATION: {
          recommendationClient.postModelRecommendation(files[0]).then((response: any) => {
            if (isSuccessfulResponse(response)) {
              if (response.data) {
                this.props.changeItem(this.props.itemIndex, response.data);
              }
              this.closeModalWindow();
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  form: response.errors && typeof response.errors === "string" ? response.errors : response.message,
                },
              });
            }
          });
          break;
        }
        case ModalType.TRAIN_NEW_MODEL: {
          let folderId = UNSET_INT;
          this.props.corpora.forEach((item) => {
            if (item.folderName === this.state.corpusItem) {
              folderId = item.folderId;
            }
          });
          modelClient.add({folderId: `${folderId !== UNSET_INT ? folderId : ""}`, name}).then((response: any) => {
            if (isSuccessfulResponse(response)) {
              this.props.changeItem(this.props.itemIndex, response.data);
              this.closeModalWindow();
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  form: response.errors ? response.errors : response.message,
                },
              });
            }
          });
          break;
        }
        case ModalType.RENAME: {
          switch (this.props.entityType) {
            case EntityType.CORPORA:
              corporaClient.rename({folderId: this.props.itemIndex, name}).then((response: any) => {
                if (isSuccessfulResponse(response)) {
                  this.props.changeItem(this.props.itemIndex, {name});
                  this.closeModalWindow();
                } else {
                  this.setState({
                    errors: {
                      ...this.state.errors,
                      form: response.errors ? response.errors : response.message,
                    },
                  });
                }
              });
              break;
            case EntityType.MODELS:
              modelClient.rename({modelId: this.props.itemIndex, name}).then((response: any) => {
                if (isSuccessfulResponse(response)) {
                  this.props.changeItem(this.props.itemIndex, {name});
                  this.closeModalWindow();
                } else {
                  this.setState({
                    errors: {
                      ...this.state.errors,
                      form: response.errors ? response.errors : response.message,
                    },
                  });
                }
              });
              break;
          }
          break;
        }
        case ModalType.DELETE: {
          switch (this.props.entityType) {
            case EntityType.CORPORA:
              corporaClient.delete(this.props.itemIndex).then((response: any) => {
                if (isSuccessfulResponse(response)) {
                  this.props.changeItem(this.props.itemIndex, {name});
                  this.closeModalWindow();
                } else {
                  this.setState({
                    errors: {
                      ...this.state.errors,
                      form: response.errors ? response.errors : response.message,
                    },
                  });
                }
              });
              break;
            case EntityType.MODELS:
              modelClient.delete(this.props.itemIndex).then((response: any) => {
                if (isSuccessfulResponse(response)) {
                  this.props.changeItem(this.props.itemIndex, {name});
                  this.closeModalWindow();
                } else {
                  this.setState({
                    errors: {
                      ...this.state.errors,
                      form: response.errors ? response.errors : response.message,
                    },
                  });
                }
              });
              break;
          }
          break;
        }
        case ModalType.LEGEND_DOCUMENT_FROM_CORPORA: {
          const entitiesFiles = this.state.entities;
          if (isNotEmptyObject(entitiesFiles)) {
            const listOfTextId = entitiesFiles.map((item: any) => item.value);
            const params = {
              joinOption: this.state.combineCheckbox,
              listOfTextId,
              name: this.state.name,
            };
            modelClient.postModelTextItemModelId(this.props.itemIndex, params).then((response: any) => {
              if (isSuccessfulResponse(response)) {
                payload.itemType = AddItemTypes.DOCUMENTS_FROM_CORPORA;
                Object.keys(response.data).forEach((index) => {
                    modelClient.postModelPredict(response.data[index].entityId, payload).then((prediction: any) => {
                      if (isSuccessfulResponse(prediction)) {
                        const newChartData = [{
                          data: prediction.data,
                          entityId: response.data[index].entityId,
                          entityName: response.data[index].entityName,
                          legendType: payload.itemType,
                          icon: <FaFile />,
                          content: params.joinOption ? entitiesFiles : [entitiesFiles[index]],
                        }];
                        this.props.changeChartData(newChartData, false);
                      }
                    });
                  },
                );
                this.closeModalWindow();
              } else {
                this.setState({
                  errors: {
                    ...this.state.errors,
                    form: response.errors ? response.errors : response.message,
                  },
                });
              }
            });
          } else {
            this.setState({
              errors: {
                ...this.state.errors,
                form: "Please select file",
              },
            });
          }
          break;
        }
        case ModalType.LEGEND_ADHOC_DOCUMENT: {
          const text = this.state.adHocText;
          modelClient.postModelAdhocTextModelId(this.props.itemIndex, {
            text,
            name: this.state.name,
          }).then((response: any) => {
            payload.itemType = AddItemTypes.ADHOC_DOCUMENT;
            if (isSuccessfulResponse(response)) {
              modelClient.postModelPredict(response.data.adhocTextId, payload).then((prediction: any) => {
                if (isSuccessfulResponse(prediction)) {
                  const newChartData = [{
                    data: prediction.data,
                    entityId: response.data.adhocTextId,
                    entityName: response.data.textName,
                    legendType: payload.itemType,
                    icon:  <FaFont />,
                    content: text,
                  }];
                  this.props.changeChartData(newChartData, false);
                }
              });
              this.closeModalWindow();
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  form: response.errors ? response.errors : response.message,
                },
              });
            }
          });
          break;
        }
        case ModalType.LEGEND_CORPUS_BASELINE: {
          const corpusBaseline = this.state.corpusBaseline;
          modelClient.postModelFolderItem({
            modelId: this.props.itemIndex,
            folderId: corpusBaseline.value,
            name: this.state.name,
          }).then((response: any) => {
            payload.itemType = AddItemTypes.CORPUS_BASELINE;
            if (isSuccessfulResponse(response)) {
              modelClient.postModelPredict(response.data.entityId, payload)
                .then((prediction: any) => {
                  if (isSuccessfulResponse(prediction)) {
                    const newChartData = [{
                      data: prediction.data,
                      entityId: response.data.entityId,
                      entityName: response.data.entityName,
                      legendType: payload.itemType,
                      icon:  <FaBook />,
                      content: corpusBaseline,
                    }];
                    this.props.changeChartData(newChartData, false);
                  }
                });
              this.closeModalWindow();
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  form: response.errors ? response.errors : response.message,
                },
              });
            }
          });
          break;
        }
        case ModalType.LEGEND_FROM_URL: {
          const links = Object.entries(this.state)
            .filter((e) => /^link_\d+$/.test(e[0])).map((e) => e[1]);
          const params = {
            joinOption: this.state.combineCheckbox,
            listOfUrl: links,
            listOName: this.state.combineCheckbox || links.length === 1
              ? [this.state.name]
              : links.map((l, i) => `${this.state.name}_${i}`),
          };
          modelClient.postUrl(this.props.itemIndex, params)
            .then((r: any) => {
              payload.itemType = AddItemTypes.FROM_URL;
              if (isSuccessfulResponse(r)) {
                Object.keys(r.data).forEach((index: any) => {
                    modelClient.postModelPredict(r.data[index].entityId, payload).then((prediction: any) => {
                      if (isSuccessfulResponse(prediction)) {
                        const newChartData = [{
                          data: prediction.data,
                          entityId: r.data[index].entityId,
                          entityName: r.data[index].entityName,
                          legendType: payload.itemType,
                          icon:  <FaPaperclip />,
                          content: params.joinOption ? links : [links[index]],
                        }];
                        this.props.changeChartData(newChartData, false);
                      }
                    });
                  },
                );
                this.closeModalWindow();
              } else {
                this.setState({
                  errors: {
                    ...this.state.errors,
                    form: r.errors && typeof r.errors === "string"
                      ? r.errors
                      : r.errors
                        ? Object.values(r.errors).join(" ")
                        : r.message,
                  },
                });
              }
            });
          break;
        }
        case ModalType.EDIT_DOCUMENTS_FROM_CORPORA: {
          modelClient.deleteModelTextItemEntityId(this.state.entityId);
          const entitiesFiles = this.state.entities;
          if (isNotEmptyObject(entitiesFiles)) {
            const listOfTextId = entitiesFiles.map((item: any) => item.value);
            modelClient.postModelTextItemModelId(this.props.itemIndex, {
              joinOption: this.state.combineCheckbox,
              listOfTextId,
              name: this.state.name,
            }).then((response: any) => {
              if (isSuccessfulResponse(response)) {
                payload.itemType = AddItemTypes.DOCUMENTS_FROM_CORPORA;
                Object.keys(response.data).forEach((index) => {
                  this.props.legendItemEdit(this.state.entityId, response.data[index].entityName,
                    entitiesFiles, response.data[index].entityId);
                });
                this.closeModalWindow();
              } else {
                this.setState({
                  errors: {
                    ...this.state.errors,
                    form: response.errors ? response.errors : response.message,
                  },
                });
              }
            });
          } else {
            this.setState({
              errors: {
                ...this.state.errors,
                form: "Please select file",
              },
            });
          }
          break;
        }
        case ModalType.EDIT_ADHOC_DOCUMENT: {
          modelClient.putModelAdhocTextAdhocTextId(this.state.entityId, {
            text: this.state.adHocText,
            name: this.state.name,
          }).then((response: any) => {
            if (isSuccessfulResponse(response) && isNotEmptyObject(response.data)) {
              payload.itemType = AddItemTypes.ADHOC_DOCUMENT;
              this.props.legendItemEdit(response.data.adhocTextId, response.data.textName, response.data.content);
              this.closeModalWindow();
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  form: response.errors && typeof response.errors === "string"
                    ? response.errors
                    : response.errors
                      ? Object.values(response.errors).join(" ")
                      : response.message,
                },
              });
            }
          });
          break;
        }
        case ModalType.EDIT_CORPUS_BASELINE: {
          const corpusBaseline = this.state.corpusBaseline;
          modelClient.deleteModelFolderItemEntityId(this.state.entityId);
          modelClient.postModelFolderItem({
            modelId: this.props.itemIndex,
            folderId: corpusBaseline.value,
            name: this.state.name,
          }).then((response: any) => {
            if (isSuccessfulResponse(response)) {
              this.props.legendItemEdit(this.state.entityId, response.data.entityName,
                corpusBaseline, response.data.entityId);
              this.closeModalWindow();
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  form: response.errors ? response.errors : response.message,
                },
              });
            }
          });
          break;
        }
        case ModalType.EDIT_FROM_URL: {
          modelClient.putModelUrlOperations({entityId: this.state.entityId, name})
            .then((response: any) => {
              if (isSuccessfulResponse(response) && isNotEmptyObject(response.data)) {
                this.props.legendItemEdit(response.data.entityId, response.data.entityName, this.state.links);
                this.closeModalWindow();
              } else {
                this.setState({
                  errors: {
                    ...this.state.errors,
                    form: response.errors && typeof response.errors === "string"
                      ? response.errors
                      : response.errors
                        ? Object.values(response.errors).join(" ")
                        : response.message,
                  },
                });
              }
            });
          break;
        }
        case ModalType.SEND_GRAPH: {
          recommendationClient.postSendEmail(this.props.chartSettings.recommendation, this.props.chartSettings.file, name)
            .then((response) => {
              if (isSuccessfulResponse(response)) {
                this.closeModalWindow();
              } else {
                this.setState({
                  errors: {
                    ...this.state.errors,
                    form: response.errors && typeof response.errors === "object"
                      ? Object.values(response.errors).join(" ")
                      : response.errors,
                  },
                });
              }
            });
          break;
        }
      }
    }
  }

  public handleChange = (event: SyntheticEvent<FormElementProps>): void => {
    const name = event.currentTarget.name;
    const target = event.target as HTMLInputElement;
    const value = name === "files" ? target.files : event.currentTarget.value;
    if (name === "combineCheckbox") {
      this.setState({
        combineCheckbox: !this.state.combineCheckbox,
      });
      return;
    }
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: !value ? "The field is required." : "",
      },
    });
  }

  public handleSelect = (value: any, action: any): void => {
    this.setState({
      [action.name]: value,
    });
  }

  public closeModalWindow = () => {
    this.setState({
      name: "",
      files: undefined,
      corpusItem: "",
      errors: {},
      adHocText: "",
      combineCheckbox: false,
      entities: [],
      corpusBaseline: null,
    });
    this.props.closeModalWindow();
  }

  public setEditLegendProps = (): void => {
    const item = this.props.chartSettings.item;
    let stateName = "";
    if (item && item.entityId) {
      switch (this.props.type) {
        case ModalType.EDIT_DOCUMENTS_FROM_CORPORA:
          stateName = LegendState.ENTITIES;
          break;
        case ModalType.EDIT_ADHOC_DOCUMENT:
          stateName = LegendState.ADHOC_TEXT;
          break;
        case ModalType.EDIT_CORPUS_BASELINE:
          stateName = LegendState.CORPUS_BASELINE;
          break;
        case ModalType.EDIT_FROM_URL:
          stateName = LegendState.LINKS;
          break;
      }
      this.setState({
        combineCheckbox: true,
        name: item.entityName,
        [stateName]: item.content,
        entityId: item.entityId,
      });
    }
  }

  public render() {
    let title;
    let body;
    let footer;
    let itemName = "";
    let tableName;
    switch (this.props.entityType) {
      case EntityType.CORPORA: {
        this.props.corpora.forEach((item) => {
          if (item.folderId === this.props.itemIndex) {
            itemName = item.folderName;
          }
        });
        tableName = "corpus";
        break;
      }
      case EntityType.MODELS: {
        this.props.models.forEach((item) => {
          if (item.modelId === this.props.itemIndex) {
            itemName = item.modelName;
          }
        });
        tableName = "model";
        break;
      }
      case EntityType.DICTIONARY: {
        tableName = "dictionary";
        break;
      }
      case EntityType.RECOMMENDATION: {
        tableName = "recommendation";
        break;
      }
    }
    switch (this.props.type) {
      case ModalType.UPLOAD_NEW_CORPUS:
        title = `Upload new ${tableName}`;
        body = <UploadBody
          handleChange={this.handleChange}
          name={this.state.name}
          errors={this.state.errors}
          files={this.state.files}
          displayName={true}
          multipleFiles={true}
        />;
        footer = <UploadFooter />;
        break;
      case ModalType.UPLOAD_NEW_DICTIONARY:
      case ModalType.UPLOAD_NEW_RECOMMENDATION:
        title = `Upload new ${tableName}`;
        body = <UploadBody
          handleChange={this.handleChange}
          name={this.state.name}
          errors={this.state.errors}
          files={this.state.files}
          displayName={false}
          multipleFiles={false}
        />;
        footer = <UploadFooter />;
        break;
      case ModalType.TRAIN_NEW_MODEL:
        title = `Train new ${tableName}`;
        body = <TrainBody
          handleChange={this.handleChange}
          name={this.state.name}
          corpora={this.props.corpora}
          corpusItem={this.state.corpusItem}
          errors={this.state.errors}
        />;
        footer = <TrainFooter />;
        break;
      case ModalType.RENAME:
        title = `Rename ${tableName} ${itemName}`;
        body = <RenameBody
          handleChange={this.handleChange}
          name={this.state.name}
          setName={this.setName}
          errors={this.state.errors}
        />;
        footer = <RenameFooter />;
        break;
      case ModalType.DELETE:
        title = `Delete ${tableName} ${itemName}`;
        body = <div>
          {`Are you sure you want to delete ${itemName}?`}
          <Feedback valid={false} errors={this.state.errors.form} forceShow={true} />
        </div>;
        footer = <Delete />;
        break;
      case ModalType.LEGEND_DOCUMENT_FROM_CORPORA:
        title = <div><FaFile />{` Documents from corpora`}</div>;
        body = <DocumentsFromCorporaBody
          name={this.state.name}
          handleChange={this.handleChange}
          handleSelect={this.handleSelect}
          showCheckbox={true}
          combineCheckbox={this.state.combineCheckbox}
          documents={this.props.corpora}
          errors={this.state.errors}
          setEditLegendProps={this.setEditLegendProps}
          entities={this.state.entities}
        />;
        footer = <LegendFooter />;
        break;
      case ModalType.EDIT_DOCUMENTS_FROM_CORPORA:
        title = <div><FaFile />{` Documents from corpora`}</div>;
        body = <DocumentsFromCorporaBody
          name={this.state.name}
          handleChange={this.handleChange}
          handleSelect={this.handleSelect}
          showCheckbox={false}
          combineCheckbox={this.state.combineCheckbox}
          documents={this.props.corpora}
          errors={this.state.errors}
          setEditLegendProps={this.setEditLegendProps}
          entities={this.state.entities}
        />;
        footer = <EditLegendFooter />;
        break;
      case ModalType.LEGEND_ADHOC_DOCUMENT:
        title = <div><FaFont />{` Ad-hoc document`}</div>;
        body = <AdHocDocument
          adHocText={this.state.adHocText}
          name={this.state.name}
          handleChange={this.handleChange}
          errors={this.state.errors}
          setEditLegendProps={this.setEditLegendProps}
        />;
        footer = <LegendFooter />;
        break;
      case ModalType.EDIT_ADHOC_DOCUMENT:
        title = <div><FaFont />{` Ad-hoc document`}</div>;
        body = <AdHocDocument
          adHocText={this.state.adHocText}
          name={this.state.name}
          handleChange={this.handleChange}
          errors={this.state.errors}
          setEditLegendProps={this.setEditLegendProps}
        />;
        footer = <EditLegendFooter />;
        break;
      case ModalType.LEGEND_CORPUS_BASELINE:
        title = <div><FaBook />{` Corpus baseline`}</div>;
        body = <CorpusBaseline
          corpora={this.props.corpora}
          name={this.state.name}
          handleChange={this.handleChange}
          handleSelect={this.handleSelect}
          errors={this.state.errors}
          corpusBaseline={this.state.corpusBaseline}
          setEditLegendProps={this.setEditLegendProps}
        />;
        footer = <LegendFooter />;
        break;
      case ModalType.EDIT_CORPUS_BASELINE:
        title = <div><FaBook />{` Corpus baseline`}</div>;
        body = <CorpusBaseline
          corpora={this.props.corpora}
          name={this.state.name}
          handleChange={this.handleChange}
          handleSelect={this.handleSelect}
          errors={this.state.errors}
          corpusBaseline={this.state.corpusBaseline}
          setEditLegendProps={this.setEditLegendProps}
        />;
        footer = <EditLegendFooter />;
        break;
      case ModalType.LEGEND_FROM_URL:
        title = <h4>Upload link</h4>;
        body = <FromUrl
          name={this.state.name}
          combineCheckbox={this.state.combineCheckbox}
          handleChange={this.handleChange}
          errors={this.state.errors}
        />;
        footer = <LegendFooter />;
        break;
      case ModalType.EDIT_FROM_URL:
        title = <h4>Upload link</h4>;
        body = <EditFromUrl
          name={this.state.name}
          handleChange={this.handleChange}
          errors={this.state.errors}
          links={this.state.links}
          setEditLegendProps={this.setEditLegendProps}
        />;
        footer = <EditLegendFooter />;
        break;
      case ModalType.SEND_GRAPH: {
        title = <h4>Enter target email address</h4>;
        body = <SendGraphBody
          name={this.state.name}
          handleChange={this.handleChange}
          errors={this.state.errors}
        />;
        footer = <SendGraphFooter />;
      }
    }
    return (
      <Modal onHide={this.closeModalWindow} show={this.props.show}>
        <Modal.Header closeButton={true}>
          {title}
        </Modal.Header>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Body>
            {body}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-default"
              variant="secondary"
              onClick={this.closeModalWindow}
            >
              Cancel
            </Button>
            {footer}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
