// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdhocTextContent
 */
export interface AdhocTextContent {
    /**
     * Text id.
     * @type {number}
     * @memberof AdhocTextContent
     */
    adhocTextId: number;
    /**
     * Text name.
     * @type {string}
     * @memberof AdhocTextContent
     */
    textName: string;
    /**
     * Text type: url or text.
     * @type {string}
     * @memberof AdhocTextContent
     */
    type: string;
    /**
     * Text content.
     * @type {string}
     * @memberof AdhocTextContent
     */
    content: string;
}

export function AdhocTextContentFromJSON(json: any): AdhocTextContent {
    return AdhocTextContentFromJSONTyped(json, false);
}

export function AdhocTextContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdhocTextContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adhocTextId': json['adhocTextId'],
        'textName': json['textName'],
        'type': json['type'],
        'content': json['content'],
    };
}

export function AdhocTextContentToJSON(value?: AdhocTextContent): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adhocTextId': value.adhocTextId,
        'textName': value.textName,
        'type': value.type,
        'content': value.content,
    };
}


