// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Login,
    LoginFromJSON,
    LoginToJSON,
    LoginOutput,
    LoginOutputFromJSON,
    LoginOutputToJSON,
    RefreshTokenOutput,
    RefreshTokenOutputFromJSON,
    RefreshTokenOutputToJSON,
    Register,
    RegisterFromJSON,
    RegisterToJSON,
    RegisterOutputError,
    RegisterOutputErrorFromJSON,
    RegisterOutputErrorToJSON,
    RegisterOutputSuccess,
    RegisterOutputSuccessFromJSON,
    RegisterOutputSuccessToJSON,
    ResetEmailOutput,
    ResetEmailOutputFromJSON,
    ResetEmailOutputToJSON,
    ResetPassword,
    ResetPasswordFromJSON,
    ResetPasswordToJSON,
    ResetTokenOutputError,
    ResetTokenOutputErrorFromJSON,
    ResetTokenOutputErrorToJSON,
    ResetTokenOutputSuccess,
    ResetTokenOutputSuccessFromJSON,
    ResetTokenOutputSuccessToJSON,
    SendEmailReset,
    SendEmailResetFromJSON,
    SendEmailResetToJSON,
    TokenExpiredOutput,
    TokenExpiredOutputFromJSON,
    TokenExpiredOutputToJSON,
    UpdateInfo,
    UpdateInfoFromJSON,
    UpdateInfoToJSON,
    UserInfoOutput,
    UserInfoOutputFromJSON,
    UserInfoOutputToJSON,
} from '../models';

export interface GetGetUserInfoRequest {
    authorization: string;
}

export interface GetResfreshTokenRequest {
    authorization: string;
}

export interface PostLoginRequest {
    payload: Login;
}

export interface PostRegisterRequest {
    payload: Register;
}

export interface PostResetWithTokenRequest {
    payload: ResetPassword;
}

export interface PostSendResetEmailRequest {
    payload: SendEmailReset;
}

export interface PostUpdateUserInfoRequest {
    authorization: string;
    payload: UpdateInfo;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Returns user info: first name, last name, email, company, position, role.
     * Get user info
     */
    async getGetUserInfoRaw(requestParameters: GetGetUserInfoRequest): Promise<runtime.ApiResponse<UserInfoOutput>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getGetUserInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/users/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoOutputFromJSON(jsonValue));
    }

   /**
    * Returns user info: first name, last name, email, company, position, role.
    * Get user info
    */
    async getGetUserInfo(requestParameters: GetGetUserInfoRequest): Promise<UserInfoOutput> {
        const response = await this.getGetUserInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns refreshed access token.
     * Refresh access token
     */
    async getResfreshTokenRaw(requestParameters: GetResfreshTokenRequest): Promise<runtime.ApiResponse<RefreshTokenOutput>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getResfreshToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/users/tokens`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenOutputFromJSON(jsonValue));
    }

   /**
    * Returns refreshed access token.
    * Refresh access token
    */
    async getResfreshToken(requestParameters: GetResfreshTokenRequest): Promise<RefreshTokenOutput> {
        const response = await this.getResfreshTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns access token and refresh token if login is successful.
     * Login user
     */
    async postLoginRaw(requestParameters: PostLoginRequest): Promise<runtime.ApiResponse<LoginOutput>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postLogin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginOutputFromJSON(jsonValue));
    }

   /**
    * Returns access token and refresh token if login is successful.
    * Login user
    */
    async postLogin(requestParameters: PostLoginRequest): Promise<LoginOutput> {
        const response = await this.postLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns user info if registration is successful.
     * Registration user
     */
    async postRegisterRaw(requestParameters: PostRegisterRequest): Promise<runtime.ApiResponse<RegisterOutputSuccess>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postRegister.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/registration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns user info if registration is successful.
    * Registration user
    */
    async postRegister(requestParameters: PostRegisterRequest): Promise<RegisterOutputSuccess> {
        const response = await this.postRegisterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns user info, access token and refresh token if resetting is successful.
     * Reset user\'s password
     */
    async postResetWithTokenRaw(requestParameters: PostResetWithTokenRequest): Promise<runtime.ApiResponse<ResetTokenOutputSuccess>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postResetWithToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/reset/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetTokenOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns user info, access token and refresh token if resetting is successful.
    * Reset user\'s password
    */
    async postResetWithToken(requestParameters: PostResetWithTokenRequest): Promise<ResetTokenOutputSuccess> {
        const response = await this.postResetWithTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send password reset link to email.
     * Send link to user
     */
    async postSendResetEmailRaw(requestParameters: PostSendResetEmailRequest): Promise<runtime.ApiResponse<ResetEmailOutput>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postSendResetEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/reset/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailResetToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetEmailOutputFromJSON(jsonValue));
    }

   /**
    * Send password reset link to email.
    * Send link to user
    */
    async postSendResetEmail(requestParameters: PostSendResetEmailRequest): Promise<ResetEmailOutput> {
        const response = await this.postSendResetEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns user info if data is successfully updated.
     * Update user info
     */
    async postUpdateUserInfoRaw(requestParameters: PostUpdateUserInfoRequest): Promise<runtime.ApiResponse<RegisterOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postUpdateUserInfo.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling postUpdateUserInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/users/updates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInfoToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns user info if data is successfully updated.
    * Update user info
    */
    async postUpdateUserInfo(requestParameters: PostUpdateUserInfoRequest): Promise<RegisterOutputSuccess> {
        const response = await this.postUpdateUserInfoRaw(requestParameters);
        return await response.value();
    }

}
