export enum ChartColors {
  DARK_BLUE = "#1f77b4",
  ORANGE = "#ff7f0e",
  GREEN = "#2ca02c",
  RED = "#d62728",
  PURPLE = "#9467bd",
  BROWN = "#8c564b",
  PINK = "#e377c2",
  GREY = "#7f7f7f",
  YELLOW = "#bcbd22",
  BLUE = "#17becf",
}

export const COLOR_BY_INDEX = Object.values(ChartColors);

export enum FilterColors {
  "#33a02c",
  "#fb9a99",
  "#80b1d3",
  "#fb8072",
  "#b3de69",
  "#1f78b4",
  "#fdb462",
  "#b15928",
  "#ffed6f",
  "#bebada",
  "#8dd3c7",
  "#bc80bd",
  "#b2df8a",
  "#e31a1c",
  "#ffffb3",
  "#fdbf6f",
  "#6a3d9a",
  "#fccde5",
  "#d9d9d9",
  "#a6cee3",
  "#ff7f00",
  "#cab2d6",
  "#ffff99",
  "#ccebc5",
}
