// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HeatmapData,
    HeatmapDataFromJSON,
    HeatmapDataFromJSONTyped,
    HeatmapDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface HeatmapOutput
 */
export interface HeatmapOutput {
    /**
     * 
     * @type {HeatmapData}
     * @memberof HeatmapOutput
     */
    data: HeatmapData;
    /**
     * Error message.
     * @type {string}
     * @memberof HeatmapOutput
     */
    errors: string;
}

export function HeatmapOutputFromJSON(json: any): HeatmapOutput {
    return HeatmapOutputFromJSONTyped(json, false);
}

export function HeatmapOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeatmapOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': HeatmapDataFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function HeatmapOutputToJSON(value?: HeatmapOutput): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': HeatmapDataToJSON(value.data),
        'errors': value.errors,
    };
}


