import React, { Component } from "react";
import FormElement from "../form/FormElement";
import Feedback from "../form/Feedback";
import { EntityModalProps } from "./index";

interface AdHocDocumentProps {
  adHocText: string;
  setEditLegendProps: () => void;
}

export class AdHocDocument extends Component<EntityModalProps & AdHocDocumentProps> {
  public componentDidMount(): void {
    this.props.setEditLegendProps();
  }

  public render() {
    return (
      <>
        <FormElement
          name="name"
          label="Label"
          value={this.props.name}
          required={true}
          handleChange={this.props.handleChange}
          errors={this.props.errors.name}
        />
        <FormElement
          name="adHocText"
          label="Text"
          controlAs="textarea"
          value={this.props.adHocText}
          required={true}
          handleChange={this.props.handleChange}
          errors={this.props.errors.adHocText}
        />
        <Feedback valid={false} errors={this.props.errors.form} forceShow={true} />
      </>
    );
  }
}
