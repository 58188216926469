import React, { FC } from "react";
import { Button, Table } from "react-bootstrap";
import { FaTrashAlt, GoPencil } from "react-icons/all";
import { EntityType } from "../../../constants";
import { OpenModalFunction } from "../Modal";
import { ModalType } from "../Modal/constants";
import { isNotEmptyObject } from "../../../utility/element";
import ReactTooltip from "react-tooltip";
// import { CorporaItem, DictionaryItem, ModelItem } from "../../../types";

export interface BodyProps {
  entityType: EntityType;
  items: any;
  // items: CorporaItem[] | DictionaryItem[] | ModelItem[];
  singular: string;
  plural: string;
  openModalWindow: OpenModalFunction;
}

const Body: FC<BodyProps> = ({
  entityType,
  items,
  plural,
  singular,
  openModalWindow,
}) => {
  let itemName: string;
  let itemIndex: string;
  let allFeatures: boolean = true;
  let linkTip: string;
  switch (entityType) {
    case EntityType.CORPORA:
      itemName = "folderName";
      itemIndex = "folderId";
      linkTip = "Click to view in a Corpora page";
      break;
    case EntityType.MODELS:
      itemName = "modelName";
      itemIndex = "modelId";
      linkTip = "Click to open a Model page";
      break;
    case EntityType.DICTIONARY:
      itemName = "dictionaryName";
      itemIndex = "dictionaryId";
      allFeatures = false;
      break;
    case EntityType.RECOMMENDATION:
      itemName = "recommendationName";
      itemIndex = "recommendationId";
      allFeatures = false;
      break;
  }
  return (
  <Table className="table-condensed" size="sm">
    <thead>
    <tr>
      <th>Name</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    {isNotEmptyObject(items) && items.map((item: any, index: any) => {
      return item && (
        <tr key={index}>
          <td>
            {allFeatures && <a
              href={`/${plural}/${item[itemIndex]}`}
              data-tip={linkTip}
            >
              {item[itemName]}
              <ReactTooltip />
            </a>}
            {!allFeatures && item[itemName]}
          </td>
          <td>
            {allFeatures && <>
              <Button
                variant="link"
                size="sm"
                onClick={() => openModalWindow(ModalType.RENAME, entityType, item[itemIndex])}
              >
                <GoPencil /> {` Rename`}
              </Button>
              <Button
                data-tip={`Delete ${singular}`}
                variant="danger"
                size="sm"
                onClick={() => openModalWindow(ModalType.DELETE, entityType, item[itemIndex])}
              >
                <FaTrashAlt />
                <ReactTooltip />
              </Button></>
            }
          </td>
        </tr>
      );
    })}
    </tbody>
  </Table>
); };

export default Body;
