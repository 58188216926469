// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegisterDataSuccess,
    RegisterDataSuccessFromJSON,
    RegisterDataSuccessFromJSONTyped,
    RegisterDataSuccessToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResetTokenDataSuccess
 */
export interface ResetTokenDataSuccess {
    /**
     * 
     * @type {RegisterDataSuccess}
     * @memberof ResetTokenDataSuccess
     */
    user: RegisterDataSuccess;
    /**
     * Access token.
     * @type {string}
     * @memberof ResetTokenDataSuccess
     */
    accessToken: string;
    /**
     * Refresh token.
     * @type {string}
     * @memberof ResetTokenDataSuccess
     */
    refreshToken: string;
}

export function ResetTokenDataSuccessFromJSON(json: any): ResetTokenDataSuccess {
    return ResetTokenDataSuccessFromJSONTyped(json, false);
}

export function ResetTokenDataSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetTokenDataSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': RegisterDataSuccessFromJSON(json['user']),
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
    };
}

export function ResetTokenDataSuccessToJSON(value?: ResetTokenDataSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': RegisterDataSuccessToJSON(value.user),
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
    };
}


