// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HeatmapAnalysis,
    HeatmapAnalysisFromJSON,
    HeatmapAnalysisFromJSONTyped,
    HeatmapAnalysisToJSON,
} from './';

/**
 * 
 * @export
 * @interface HeatmapNgram
 */
export interface HeatmapNgram {
    /**
     * 
     * @type {Array<HeatmapAnalysis>}
     * @memberof HeatmapNgram
     */
    _1: Array<HeatmapAnalysis>;
    /**
     * 
     * @type {Array<HeatmapAnalysis>}
     * @memberof HeatmapNgram
     */
    _2: Array<HeatmapAnalysis>;
    /**
     * 
     * @type {Array<HeatmapAnalysis>}
     * @memberof HeatmapNgram
     */
    _3: Array<HeatmapAnalysis>;
    /**
     * 
     * @type {Array<HeatmapAnalysis>}
     * @memberof HeatmapNgram
     */
    _4: Array<HeatmapAnalysis>;
    /**
     * 
     * @type {Array<HeatmapAnalysis>}
     * @memberof HeatmapNgram
     */
    _5: Array<HeatmapAnalysis>;
}

export function HeatmapNgramFromJSON(json: any): HeatmapNgram {
    return HeatmapNgramFromJSONTyped(json, false);
}

export function HeatmapNgramFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeatmapNgram {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_1': (json['1'] as Array<any>).map(HeatmapAnalysisFromJSON),
        '_2': (json['2'] as Array<any>).map(HeatmapAnalysisFromJSON),
        '_3': (json['3'] as Array<any>).map(HeatmapAnalysisFromJSON),
        '_4': (json['4'] as Array<any>).map(HeatmapAnalysisFromJSON),
        '_5': (json['5'] as Array<any>).map(HeatmapAnalysisFromJSON),
    };
}

export function HeatmapNgramToJSON(value?: HeatmapNgram): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '1': (value._1 as Array<any>).map(HeatmapAnalysisToJSON),
        '2': (value._2 as Array<any>).map(HeatmapAnalysisToJSON),
        '3': (value._3 as Array<any>).map(HeatmapAnalysisToJSON),
        '4': (value._4 as Array<any>).map(HeatmapAnalysisToJSON),
        '5': (value._5 as Array<any>).map(HeatmapAnalysisToJSON),
    };
}


