// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryData,
    CategoryDataFromJSON,
    CategoryDataFromJSONTyped,
    CategoryDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface PredictData
 */
export interface PredictData {
    /**
     * 
     * @type {Array<CategoryData>}
     * @memberof PredictData
     */
    characterStrengths: Array<CategoryData>;
    /**
     * Number of words in the text.
     * @type {number}
     * @memberof PredictData
     */
    numWords: number;
    /**
     * Sentiment.
     * @type {string}
     * @memberof PredictData
     */
    sentiment: string;
    /**
     * Unit.
     * @type {string}
     * @memberof PredictData
     */
    unit: string;
}

export function PredictDataFromJSON(json: any): PredictData {
    return PredictDataFromJSONTyped(json, false);
}

export function PredictDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'characterStrengths': (json['characterStrengths'] as Array<any>).map(CategoryDataFromJSON),
        'numWords': json['numWords'],
        'sentiment': json['sentiment'],
        'unit': json['unit'],
    };
}

export function PredictDataToJSON(value?: PredictData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'characterStrengths': (value.characterStrengths as Array<any>).map(CategoryDataToJSON),
        'numWords': value.numWords,
        'sentiment': value.sentiment,
        'unit': value.unit,
    };
}


