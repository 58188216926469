import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { FaCog } from "react-icons/fa";
import { CorporaItem } from "../../../types";
import Feedback from "../form/Feedback";
import { EntityModalProps } from "./index";
import FormElement from "../form/FormElement";

interface TrainModel {
  corpora: CorporaItem[];
  corpusItem: string;
}

export class TrainBody extends Component<EntityModalProps & TrainModel> {
  public render() {
    return (<>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            onChange={this.props.handleChange}
            value={this.props.name}
            required={true}
          />
          <Feedback valid={false} errors={this.props.errors.name} forceShow={true} />
        </Form.Group>
        <Form.Group>
          <FormElement
            controlAs="select"
            name="corpusItem"
            label="Corpus"
            value={this.props.corpusItem}
            handleChange={this.props.handleChange}
            required={false}
          >
            {/*FIXME: Handle inside FormElement*/}
            {[<option key="-1" value="">Select</option>, ...(this.props.corpora.map((corpus) => <option key={corpus.folderId}>{corpus.folderName}</option>))]}
          </FormElement>
          <Feedback valid={false} errors={this.props.errors.corpusItem} />
          <Feedback valid={false} errors={this.props.errors.form} forceShow={true} />
        </Form.Group>
      </>
    );
  }
}

export const TrainFooter = () => {
  return (
    <Button variant="primary" type="submit">
      <FaCog /> Train
    </Button>
  );
};
