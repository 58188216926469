// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FolderDataEntity
 */
export interface FolderDataEntity {
    /**
     * Corpora folder id.
     * @type {number}
     * @memberof FolderDataEntity
     */
    folderId: number;
    /**
     * Corpora folder name.
     * @type {string}
     * @memberof FolderDataEntity
     */
    folderName: string;
    /**
     * Created time.
     * @type {Date}
     * @memberof FolderDataEntity
     */
    created: Date;
    /**
     * Entity id.
     * @type {number}
     * @memberof FolderDataEntity
     */
    entityId: number;
    /**
     * Entity name.
     * @type {string}
     * @memberof FolderDataEntity
     */
    entityName: string;
}

export function FolderDataEntityFromJSON(json: any): FolderDataEntity {
    return FolderDataEntityFromJSONTyped(json, false);
}

export function FolderDataEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FolderDataEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folderId': json['folderId'],
        'folderName': json['folderName'],
        'created': new Date(json['created']),
        'entityId': json['entityId'],
        'entityName': json['entityName'],
    };
}

export function FolderDataEntityToJSON(value?: FolderDataEntity): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folderId': value.folderId,
        'folderName': value.folderName,
        'created': value.created.toISOString(),
        'entityId': value.entityId,
        'entityName': value.entityName,
    };
}


