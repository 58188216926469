// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FolderData
 */
export interface FolderData {
    /**
     * Corpora folder id.
     * @type {number}
     * @memberof FolderData
     */
    folderId: number;
    /**
     * Corpora folder name.
     * @type {string}
     * @memberof FolderData
     */
    folderName: string;
    /**
     * Created time.
     * @type {Date}
     * @memberof FolderData
     */
    created: Date;
}

export function FolderDataFromJSON(json: any): FolderData {
    return FolderDataFromJSONTyped(json, false);
}

export function FolderDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FolderData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folderId': json['folderId'],
        'folderName': json['folderName'],
        'created': new Date(json['created']),
    };
}

export function FolderDataToJSON(value?: FolderData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folderId': value.folderId,
        'folderName': value.folderName,
        'created': value.created.toISOString(),
    };
}


