import {
  RenameModel, ModelApi,
  CreateModel, JoinTextOption,
  TypeOfModelItem, ListOfUrls,
  EntityId, DownloadCsvFile,
  ItemType,
  AdhocText, EntityFolderName, EntityTextName, UrlName,
} from "../../client/src";
import BaseClient from "./BaseClient";

export class ModelClient extends BaseClient {
  public id = "ModelClient";
  private modelApi = new ModelApi(BaseClient.DEFAULT_CLIENT_CONFIGURATION);

  constructor() {
    super();
    return super.cache(this);
  }

  public async getTable() {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.getModelOperations({ authorization: authorizationHeader })),
    );
  }

  public async add(params: CreateModel) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.postModelOperations({
        authorization: authorizationHeader,
        payload: params,
      })),
    );
  }

  public async rename(params: RenameModel) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.putModelOperations({
        authorization: authorizationHeader,
        payload: params,
      })),
    );
  }

  public async delete(modelId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.deleteModelModelId({
        authorization: authorizationHeader,
        modelId,
      })),
    );
  }

  public async getModelById(modelId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.getModelModelId({
        authorization: authorizationHeader,
        modelId,
      })),
    );
  }

  public async postModelTextItemModelId(modelId: number, params: JoinTextOption) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.postModelTextItemModelId({
        authorization: authorizationHeader,
        modelId,
        payload: params,
      })),
    );
  }

  public async postModelAdhocTextModelId(modelId: number, params: AdhocText) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.postModelAdhocTextModelId({
        authorization: authorizationHeader,
        modelId,
        payload: params,
      })),
    );
  }

  public async postModelFolderItem(payload: EntityFolderName) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.postModelFolderItem({
        authorization: authorizationHeader,
        payload,
      })),
    );
  }

  public async postModelPredict(entityId: number, payload: TypeOfModelItem) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.postModelPredict({
        authorization: authorizationHeader,
        entityId,
        payload,
      })),
    );
  }

  public async postUrl(modelId: number, payload: ListOfUrls) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.postModelUrlModelId({
        authorization: authorizationHeader,
        modelId,
        payload,
      })),
    );
  }

  public async postModelDownloadPrediction(payload: DownloadCsvFile[]) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.postModelDownloadPrediction({
        authorization: authorizationHeader,
        payload,
      })),
    );
  }

  public async postModelHeatmap(entityId: number, payload: ItemType) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.postModelHeatmap({
        authorization: authorizationHeader,
        entityId,
        payload,
      })),
    );
  }

  public async getModelAdhocTextAdhocTextId(adhocTextId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.getModelAdhocTextAdhocTextId({
        authorization: authorizationHeader,
        adhocTextId,
      })),
    );
  }

  public async getModelUrlInfo(entityId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.getModelUrlInfo({
        authorization: authorizationHeader,
        entityId,
      })),
    );
  }

  public async deleteModelAdhocTextAdhocTextId(adhocTextId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.deleteModelAdhocTextAdhocTextId({
        authorization: authorizationHeader,
        adhocTextId,
      })),
    );
  }

  public async deleteModelTextItemEntityId(entityId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.deleteModelTextItemEntityId({
        authorization: authorizationHeader,
        entityId,
      })),
    );
  }

  public async deleteModelFolderItemEntityId(entityId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.deleteModelFolderItemEntityId({
        authorization: authorizationHeader,
        entityId,
      })),
    );
  }

  public async deleteModelUrlOperations(payload: EntityId) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.deleteModelUrlOperations({
        authorization: authorizationHeader,
        payload,
      })),
    );
  }

  public async putModelAdhocTextAdhocTextId(adhocTextId: number, payload: AdhocText) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.putModelAdhocTextAdhocTextId({
        authorization: authorizationHeader,
        adhocTextId,
        payload,
      })),
    );
  }

  public async putModelUrlOperations(payload: UrlName) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.putModelUrlOperations({
        authorization: authorizationHeader,
        payload,
      })),
    );
  }

  public async putModelFolderItemEntityId(entityId: number, payload: EntityTextName) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.putModelFolderItemEntityId({
        authorization: authorizationHeader,
        entityId,
        payload,
      })),
    );
  }

  public async putModelTextItemEntityId(entityId: number, payload: EntityTextName) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.modelApi.putModelTextItemEntityId({
        authorization: authorizationHeader,
        entityId,
        payload,
      })),
    );
  }
}
