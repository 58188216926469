import React, { Component, SyntheticEvent } from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Container, Form, Row } from "react-bootstrap";
import LandingHeader from "../Landing/Header";
import {
  ForgotPassErrors as ForgotPassModel,
  isValidForm,
  UserClient,
} from "../../../utility";
import FormElement, { FormElementProps } from "../../common/form/FormElement";
import { RouteComponentProps } from "react-router";
import Feedback from "../../common/form/Feedback";
import { ButtonRef } from "../../common/buttons";
import { ROUTE } from "../../../constants";
import { LandingFormType } from "../Landing/constants";

interface ForgotPassErrors extends ForgotPassModel {
  email?: string;
  form?: string;
}

interface ForgotPassState {
  data: ForgotPassModel;
  validated: boolean;
  errors: ForgotPassErrors;
}

export default class ForgotPass extends Component<RouteComponentProps, ForgotPassState> {

  public readonly state: ForgotPassState = {
    data: {
      email: "",
    },
    validated: false,
    errors: {},
  };

  public handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = {
      ...this.state.errors,
      form: "",
    };
    const userClient = new UserClient();
    this.setState({
      validated: true,
      errors,
    });

    if (isValidForm(event.currentTarget, errors)) {
      userClient.postSendResetEmail(this.state.data as Required<ForgotPassModel>).then(async (response) => {
        if (response.errors) {
          this.setState({
            errors: {
              ...this.state.errors,
              form: response.errors,
            },
          });
        } else {
          this.props.history.push(ROUTE.ROOT);
        }
      });
    }
  }

  public handleChange = (event: SyntheticEvent<FormElementProps<keyof ForgotPassState>>) => {
    const inputName = event.currentTarget.name;
    const inputValue = event.currentTarget.value;
    this.setState({
      data: {
        ...this.state.data,
        [inputName]: inputValue,
      },
      errors: {
        ...this.state.errors,
        [inputName]: !inputValue ? "The field is required" : "",
      },
    });
  }

  public render() {
    const {
      data,
      errors,
      validated,
    } = this.state;

    return (
      <>
        <LandingHeader />
        <Container>
          <Row className="justify-content-center forgot_pass_title">
              <p><strong>Please enter an email to receive a Reset password link on your email</strong></p>
          </Row>
          <Row className="justify-content-center">
            <Col md={6}>
              <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                  <FormElement
                    groupAs={Col}
                    name="email"
                    label="Email"
                    errors={errors.email}
                    value={data.email}
                    handleChange={this.handleChange}
                    type="email"
                    required={true}
                    isValid={validated ? !errors.email : undefined}
                  />
                </Form.Row>
                <Form.Row className="forgot_password_padding">
                  <Col>
                    <Feedback valid={false} errors={errors.form} forceShow={true} />
                  </Col>
                </Form.Row>
                <ButtonToolbar className="justify-content-center">
                  <ButtonGroup>
                    <ButtonRef to={ROUTE.LOGIN} variant="secondary">Cancel</ButtonRef>
                    <Button variant="primary" type="submit">
                      {LandingFormType.FORGOT_PASS}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
