// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleRecommendationData
 */
export interface SingleRecommendationData {
    /**
     * Category name.
     * @type {string}
     * @memberof SingleRecommendationData
     */
    category: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SingleRecommendationData
     */
    link?: Array<string>;
    /**
     * Recommendation text.
     * @type {string}
     * @memberof SingleRecommendationData
     */
    recommendation: string;
}

export function SingleRecommendationDataFromJSON(json: any): SingleRecommendationData {
    return SingleRecommendationDataFromJSONTyped(json, false);
}

export function SingleRecommendationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleRecommendationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': json['category'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'recommendation': json['recommendation'],
    };
}

export function SingleRecommendationDataToJSON(value?: SingleRecommendationData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'link': value.link,
        'recommendation': value.recommendation,
    };
}


