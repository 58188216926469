// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FolderData,
    FolderDataFromJSON,
    FolderDataFromJSONTyped,
    FolderDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface FolderAllOutputSuccess
 */
export interface FolderAllOutputSuccess {
    /**
     * 
     * @type {Array<FolderData>}
     * @memberof FolderAllOutputSuccess
     */
    data: Array<FolderData>;
    /**
     * Error message.
     * @type {string}
     * @memberof FolderAllOutputSuccess
     */
    errors: string;
}

export function FolderAllOutputSuccessFromJSON(json: any): FolderAllOutputSuccess {
    return FolderAllOutputSuccessFromJSONTyped(json, false);
}

export function FolderAllOutputSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): FolderAllOutputSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': (json['data'] as Array<any>).map(FolderDataFromJSON),
        'errors': json['errors'],
    };
}

export function FolderAllOutputSuccessToJSON(value?: FolderAllOutputSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': (value.data as Array<any>).map(FolderDataToJSON),
        'errors': value.errors,
    };
}


