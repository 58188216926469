import { Navigation } from "../../common";
import { Link, RouteComponentProps } from "react-router-dom";
import React, { Component } from "react";
import { linkName, isNotEmptyObject, CorporaClient, isSuccessfulResponse, convertDateToString } from "../../../utility";
import { Container } from "react-bootstrap";
import { CorporaRouterProps, CorporaState } from "./index";

class CorpusDetails extends Component<RouteComponentProps<CorporaRouterProps>> {
  public state: CorporaState = {
      corpus: {},
  };

  public componentDidMount(): void {
    const folderId = parseInt(this.props.match.params.corpus, 10);
    const corporaClient = new CorporaClient();
    corporaClient.getListOfDocuments(folderId).then((response) => {
      if (isSuccessfulResponse(response)) {
        const corpus = response.data;
        this.setState({
          corpus,
        });
      }
    });
  }

  public render() {
    const corpus = this.state.corpus;
    return (
      <Container>
        {isNotEmptyObject(corpus) &&
        <>
          <Navigation
            {...this.props}
            elementName={(url, index) => index === 1 ? corpus.folderName : linkName(url[index])}
          />
          <h1>{corpus.folderName}</h1>
            <p>{`Number of documents: `}
              <Link to={`/corpora/${corpus.folderId}/documents`}>
                {corpus.numberOfTexts}
              </Link>
            </p>
            <p>Created on: {convertDateToString(corpus.created)}</p>
        </>}
      </Container>
    );
  }
}

export default CorpusDetails;
