// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RecommendationOutputError,
    RecommendationOutputErrorFromJSON,
    RecommendationOutputErrorToJSON,
    RecommendationOutputSuccess,
    RecommendationOutputSuccessFromJSON,
    RecommendationOutputSuccessToJSON,
    SendEmail,
    SendEmailFromJSON,
    SendEmailToJSON,
    TokenExpiredOutput,
    TokenExpiredOutputFromJSON,
    TokenExpiredOutputToJSON,
} from '../models';

export interface GetModelRecommendationRequest {
    authorization: string;
}

export interface PostModelRecommendationRequest {
    authorization: string;
    file: Blob;
}

export interface PostSendEmailRequest {
    authorization: string;
    file: Blob;
    email: string;
    recommendation: string;
}

/**
 * no description
 */
export class RecommendationApi extends runtime.BaseAPI {

    /**
     * Returns recommendation info.
     * Get recommendation (admin)
     */
    async getModelRecommendationRaw(requestParameters: GetModelRecommendationRequest): Promise<runtime.ApiResponse<RecommendationOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getModelRecommendation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/recommendations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecommendationOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns recommendation info.
    * Get recommendation (admin)
    */
    async getModelRecommendation(requestParameters: GetModelRecommendationRequest): Promise<RecommendationOutputSuccess> {
        const response = await this.getModelRecommendationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns recommendation info if creation is successful.
     * Create recommendation (admin)
     */
    async postModelRecommendationRaw(requestParameters: PostModelRecommendationRequest): Promise<runtime.ApiResponse<RecommendationOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postModelRecommendation.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling postModelRecommendation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/recommendations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecommendationOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns recommendation info if creation is successful.
    * Create recommendation (admin)
    */
    async postModelRecommendation(requestParameters: PostModelRecommendationRequest): Promise<RecommendationOutputSuccess> {
        const response = await this.postModelRecommendationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send ppt file and recommendations to email.
     * Send analysis results to user
     */
    async postSendEmailRaw(requestParameters: PostSendEmailRequest): Promise<runtime.ApiResponse<SendEmail>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling postSendEmail.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling postSendEmail.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling postSendEmail.');
        }

        if (requestParameters.recommendation === null || requestParameters.recommendation === undefined) {
            throw new runtime.RequiredError('recommendation','Required parameter requestParameters.recommendation was null or undefined when calling postSendEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('File', requestParameters.file as any);
        }

        if (requestParameters.email !== undefined) {
            formData.append('Email', requestParameters.email as any);
        }

        if (requestParameters.recommendation !== undefined) {
            formData.append('Recommendation', requestParameters.recommendation as any);
        }

        const response = await this.request({
            path: `/recommendations/email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SendEmailFromJSON(jsonValue));
    }

   /**
    * Send ppt file and recommendations to email.
    * Send analysis results to user
    */
    async postSendEmail(requestParameters: PostSendEmailRequest): Promise<SendEmail> {
        const response = await this.postSendEmailRaw(requestParameters);
        return await response.value();
    }

}
