import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Navigation, Chart, Legend, Trait, OpenModalFunction } from "../../common";
import { ModelClient, linkName, isNotEmptyObject, isSuccessfulResponse, RecommendationClient } from "../../../utility";
import { Container } from "react-bootstrap";
import { ModalType } from "../../common/Modal/constants";
import { EntityType, NEW_LINE, UNSET_INT } from "../../../constants";
import { ModelRouterProps } from "./index";
import { ChangeChartData, NewChartData } from "../Home";
import { ILegend } from "../../common/chart/Legend";
import { AddItemOptions, AddItemTypes, ExportGraph, SentimentOptions, UnitOptions } from "../../../types";
import DomToImage from "dom-to-image";
import { saveAs } from "file-saver";
import RecommendationBlock, { RecommendationProps } from "./RecommendationBlock";
import { LegendOptions } from "../../common/chart/LegendOptions";
import { FaFile, FaPaperclip, FaBook, FaFont } from "react-icons/all";
import { AdhocTextData, CorporaTextEntity, FolderDataEntity, UrlData, UrlEntity } from "../../../client/src/models";

interface ModelDetailProps {
  openModalWindow: OpenModalFunction;
  chartData: Map<number, Trait[]>;
  changeChartData: ChangeChartData;
  legend: ILegend[];
  legendShow: (show: boolean) => void;
  legendItemShow: (entityId: number) => void;
  legendItemDelete: (entityId: number) => void;
  changeLegend: () => void;
  chartSettings: {
    unit: UnitOptions;
    sentiment: SentimentOptions;
  };
  handleClick: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  chartRecommendations: RecommendationProps;
  tickFormatter: (n: number | { valueOf(): number }) => string;
  loading: boolean;
}

interface Model {
  created: string;
  modelId: number;
  modelName: string;
  trainCorpusName: string;
}

interface ModelDetailState {
  joinOption: boolean;
  highLightId: number;
  model: Model[];
}

class ModelDetail extends Component<RouteComponentProps<ModelRouterProps> & ModelDetailProps, ModelDetailState> {
  public state = {
    model: [],
    joinOption: false,
    highLightId: UNSET_INT,
  };

  public componentDidMount(): void {
    const modelClient = new ModelClient();
    const modelId = parseInt(this.props.match.params.model, 10);
    modelClient.getTable().then((response: any) => {
      if (isSuccessfulResponse(response)) {
        const model = isNotEmptyObject(response.data)
          && response.data.filter((item: Model) => item.modelId === modelId);
        this.setState({
          model,
        });
      }
    });
    const entityPromises: Array<Promise<NewChartData>> = [];
    modelClient.getModelById(modelId).then((response) => {
      if (isSuccessfulResponse(response)) {
        if (response.data) {
          if (response.data.folderEntities) {
            const folderEntities = Object.values(response.data.folderEntities);
            for (let i = 0; i < folderEntities.length; ++i) {
              const item = folderEntities[i] as FolderDataEntity;
              const payload = {
                itemType: AddItemTypes.CORPUS_BASELINE,
                unit: this.props.chartSettings.unit,
                sentiment: this.props.chartSettings.sentiment,
              };
              entityPromises.push(modelClient.postModelPredict(item.entityId, payload).then((p) => ({
                data: p.data,
                entityId: item.entityId,
                entityName: item.entityName,
                legendType: AddItemTypes.CORPUS_BASELINE,
                icon: <FaBook />,
                content: { value: item.folderId, label: <><FaBook />{` ${item.folderName}`}</> },
              })));
            }
          }
          if (response.data.textEntities) {
            const textEntities = Object.values(response.data.textEntities);
            for (let i = 0; i < textEntities.length; ++i) {
              const item = textEntities[i] as CorporaTextEntity;
              const payload = {
                itemType: AddItemTypes.DOCUMENTS_FROM_CORPORA,
                unit: this.props.chartSettings.unit,
                sentiment: this.props.chartSettings.sentiment,
              };
              entityPromises.push(modelClient.postModelPredict(item.entityId, payload).then((p) => ({
                data: p.data,
                entityId: item.entityId,
                entityName: item.entityName,
                legendType: AddItemTypes.DOCUMENTS_FROM_CORPORA,
                icon: <FaFile />,
                content: item.texts.map((text: any) => ({ value: text.textId, label: text.textName })),
              })));
            }
          }
          if (response.data.urls) {
            const urls = Object.values(response.data.urls);
            for (let i = 0; i < urls.length; ++i) {
              const item = urls[i] as UrlEntity;
              const payload = {
                itemType: AddItemTypes.FROM_URL,
                unit: this.props.chartSettings.unit,
                sentiment: this.props.chartSettings.sentiment,
              };
              entityPromises.push(modelClient.postModelPredict(item.entityId, payload).then((p) => ({
                data: p.data,
                entityId: item.entityId,
                entityName: item.entityName,
                legendType: AddItemTypes.FROM_URL,
                icon: <FaPaperclip />,
                content: item.urls.map((url: UrlData) => url.textName),
              })));
            }
          }
          if (response.data.adhocTexts) {
            const adhocTexts = Object.values(response.data.adhocTexts);
            for (let i = 0; i < adhocTexts.length; ++i) {
              const item = adhocTexts[i] as AdhocTextData;
              const payload = {
                itemType: AddItemTypes.ADHOC_DOCUMENT,
                unit: this.props.chartSettings.unit,
                sentiment: this.props.chartSettings.sentiment,
              };
              entityPromises.push(
                modelClient.getModelAdhocTextAdhocTextId(item.adhocTextId)
                  .then((c) => modelClient.postModelPredict(item.adhocTextId, payload)
                        .then((p) => ({
                          data: p.data,
                          entityId: item.adhocTextId,
                          entityName: item.textName,
                          legendType: AddItemTypes.ADHOC_DOCUMENT,
                          icon: <FaFont />,
                      content: c.data.content,
                    })),
                  ),
              );
            }
          }
          Promise.all(entityPromises).then((newChartData) => this.props.changeChartData(newChartData));
        }
      }
    });
  }

  public handleSelect = (eventKey: string): void => {
    const chartSettings = this.props.chartSettings;
    const modelId = parseInt(this.props.match.params.model, 10);
    switch (eventKey) {
      case AddItemOptions.DOCUMENTS_FROM_CORPORA:
        this.props.openModalWindow(ModalType.LEGEND_DOCUMENT_FROM_CORPORA, EntityType.CORPORA, modelId, chartSettings);
        break;
      case AddItemOptions.ADHOC_DOCUMENT:
        this.props.openModalWindow(ModalType.LEGEND_ADHOC_DOCUMENT, EntityType.UNDEFINED, modelId, chartSettings);
        break;
      case AddItemOptions.CORPUS_BASELINE:
        this.props.openModalWindow(ModalType.LEGEND_CORPUS_BASELINE, EntityType.CORPORA, modelId, chartSettings);
        break;
      case AddItemOptions.FROM_URL:
        this.props.openModalWindow(ModalType.LEGEND_FROM_URL, EntityType.UNDEFINED, modelId, chartSettings);
        break;
      case ExportGraph.PNG_TYPE:
      case ExportGraph.PPT_TYPE:
        const chart = document.getElementById("chart-container");
        DomToImage.toBlob(chart as Node).then((image) => {
          saveAs(image, `multigraph.${eventKey}`);
        });
        break;
    }
  }

  public chartHighlight = (highLightId: number): void => {
    this.setState({
      highLightId,
    });
  }

  public unHighlightChart = (): void => {
    this.setState({
      highLightId: UNSET_INT,
    });
  }

  public legendItemEdit = (item: any): void => {
    const modelId = parseInt(this.props.match.params.model, 10);
    const chartSettings = {unit: this.props.chartSettings.unit, sentiment: this.props.chartSettings.sentiment, item};
    switch (item.legendType) {
      case AddItemTypes.DOCUMENTS_FROM_CORPORA:
        this.props.openModalWindow(ModalType.EDIT_DOCUMENTS_FROM_CORPORA, EntityType.CORPORA, modelId, chartSettings);
        break;
      case AddItemTypes.ADHOC_DOCUMENT:
        this.props.openModalWindow(ModalType.EDIT_ADHOC_DOCUMENT, EntityType.UNDEFINED, modelId, chartSettings);
        break;
      case AddItemTypes.CORPUS_BASELINE:
        this.props.openModalWindow(ModalType.EDIT_CORPUS_BASELINE, EntityType.CORPORA, modelId, chartSettings);
        break;
      case AddItemTypes.FROM_URL:
        this.props.openModalWindow(ModalType.EDIT_FROM_URL, EntityType.UNDEFINED, modelId, chartSettings);
        break;
    }
  }

  public sendGraph = () => {
    const modelId = parseInt(this.props.match.params.model, 10);
    const chartRecommendations = this.props.chartRecommendations;
    const chart = document.getElementById("chart-container");
    const recommendation = Object.keys(chartRecommendations)
      .map((key) => {
        return `${key}: ${chartRecommendations[key].recommendation}`;
      }).join(NEW_LINE);

    DomToImage.toBlob(chart as Node).then((image) => {
      const file = new File([image], "multigraph.ppt");
      this.props.openModalWindow(ModalType.SEND_GRAPH, EntityType.UNDEFINED, modelId, {file, recommendation});
    });
  }

  public render() {
    const model: Model = this.state.model[0];

    return(
      <Container>
        {model && Object.keys(model).length > 0 &&
        <>
          <Navigation
            {...this.props}
            elementName={(url, index) => index === 1 ? model.modelName : linkName(url[index])}
          />
          <h1>{model.modelName}</h1>
          <Chart
            chartData={this.props.chartData}
            legend={this.props.legend}
            unit={this.props.chartSettings.unit}
            chartHighlight={this.chartHighlight}
            highLightId={this.state.highLightId}
            unHighlightChart={this.unHighlightChart}
            tickFormatter={this.props.tickFormatter}
            loading={this.props.loading}
          />
          <Legend
            handleSelect={this.handleSelect}
            legend={this.props.legend}
            chartHighlight={this.chartHighlight}
            highLightId={this.state.highLightId}
            unHighlightChart={this.unHighlightChart}
            legendItemShow={this.props.legendItemShow}
            legendItemDelete={this.props.legendItemDelete}
            legendItemEdit={this.legendItemEdit}
          />
          <LegendOptions
            chartSettings={this.props.chartSettings}
            handleClick={this.props.handleClick}
            legendShow={this.props.legendShow}
            refreshLegend={this.props.changeLegend}
            legend={this.props.legend}
            handleSelect={this.handleSelect}
            sendGraph={this.sendGraph}
          />
          <RecommendationBlock
            recommendations={this.props.chartRecommendations}
          />
        </>
        }
      </Container>
    );
  }
}

export default withRouter(ModelDetail);
