import { localStorageService } from "../storage";
import { UserClient } from "../requests";

enum UserRole {
  ADMIN = "admin",
  USER = "user",
}

export enum LOCAL_STORAGE_KEYS {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  ROLE = "role",
}

interface LoginUserData {
  accessToken: string;
  refreshToken: string;
}

export const authenticationService = {
  getLoginData() {
    const loginUserData: Partial<LoginUserData> = {
      accessToken: localStorageService.readItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || undefined,
      refreshToken: localStorageService.readItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || undefined,
    };
    return loginUserData;
  },
  saveAccessToken(accessToken: string) {
    localStorageService.createOrUpdateItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
  },
  checkLogin() {
    return localStorageService.readItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) !== null;
  },
  checkAdmin() {
    const accessToken = localStorageService.readItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN),
      refreshToken = localStorageService.readItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    if (accessToken && refreshToken) {
      this.loginUser({accessToken, refreshToken});
      return this.checkLogin() && localStorageService.readItem(LOCAL_STORAGE_KEYS.ROLE) === UserRole.ADMIN;
    }
    return false;
  },
  async loginUser(data: LoginUserData) {
    const userClient = new UserClient();
    return userClient.info(data.accessToken)
      .then((info: any) => {
        if (info.data) {
          localStorageService.createOrUpdateItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, data.accessToken);
          localStorageService.createOrUpdateItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken);
          Object.keys(info.data).forEach((key) => {
            localStorageService.createOrUpdateItem(key, info.data[key]);
          });
        }
      });
  },
  logoutUser() {
    localStorageService.clear();
    window.location.reload();
  },
};
