import React from "react";
import { Container } from "react-bootstrap";
import { LandingLogo } from "./Landing/Header";

const TermsOfService = () => {
  return (
    <>
      <LandingLogo />
      <Container>
        <div style={{width: 640}} className="wp-video">
          <video className="usragvid" width="640" height="480" autoPlay={true} preload="metadata" controls={true}>
            <source
              type="video/mp4"
              src="https://www.emoto.ai/wp-content/uploads/2018/08/User-Agreement-sucks.mp4?_=1"
            />
            <a href="https://www.emoto.ai/wp-content/uploads/2018/08/User-Agreement-sucks.mp4">
              https://www.emoto.ai/wp-content/uploads/2018/08/User-Agreement-sucks.mp4
            </a>
          </video>
        </div>
        <p>&nbsp;</p>
        <p>
          <strong>
            We admit it.
            Our user agreement sucks too.
            But until the dust settles on all the new privacy rules emerging,
            we are obliged to publish these standard terms of use:
          </strong>
        </p>
        <h2>TERMS OF USE</h2>
        <p>This Agreement sets forth the terms governing your use of the DIGITAL CHARACTER ANALYSIS demo application
          (&#8220;the Application&#8221;).
          By using the Application, you agree to be legally bound by these Terms of Use;
          if you do not agree to all of these Terms of Use, do not use this Application.
        </p>
        <p>
          The Application is made available to you, as an individual person,
          non-exclusively by Real Engagement And Loyalty (“REAL”) at
          no charge via an Internet connection provided by you.
        </p>
        <p>
          The Application and any related software, documentation,
          content or other materials provided by REAL in connection with the Application are owned by REAL
          or a third party supplier,
          and are copyrighted and licensed, not sold. Transfer of your rights under this Agreement is not permitted.
        </p>
        <p>
          You are authorized to use the Application and associated content for your own personal use for informational
          purposes only. The Application is not intended to be used for productive or commercial purposes, and such use
          is not permitted. The Application relies on content,
          which may include someone’s social media profile information,
          is neither owned nor controlled by REAL or its suppliers,
          and REAL and its suppliers do not license or otherwise provide any rights in the content.
          Content may include materials that are illegal, inaccurate, misleading, indecent, or otherwise objectionable.
          REAL or its suppliers have no obligation to review, filter, verify, edit or remove any content.
          However, REAL or its suppliers may, at their sole discretion, do so.
        </p>
        <p>
          The Application may be unavailable during maintenance scheduled determined by REAL,
          and other scheduled or non-scheduled downtimes may occur.
          Either party may terminate use or access to the Application at any time.
        </p>
        <p>
          You are responsible for your use of the Application and associated content and the results
          obtained from their use. You are also responsible for any information,
          data or other materials (“User Materials”) that you submit or provide in connection with the Application,
          including ensuring that you have all necessary authorizations to permit REAL and its subcontractors to use,
          host, cache, record, copy and display such User Materials without charge.
          REAL has no responsibility for the User Materials that you submit
          or provide in connection with the Application.
          All User Materials which you submit or provide to the Application or to REAL are non-confidential.
        </p>
        <p>
          The Application suggests output based on applying an algorithm to a limited writing sample, and, as such,
          the completeness, accuracy and reliability of the output will vary.
          Use of this Application may implicate various laws or regulations, including those related to privacy,
          data protection, and employment. The Application may be used only for lawful purposes and in a lawful manner.
          You agree to use the Application pursuant to, and you assume all responsibility for complying with,
          applicable laws, regulations and policies, and will obtain or have obtained any consents, permissions,
          or licenses that may be needed.
        </p>
        <p>
          The Application and associated content are provided “AS IS”, with no warranties, express or implied,
          including without limitation, warranties of merchantability, fitness for a particular purpose,
          or non-infringement. REAL and its affiliates and suppliers will not be liable, under any contract, tort,
          strict liability, or other theory, for any direct, special, punitive, indirect, incidental, or consequential
          damages, including, but not limited to, loss of or damage to data, loss of anticipated revenue, profits,
          savings, or goodwill, work stoppage or impairment of other assets,
          whether or not foreseeable and whether or not you have been advised of the possibility of such damages.
          You are responsible for compliance with any applicable laws and regulations which may govern your accessing
          and use of the Application.
        </p>
        <p>
          The laws of the State of New York, excluding its conflicts of laws rules,
          govern this Agreement and your use of the Application.
        </p>
        <p>
          REAL wants to know what you think about the Application and how REAL can make them better.
          You can do this by providing your comments and feedback via online forums or other submission vehicles
          which REAL makes available to you. By providing your comments and feedback to REAL,
          you authorize REAL to publish and display them and to use them in the development and enhancement of its
          products and Application.
        </p>
        <p>
          The foregoing is the complete agreement between you and REAL regarding your use of the Application and
          replaces any prior communications related to such use.
        </p>
        <h3><b>USE OF WEBSITE:</b></h3>
        <p>While using the Website and Services, You Are Responsible for Your Actions and for All Content You Post.</p>
        <p>By accessing and using the Website and Services, You Represent and Warrant:</p>
        <ul>
          <li>
            That any and all information you post or provide, including, without limitation,
            as part of any registration or application or to gain access to the Website or Services, is true,
            accurate and not misleading and that you will not allow any other person or entity to use your Account.
          </li>
          <li>
            That you may be liable for any illegal or prohibited content that you provide to the Website
            or to other Users, including, without limitation, content that is unlawful, harmful, threatening, tortious,
            defamatory, infringing, libellous, abusive, disparaging, paedophilic, pornographic, obscene,
            invasive of another&#8217;s privacy, hateful, malicious or otherwise promotes illegal activities or conduct
            or violates any local, state, national or other applicable law or regulation;
          </li>
          <li>
            That you will not post or otherwise provide User Content that contains hate propaganda or promotes
            discrimination or violence against any people on account of their race, national origin, religion, age,
            gender, disability, or sexual orientation; or that is or could be harmful to minors;
          </li>
          <li>
            That you will not duplicate, download, publish, modify or otherwise distribute any Content on this Website
            for any purpose other than for your own individual use.
          </li>
          <li>
            That you will not post or otherwise provide User Content that contains a software virus or any other code
            files or programs with the ability to interrupt, destroy, compromise,
            or otherwise limit the functionality of any computer software or hardware or telecommunications equipment.
          </li>
          <li>
            That you will not avoid, bypass, reverse engineer, interfere with, deactivate, impair, descramble or
            otherwise circumvent any technical measure implemented by Company to administer
            and protect the Website and Services.
          </li>
          <li>
            That you will not collect or &#8220;harvest&#8221;
            from the Website the personally identifiable information of other users without their consent
            for the purpose of transmitting unsolicited commercial mass mailings or &#8220;spamming.”
          </li>
          <li>
            That you will not forge headers or otherwise manipulate identifiers for the purpose of disguising the origin
            of any content posted or transmitted through or on the Website;
          </li>
          <li>
            That you will not access the Website and Services by any means other than through interfaces expressly
            authorized by Company and these Terms of Use.
          </li>
          <li>
            That you will not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary
            information of the Website or our Vendors without our express written consent.
          </li>
          <li>
            That you will not scrape or download Content from the Website or Services,
            or otherwise without authorization use, upload Content to, or create new links, reposts, or referrals
            through the use of any engine, software, tool, agent, device or mechanism (including automated scripts,
            spiders, robots, crawlers and data mining tools).
          </li>
        </ul>
        <p><b>INTELLECTUAL PROPERTY RIGHTS:</b></p>
        <p>
          The Website includes a combination of content that we create, that our partners or advertisers create,
          and that our Users create. This section sets out the ownership and usage rights for each type of content.
        </p>
        <p>
          COMPANY CONTENT: The Website, including without limitation Domain Names, Company Content,
          Services and all intellectual property rights therein (<b>“Company Content”</b>), is the property of Company,
          its Affiliates and/or its authorized licensors and is protected by U.S. and international copyright,
          trademarks and other intellectual property laws.
          Except to the extent otherwise expressly permitted under copyright law, no copying or exploitation of any
          content on the Website is permitted without the express written permission of Company or the applicable
          copyright owner. We hereby grant you a worldwide, revocable, royalty-free, non-exclusive license to use,
          view and download our Company Content for your personal, non-commercial use in accordance with these Terms
          of Use and applicable U.S. and international copyright laws. You acknowledge that you will not acquire any
          ownership rights by virtue of downloading our Company Content from the Website. Except to the minimum extent
          otherwise expressly permitted under copyright law, you will not copy, reproduce, modify, use, distribute,
          display, create derivative works of or otherwise exploit our Company Content or the Services
          (regardless of form or media and whether by download or otherwise) without the express written permission of
          Company or the applicable copyright owner.
        </p>
        <p>
          YOUR CONTENT: You are solely responsible for all content, whether publicly posted or privately transmitted,
          that you upload, post, e-mail, transmit, or otherwise make available on the Website, including without
          limitation user reviews (<b>&#8220;Your Content&#8221;</b>). You certify that you own all intellectual
          property rights in Your Content. You hereby grant us, our parent company and affiliates and our partners a
          worldwide, irrevocable, royalty-free, nonexclusive, sub-licensable license to use, reproduce, create
          derivative works of, publicly communicate and distribute Your Content and subsequent versions of Your
          Content for use in the ordinary course of our business, including without limitation, displaying Your
          Content on the Website and making Your Content available, either electronically or via other media, to Users.
        </p>
        <p>
          All intellectual property rights not expressly granted hereunder are expressly reserved to Company and to the
          respective owners of such rights.
        </p>
        <p><b>COPYRIGHT INFRINGEMENT CLAIMS:</b></p>
        <p>Company respects the intellectual property rights of others and will not tolerate infringing activity on the
          Website. If you are a copyright owner or agent, and you believe your rights under applicable Copyright laws
          are being infringed by Company or another person or entity using the Website or Services, then you may submit
          a notification pursuant to the Digital Millennium Copyright Act (&#8220;DMCA&#8221;) (17 U.S.C. § 512) by
          providing Company’s Designated Copyright Agent at real@real.org with the following information in writing:
        </p>
        <ul>
          <li>
            A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that
            has/have been allegedly infringed;
          </li>
          <li>
            Identification of works or materials being infringed, or, if multiple works are covered by a single
            notification, a representative list of such works;
          </li>
          <li>
            Identification of specific material to be removed, which is claimed to infringe or to be subject of
            infringing activity, and its location;
          </li>
          <li>
            Your contact information, including phone number, and, if available, an e-mail address at which you may be
            contacted;
          </li>
          <li>
            Your good faith statement that the use of the material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law; and
          </li>
          <li>
            A statement that the information in your notification is accurate, and under penalty of perjury, that you
            are authorized to act on behalf of the owner of the allegedly infringed copyright.
          </li>
        </ul>
        <p>
          Company reserves the right to seek damages from any person who knowingly materially submits a notification
          claim under this section in violation of the law.
        </p>
        <p><b>PRIVACY AND SECURITY:</b></p>
        <p>
          Company takes your Privacy seriously and endeavors to comply with applicable data privacy laws in all regions
          where we do business. Please review our Privacy Policy, which governs your access to and use of the Website
          and the Services and sets forth the information we may collect from you and how we will safeguard and use it
          to administer your Services and Website experience.
        </p>
        <p>
          By communicating with us via e-mail or by submitting any request on the Website, you consent to receive e-mail
          or other electronic communications from Company, our parent company and our Affiliates.
        </p>
        <p>
          You shall not: (a) access data or materials not intended for you; (b) log into a server or account which you
          are not authorized to access; (c) attempt to probe, scan or test the vulnerability of a system or network or
          to breach security or authentication measures without proper authorization; or (d) attempt to interfere with
          service to any user, host or network.
        </p>
        <p>
          By accessing and using the Website, you understand and accept the risk that data and communications,
          including e-mail and other electronic communications, may be accessed by unauthorized third parties when
          communicated over the Internet.
        </p>
        <p><b>DISCLAIMER:</b></p>
        <p>
          YOUR USE OF THE WEBSITE AND SERVICES IS AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
          THE WEBSITE AND SERVICES ARE PROVIDED ON AN &#8220;AS IS&#8221; AND &#8220;AS AVAILABLE&#8221; BASIS, WITHOUT
          WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION: ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF THIRD PARTY INTELLECTUAL PROPERTY
          RIGHTS, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, ACCURACY OF INFORMATIONAL CONTENT OR SYSTEM INTEGRATION. WE DO
          NOT WARRANT THAT THE SITE WILL BE AVAILABLE OR OPERATE IN AN UNINTERRUPTED OR ERROR-FREE MANNER OR THAT ERRORS
          OR DEFECTS WILL BE CORRECTED OR THAT THE CONTENT ON THE WEBSITE IS ACCURATE OR APPROPRIATE FOR YOU.
        </p>
        <p>
          <b>Note: </b>
          As some jurisdictions do not allow exclusion of implied warranties, the above exclusions may not apply to you.
        </p>
        <p>
          You understand that certain Services on the Website may include materials and information from third parties
          and links to other web sites and resources over which Company has minimal control. Your use of those third
          party websites is subject to their terms and conditions and you agree that Company is not responsible or
          liable for any loss or damage incurred as the result of your transactions with such third parties.
        </p>
        <p><b>LIMITATION OF LIABILITY:</b></p>
        <p>
          NEITHER COMPANY, ITS PARENT COMPANY OR AFFILIATES SHALL BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          SPECIAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS OF
          USE OR YOUR USE OF THE WEBSITE AND SERVICES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS,
          GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES). TO THE EXTENT THE FOREGOING LIMITATION OF LIABILITY IS, IN WHOLE OR IN PART, HELD TO BE INAPPLICABLE
          OR UNENFORCEABLE FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF COMPANY, ITS PARENT COMPANY AND AFFILIATES
          FOR ANY REASON AND UPON ANY CAUSE OF ACTION (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, STRICT LIABILITY AND
          OTHER ACTIONS IN CONTRACT OR TORT) ARISING OUT OF OR IN ANY WAY RELATED TO THE WEBSITE, SERVICES OR THESE
          TERMS OF USE SHALL BE LIMITED TO DIRECT DAMAGES ACTUALLY INCURRED UP TO ONE HUNDRED U.S. DOLLARS ($100).
        </p>
        <p>
          BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
          OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </p>
        <p>
          COMPANY EXPRESSLY DISCLAIMS LIABILITY FOR ANY AND ALL DISPUTES ARISING BETWEEN USERS OF THE WEBSITE OR
          SERVICES. BY USING THE WEBSITE AND SERVICES, YOU RELEASE COMPANY, ITS PARENT COMPANY AND AFFILIATES FROM ALL
          LIABILITY FOR CLAIMS ARISING FROM SUCH DISPUTES.
        </p>
        <p><b>INDEMNITY:</b></p>
        <p>
          You agree to indemnify and hold Company, its parent company and Affiliates harmless, and, at Company&#8217;s
          request, to defend us from and against any third party claims, demands, causes of action, debts, losses or
          liabilities, including reasonable attorneys&#8217; fees, to the extent that such claim is based upon, arises
          out of, or relates to a violation of these Terms of Use or applicable laws.
        </p>
        <p><b>TERMINATION:</b></p>
        <p>
          Termination of Your Account. You may terminate your Account at any time by sending an email request to
          real@real.org. We will aim to remove your account within 2 (two) business days. If you terminate your Account,
          you may still be able to access certain portions of the Website and Services, but you will not have access to
          the full functionality. We may terminate your Account, or impose limits on or restrict your access to parts or
          all of the Website or the Services at any time, without notice or liability.
        </p>
        <p>
          Company reserves the right, without penalty and at any time, to modify or discontinue
          (temporarily or permanently) the Services, or any part thereof, with or without notice if, in its sole
          discretion, Company believes that your conduct fails to comply with these Terms of Use. If you use or
          attempt to use the Website or Services for any purpose that contravenes these Terms of Use (including without
          limitation tampering, hacking, modifying or otherwise corrupting the security or functionality of the
          Services), you may also be subject to civil and criminal liability.
        </p>
        <p><b>GOVERNING LAW:</b></p>
        <p>
          By accessing and using the Website and Services, you consent to these Terms of Use and agree that the laws of
          the State of New York without regard to principles of conflict of laws, will govern all matters relating to or
          arising from these Terms of Use and the parties irrevocably consent to bring any action to enforce these Terms
          of Service in the federal or state courts located in the State of New York. Regardless of any statute or law
          to the contrary, any claim or cause of action arising out of or related to the Website, the Services or these
          Terms of Use must be filed or otherwise commenced within one (1) year after such claim or cause of action
          arose or shall be forever barred.
        </p>
        <p><b>WAIVER AND SEVERABILITY:</b></p>
        <p>
          Company’s failure to act with respect to a breach by you or others will not constitute a waiver and does not
          waive our right to act with respect to subsequent or similar breaches. If any of these Terms of Use shall be
          deemed invalid, void, or for any reason unenforceable under applicable law, then that term shall be deemed
          severable and shall not affect the validity and enforceability of any remaining term or provision of these
          Terms of Use.
        </p>
        <p><b>ENTIRE AGREEMENT:</b></p>
        <p>
          These Terms of Use constitute the entire agreement between you and Company with respect to the subject matter
          addressed herein, and supersede any prior agreements between you and Company relating to such subject matter.
          We reserve the right to update or otherwise modify these Term of Use from time to time. You are responsible
          for checking these Terms of Use periodically for changes and updates. Your use of this Website following such
          posted changes and updates will be deemed an acceptance of such changes and updates.
        </p>
        <p>
          BY ACCESSING AND USING THE WEBSITE AND SERVICES, YOU ATTEST THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE
          LEGALLY BOUND BY THE FOREGOING TERMS OF USE.
        </p>
      </Container>
    </>
  );
};

export default TermsOfService;
