// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterDataSuccess
 */
export interface RegisterDataSuccess {
    /**
     * User id.
     * @type {string}
     * @memberof RegisterDataSuccess
     */
    userId: string;
    /**
     * First name.
     * @type {string}
     * @memberof RegisterDataSuccess
     */
    firstName: string;
    /**
     * Last name.
     * @type {string}
     * @memberof RegisterDataSuccess
     */
    lastName: string;
    /**
     * Email.
     * @type {string}
     * @memberof RegisterDataSuccess
     */
    email: string;
    /**
     * Company name.
     * @type {string}
     * @memberof RegisterDataSuccess
     */
    company: string;
    /**
     * Position of user.
     * @type {string}
     * @memberof RegisterDataSuccess
     */
    position: string;
    /**
     * Role. User or admin role.
     * @type {string}
     * @memberof RegisterDataSuccess
     */
    role: string;
}

export function RegisterDataSuccessFromJSON(json: any): RegisterDataSuccess {
    return RegisterDataSuccessFromJSONTyped(json, false);
}

export function RegisterDataSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterDataSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'company': json['company'],
        'position': json['position'],
        'role': json['role'],
    };
}

export function RegisterDataSuccessToJSON(value?: RegisterDataSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'company': value.company,
        'position': value.position,
        'role': value.role,
    };
}


