import React, { FC } from "react";
import Body, { BodyProps } from "./Body";
import Header, { HeaderProps } from "./Header";
import { Spinner } from "react-bootstrap";

interface TableProps {
  header: HeaderProps;
  body: BodyProps;
  loading: boolean;
}

const Table: FC<TableProps> = ({
  header,
  body,
  loading,
}) => (
  <>
    {loading ?
      <div className="spinner">
        <Spinner animation="border" variant="secondary" />
      </div> :
      <>
        <Header {...header} />
        <Body {...body} />
      </>
    }
  </>
);

export default Table;
