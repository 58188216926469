import React, { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, ButtonProps } from "react-bootstrap";

interface ButtonRefProps extends RouteComponentProps, ButtonProps {
  to: string;
  replace?: boolean;
}

const ButtonRef: FC<ButtonRefProps> = ({
  history,
  location,
  match,
  staticContext,
  to,
  replace,
  ...buttonProps
}) => (
  <Button
    {...buttonProps}
    onClick={() => replace ? history.replace(to) : history.push(to)}
  />
);

export default withRouter(ButtonRef);
