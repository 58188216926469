// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LoginData,
    LoginDataFromJSON,
    LoginDataFromJSONTyped,
    LoginDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface LoginOutput
 */
export interface LoginOutput {
    /**
     * 
     * @type {LoginData}
     * @memberof LoginOutput
     */
    data: LoginData;
    /**
     * Error message.
     * @type {string}
     * @memberof LoginOutput
     */
    errors: string;
}

export function LoginOutputFromJSON(json: any): LoginOutput {
    return LoginOutputFromJSONTyped(json, false);
}

export function LoginOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': LoginDataFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function LoginOutputToJSON(value?: LoginOutput): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': LoginDataToJSON(value.data),
        'errors': value.errors,
    };
}


