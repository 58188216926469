import { Login, Register, ResetPassword, SendEmailReset, UpdateInfo, UserApi } from "../../client/src";
import BaseClient from "./BaseClient";

export type Login = Login;
export type LoginErrors = Partial<Login>;

export type Register = Register;
export type RegisterErrors = Partial<Register>;

export type ForgotPass = SendEmailReset;
export type ForgotPassErrors = Partial<ForgotPass>;

export type ResetPass = ResetPassword;
export type ResetPassErrors = Partial<ResetPass>;

const caught = { errors: { login: "Something went wrong" } };

// TODO: Authentication middleware

export class UserClient extends BaseClient {
  public id = "UserClient";
  private userClientApi = new UserApi(BaseClient.DEFAULT_CLIENT_CONFIGURATION);

  constructor() {
    super();
    return super.cache(this);
  }

  public async login(params: Login) {
    return this.userClientApi.postLogin({ payload: params })
      .then((response) => response)
      .catch((err) => {
        try {
          return err.status === 200 || err.status === 401 ? err.clone().json() : caught;
        } catch (e) {
          return caught;
        }
      });
  }

  public async register(params: Register) {
    return this.userClientApi.postRegister({ payload: params })
      .then((response) => response)
      .catch((err) => {
        try {
          return err.clone().json();
        } catch (e) {
          return e;
        }
      });
  }

  public async info(accessToken: string) {
    return this.userClientApi.getGetUserInfo({ authorization: super.getAuthorizationHeader(accessToken) })
      .then((response) => response)
      .catch((err) => {
        try {
          return err.json();
        } catch {
          return err;
        }
      });
  }

  public async updateInfo(params: UpdateInfo) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.userClientApi.postUpdateUserInfo({ authorization: authorizationHeader, payload: params })),
    );
  }

  public async postSendResetEmail(params: ForgotPass) {
    return this.userClientApi.postSendResetEmail({ payload: params })
      .then((response) => response)
      .catch((err) => {
        try {
          return err.json();
        } catch {
          return err;
        }
      });
  }

  public async postResetWithToken(params: ResetPass) {
    return this.userClientApi.postResetWithToken({ payload: params })
      .then((response) => response)
      .catch((err) => {
        try {
          return err.json();
        } catch {
          return err;
        }
      });
  }
}
