// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextEntityDataSuccess
 */
export interface TextEntityDataSuccess {
    /**
     * Entity id.
     * @type {number}
     * @memberof TextEntityDataSuccess
     */
    entityId: number;
    /**
     * Entity name.
     * @type {string}
     * @memberof TextEntityDataSuccess
     */
    entityName: string;
}

export function TextEntityDataSuccessFromJSON(json: any): TextEntityDataSuccess {
    return TextEntityDataSuccessFromJSONTyped(json, false);
}

export function TextEntityDataSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextEntityDataSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'entityName': json['entityName'],
    };
}

export function TextEntityDataSuccessToJSON(value?: TextEntityDataSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'entityName': value.entityName,
    };
}


