// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UrlInfo,
    UrlInfoFromJSON,
    UrlInfoFromJSONTyped,
    UrlInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UrlAllInfo
 */
export interface UrlAllInfo {
    /**
     * 
     * @type {Array<UrlInfo>}
     * @memberof UrlAllInfo
     */
    urls: Array<UrlInfo>;
    /**
     * Number of urls.
     * @type {number}
     * @memberof UrlAllInfo
     */
    numberOfUrls: number;
}

export function UrlAllInfoFromJSON(json: any): UrlAllInfo {
    return UrlAllInfoFromJSONTyped(json, false);
}

export function UrlAllInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrlAllInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'urls': (json['urls'] as Array<any>).map(UrlInfoFromJSON),
        'numberOfUrls': json['numberOfUrls'],
    };
}

export function UrlAllInfoToJSON(value?: UrlAllInfo): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'urls': (value.urls as Array<any>).map(UrlInfoToJSON),
        'numberOfUrls': value.numberOfUrls,
    };
}


