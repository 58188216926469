// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ItemType
 */
export interface ItemType {
    /**
     * Type of the model item. Possible item types: folder, entity text, adhoc text, url.
     * @type {string}
     * @memberof ItemType
     */
    itemType: string;
}

export function ItemTypeFromJSON(json: any): ItemType {
    return ItemTypeFromJSONTyped(json, false);
}

export function ItemTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemType': json['itemType'],
    };
}

export function ItemTypeToJSON(value?: ItemType): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemType': value.itemType,
    };
}


