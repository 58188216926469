import React, { Component } from "react";
import FormElement from "../form/FormElement";
import Feedback from "../form/Feedback";
import Select from "react-select";
import { FaBook } from "react-icons/fa";
import { CorporaItem } from "../../../types";
import { EntityModalProps } from "./index";

interface CorpusBaselineProps {
  corpora: CorporaItem[];
  corpusBaseline: null | {value: number; label: any};
  handleSelect: (value: any, action: any) => void;
  setEditLegendProps: () => void;
}

export class CorpusBaseline extends Component<EntityModalProps & CorpusBaselineProps> {
  public componentDidMount(): void {
    this.props.setEditLegendProps();
  }

  public render() {
    return (
      <>
        <FormElement
          name="name"
          label="Label"
          value={this.props.name}
          required={true}
          handleChange={this.props.handleChange}
          errors={this.props.errors.name}
        />
        <Select
          value={this.props.corpusBaseline}
          name="corpusBaseline"
          placeholder="Select"
          onChange={this.props.handleSelect}
          options={this.props.corpora.map((corpus) => ({value: corpus.folderId, label: <><FaBook />{` ${corpus.folderName}`}</>}))}
        />
        <Feedback valid={false} errors={this.props.errors.form} forceShow={true} />
      </>
    );
  }
}
