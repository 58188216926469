import React, { Component, FormEventHandler } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import Feedback from "./Feedback";

export interface FormElementProps<TName = string> extends FormControlProps  {
  // FIXME
  controlAs?: any;
  // FIXME
  groupAs?: any;
  name: TName;
  label: string;
  errors?: string | string[];
  required: boolean;
  handleChange: FormEventHandler<this>;
  multiple?: boolean;
}

export default class FormElement extends Component<FormElementProps> {
  public render() {
    const {
      controlAs,
      groupAs,
      name,
      label,
      errors,
      handleChange,
      required,
      isValid,
      ...rest
    } = this.props;

    return (
      <Form.Group controlId={name} as={groupAs}>
        {label && <Form.Label>{label}{required && <sup>*</sup>}</Form.Label>}
        <Form.Control
          name={name}
          as={controlAs}
          onChange={handleChange}
          required={required}
          isValid={isValid === true}
          isInvalid={isValid === false}
          {...rest}
        />
        <Feedback valid={false} errors={errors} />
      </Form.Group>
    );
  }
}
