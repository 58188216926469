import React, { FC } from "react";
import { Image } from "react-bootstrap";

export interface LandingHeaderProps {
  action?: string;
}

export const LandingLogo = () => (
  <div className="landingHeader">
    <Image
      src="https://www.emoto.ai/wp-content/uploads/2018/06/emoto-logo-w-text.png"
      srcSet="https://www.emoto.ai/wp-content/uploads/2018/06/emoto-logo-w-text.png 323w"
      width={161}
      height={38}
      sizes="161.5"
      alt="Emoto"
    />
  </div>
);

const LandingHeader: FC<LandingHeaderProps> = ({ action }) => (
  <div>
    <LandingLogo />
    <div className="welcomeHeader">
      <h1>Hi, I'm EMOTO.AI.</h1>
      {action && <h1>Please {action} to proceed</h1>}
    </div>
  </div>
);

export default LandingHeader;
