// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CorporaTextAllOutputSuccess,
    CorporaTextAllOutputSuccessFromJSON,
    CorporaTextAllOutputSuccessToJSON,
    CorporaTextContentOutput,
    CorporaTextContentOutputFromJSON,
    CorporaTextContentOutputToJSON,
    CorporaTextOutputError,
    CorporaTextOutputErrorFromJSON,
    CorporaTextOutputErrorToJSON,
    CorporaTextOutputSuccess,
    CorporaTextOutputSuccessFromJSON,
    CorporaTextOutputSuccessToJSON,
    TextName,
    TextNameFromJSON,
    TextNameToJSON,
    TokenExpiredOutput,
    TokenExpiredOutputFromJSON,
    TokenExpiredOutputToJSON,
} from '../models';

export interface DeleteCorporaTextTextIdRequest {
    textId: number;
    authorization: string;
}

export interface GetCorporaTextContentRequest {
    textId: number;
    authorization: string;
}

export interface GetCorporaTextOperationsRequest {
    authorization: string;
}

export interface GetCorporaTextTextIdRequest {
    textId: number;
    authorization: string;
}

export interface PutCorporaTextOperationsRequest {
    authorization: string;
    payload: TextName;
}

/**
 * no description
 */
export class TextApi extends runtime.BaseAPI {

    /**
     * Returns info about deleted corpora text.
     * Delete specific corpora text
     */
    async deleteCorporaTextTextIdRaw(requestParameters: DeleteCorporaTextTextIdRequest): Promise<runtime.ApiResponse<CorporaTextOutputSuccess>> {
        if (requestParameters.textId === null || requestParameters.textId === undefined) {
            throw new runtime.RequiredError('textId','Required parameter requestParameters.textId was null or undefined when calling deleteCorporaTextTextId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCorporaTextTextId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/texts/{textId}`.replace(`{${"textId"}}`, encodeURIComponent(String(requestParameters.textId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporaTextOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns info about deleted corpora text.
    * Delete specific corpora text
    */
    async deleteCorporaTextTextId(requestParameters: DeleteCorporaTextTextIdRequest): Promise<CorporaTextOutputSuccess> {
        const response = await this.deleteCorporaTextTextIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns corpora text content and info.
     * Get content and info about specific corpora text
     */
    async getCorporaTextContentRaw(requestParameters: GetCorporaTextContentRequest): Promise<runtime.ApiResponse<CorporaTextContentOutput>> {
        if (requestParameters.textId === null || requestParameters.textId === undefined) {
            throw new runtime.RequiredError('textId','Required parameter requestParameters.textId was null or undefined when calling getCorporaTextContent.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCorporaTextContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/texts/content/{textId}`.replace(`{${"textId"}}`, encodeURIComponent(String(requestParameters.textId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporaTextContentOutputFromJSON(jsonValue));
    }

   /**
    * Returns corpora text content and info.
    * Get content and info about specific corpora text
    */
    async getCorporaTextContent(requestParameters: GetCorporaTextContentRequest): Promise<CorporaTextContentOutput> {
        const response = await this.getCorporaTextContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns user\'s corpora texts (all corpora texts for admin).
     * Get user\'s corpora texts
     */
    async getCorporaTextOperationsRaw(requestParameters: GetCorporaTextOperationsRequest): Promise<runtime.ApiResponse<CorporaTextAllOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCorporaTextOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/texts/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporaTextAllOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns user\'s corpora texts (all corpora texts for admin).
    * Get user\'s corpora texts
    */
    async getCorporaTextOperations(requestParameters: GetCorporaTextOperationsRequest): Promise<CorporaTextAllOutputSuccess> {
        const response = await this.getCorporaTextOperationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns corpora text info.
     * Get info about specific corpora text
     */
    async getCorporaTextTextIdRaw(requestParameters: GetCorporaTextTextIdRequest): Promise<runtime.ApiResponse<CorporaTextOutputSuccess>> {
        if (requestParameters.textId === null || requestParameters.textId === undefined) {
            throw new runtime.RequiredError('textId','Required parameter requestParameters.textId was null or undefined when calling getCorporaTextTextId.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCorporaTextTextId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/texts/{textId}`.replace(`{${"textId"}}`, encodeURIComponent(String(requestParameters.textId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporaTextOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns corpora text info.
    * Get info about specific corpora text
    */
    async getCorporaTextTextId(requestParameters: GetCorporaTextTextIdRequest): Promise<CorporaTextOutputSuccess> {
        const response = await this.getCorporaTextTextIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns corpora text info if renaming is successful.
     * Rename corpora text
     */
    async putCorporaTextOperationsRaw(requestParameters: PutCorporaTextOperationsRequest): Promise<runtime.ApiResponse<CorporaTextOutputSuccess>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling putCorporaTextOperations.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling putCorporaTextOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/texts/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TextNameToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporaTextOutputSuccessFromJSON(jsonValue));
    }

   /**
    * Returns corpora text info if renaming is successful.
    * Rename corpora text
    */
    async putCorporaTextOperations(requestParameters: PutCorporaTextOperationsRequest): Promise<CorporaTextOutputSuccess> {
        const response = await this.putCorporaTextOperationsRaw(requestParameters);
        return await response.value();
    }

}
