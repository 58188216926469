import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Table, Navigation, NavigationProps } from "../../common";
import { HomepageBlock } from "../Home/HomepageBlocks";

export interface CorporaEntity {
  folderName: string;
  folderId: number;
  numberOfTexts: string;
  created: Date;
  texts: TextEntity[];
}

export interface DocumentEntity {
  textName?: string;
}

export interface TextEntity {
  textId: number;
  textName: string;
}

export interface CorporaState {
  corpus: Partial<CorporaEntity>;
}

export interface CorporaRouterProps {
  corpus: string;
}

export interface DocumentRouteProps {
  document: string;
}

class Corpora extends Component<NavigationProps & RouteComponentProps & {block: HomepageBlock; loading: boolean}> {

    public render() {
      const { block, loading } = this.props;
      return (
        <>
          <Navigation {...this.props} />
            <Table
              header={block.table.header}
              body={block.table.body}
              loading={loading}
            />
        </>
      );
    }
}

export default Corpora;
