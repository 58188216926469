import { FolderApi, FolderName } from "../../client/src";
import BaseClient from "./BaseClient";

export class CorporaClient extends BaseClient {
  public id = "CorporaClient";
  private corporaApi = new FolderApi(BaseClient.DEFAULT_CLIENT_CONFIGURATION);

  constructor() {
    super();
    return super.cache(this);
  }

  public async getTable() {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.corporaApi.getCorporaFolderOperations({ authorization: authorizationHeader })),
    );
  }

  public async add(name: string, files: FileList) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.corporaApi.postCorporaFolderOperations({
        authorization: authorizationHeader,
        name,
        files: Array.from(files),
      })),
    );
  }

  public async rename(params: FolderName) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.corporaApi.putCorporaFolderOperations({
        authorization: authorizationHeader,
        payload: params,
      })),
    );
  }

  public async delete(folderId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
      this.corporaApi.deleteCorporaFolderFolderId({
        authorization: authorizationHeader,
        folderId,
      })),
    );
  }

  public async getListOfDocuments(folderId: number) {
    return super.doAuthorizedRequest((authorizationHeader) => (
        this.corporaApi.getCorporaFolderFolderId({
          authorization: authorizationHeader,
          folderId,
        })
      ),
    );
  }
}
