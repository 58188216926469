// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntityId
 */
export interface EntityId {
    /**
     * Specify the id associated with the entity
     * @type {number}
     * @memberof EntityId
     */
    entityId: number;
}

export function EntityIdFromJSON(json: any): EntityId {
    return EntityIdFromJSONTyped(json, false);
}

export function EntityIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
    };
}

export function EntityIdToJSON(value?: EntityId): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
    };
}


