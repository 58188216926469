import React, { FC } from "react";
import { Form } from "react-bootstrap";

interface FeedbackProps {
  valid: false;
  forceShow?: boolean;
  errors?: string | string[];
}

const Feedback: FC<FeedbackProps> = ({
  valid,
  forceShow,
  errors,
}) => (
  <Form.Control.Feedback type={valid ? "valid" : "invalid"} className={forceShow ? "show-me" : undefined}>
    {Array.isArray(errors) ? errors.join(". ") : errors}
  </Form.Control.Feedback>
);

export default Feedback;
