import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isNotEmptyObject } from "../../../utility/element";

export interface RecommendationProps {
  [name: string]: {
    links: string[];
    recommendation: string;
  };
}

const Recommendations = (props: {recommendations: RecommendationProps}) => {
  const category = Object.keys(props.recommendations);
  return (
    <Col md={7}>
      <p>This text signals {category.map((item, index) => {
        const text = category.length - 1 === index
          ? `${item} `
          : category.length - 2 === index
            ? `${item} and `
            : `${item}, `;
        return (
          <span key={`recommendation_name_${index}`}>{text}</span>
        );
      })}in this article.</p>
      {category.map((item: string, index) => (
        <span key={`recommendation_${index}`}>
          <h5>{item}</h5>
          <p>{props.recommendations[item].recommendation}</p>
        </span>
      ))}
    </Col>
  );
};

const Links = (props: {links: RecommendationProps}) => {
  return(
    <Col md={5}>
      <h2>Other research</h2>
      <ul className="recommendationList">
        {Object.keys(props.links).map((category, index) => {
          return isNotEmptyObject(props.links[category].links) && (
            <li key={`link_category_${index}`}>
              <h5>{category}</h5>
              <ul>
                {props.links[category].links.map((link, i) => (
                  <li key={`link_link_${i}`}>
                    <a href={link}>{link}</a>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </Col>
  );
};

class RecommendationBlock extends Component<{recommendations: RecommendationProps}> {
  public render() {
    return (
      <Row className="recommendationBlock">
        {isNotEmptyObject(this.props.recommendations) && <>
          <Links links={this.props.recommendations} />
          <Recommendations recommendations={this.props.recommendations} />
        </>}
      </Row>
    );
  }
}

export default RecommendationBlock;
