// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UrlName
 */
export interface UrlName {
    /**
     * Specify the id associated with the entity.
     * @type {number}
     * @memberof UrlName
     */
    entityId: number;
    /**
     * Name of the entity. Max length: 60 characters. Name should not include ./~\\ symbols.
     * @type {string}
     * @memberof UrlName
     */
    name: string;
}

export function UrlNameFromJSON(json: any): UrlName {
    return UrlNameFromJSONTyped(json, false);
}

export function UrlNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrlName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'name': json['name'],
    };
}

export function UrlNameToJSON(value?: UrlName): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'name': value.name,
    };
}


