import React, { Component, SyntheticEvent } from "react";
import Feedback from "../form/Feedback";
import FormElement, { FormElementProps } from "../form/FormElement";
import { EntityModalProps } from "./index";
import { Form } from "react-bootstrap";
import { isNotEmptyObject } from "../../../utility/element";

interface FromUrlProps {
  name: string;
  handleChange: (event: SyntheticEvent<FormElementProps>) => void;
  errors: { [name: string]: string };
  links: [];
  setEditLegendProps: () => void;
}

class EditFromUrl extends Component<EntityModalProps & FromUrlProps> {
  public componentDidMount(): void {
    this.props.setEditLegendProps();
  }

  public render() {
    const {links} = this.props;
    return (
    <>
      <FormElement
        name="name"
        label="Label"
        value={this.props.name}
        required={true}
        handleChange={this.props.handleChange}
        errors={this.props.errors.name}
      />
      <Form.Label>Link(s)</Form.Label>
      {isNotEmptyObject(links) && links.map((link, index) => (
        <div key={`link_${index}`} className="edit_legend_links">
          <Form.Control disabled={true} placeholder={link} />
        </div>))}
      <Feedback valid={false} errors={this.props.errors.form} forceShow={true} />
    </>
  );
}
}

export default EditFromUrl;
