// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdhocTextData,
    AdhocTextDataFromJSON,
    AdhocTextDataFromJSONTyped,
    AdhocTextDataToJSON,
    CorporaTextEntity,
    CorporaTextEntityFromJSON,
    CorporaTextEntityFromJSONTyped,
    CorporaTextEntityToJSON,
    FolderDataEntity,
    FolderDataEntityFromJSON,
    FolderDataEntityFromJSONTyped,
    FolderDataEntityToJSON,
    UrlEntity,
    UrlEntityFromJSON,
    UrlEntityFromJSONTyped,
    UrlEntityToJSON,
} from './';

/**
 * 
 * @export
 * @interface ModelEntitiesDataSuccess
 */
export interface ModelEntitiesDataSuccess {
    /**
     * 
     * @type {Array<FolderDataEntity>}
     * @memberof ModelEntitiesDataSuccess
     */
    folderEntities: Array<FolderDataEntity>;
    /**
     * 
     * @type {Array<CorporaTextEntity>}
     * @memberof ModelEntitiesDataSuccess
     */
    textEntities: Array<CorporaTextEntity>;
    /**
     * 
     * @type {Array<AdhocTextData>}
     * @memberof ModelEntitiesDataSuccess
     */
    adhocTexts: Array<AdhocTextData>;
    /**
     * 
     * @type {Array<UrlEntity>}
     * @memberof ModelEntitiesDataSuccess
     */
    urls: Array<UrlEntity>;
}

export function ModelEntitiesDataSuccessFromJSON(json: any): ModelEntitiesDataSuccess {
    return ModelEntitiesDataSuccessFromJSONTyped(json, false);
}

export function ModelEntitiesDataSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelEntitiesDataSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folderEntities': (json['folderEntities'] as Array<any>).map(FolderDataEntityFromJSON),
        'textEntities': (json['textEntities'] as Array<any>).map(CorporaTextEntityFromJSON),
        'adhocTexts': (json['adhocTexts'] as Array<any>).map(AdhocTextDataFromJSON),
        'urls': (json['urls'] as Array<any>).map(UrlEntityFromJSON),
    };
}

export function ModelEntitiesDataSuccessToJSON(value?: ModelEntitiesDataSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folderEntities': (value.folderEntities as Array<any>).map(FolderDataEntityToJSON),
        'textEntities': (value.textEntities as Array<any>).map(CorporaTextEntityToJSON),
        'adhocTexts': (value.adhocTexts as Array<any>).map(AdhocTextDataToJSON),
        'urls': (value.urls as Array<any>).map(UrlEntityToJSON),
    };
}


