export enum ROUTE {
  SEPARATOR = "/",
  ROOT = SEPARATOR,
  RESET_PASS = "/password/reset",
  LOGIN = "/login",
  REGISTER = "/register",
  FORGOT_PASS = "/password/forgot",
  TERMS_OF_SERVICE = "/terms-of-service",
  CORPORA_ROOT = "/corpora",
  CORPORA_CORPUS = "/corpora/:corpus",
  CORPORA_DOCUMENT_LIST = "/corpora/:corpus/documents",
  CORPORA_DOCUMENT = "/corpora/:corpus/documents/:document",
  MODELS_ROOT = "/models",
  MODELS_MODEL = "/models/:model",
  HEATMAP = "/models/heatmap/:entityType/:entityId",
}
