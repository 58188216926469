import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";

import { Table } from "../../common";
import { HeaderProps } from "../../common/Table/Header";
import { BodyProps } from "../../common/Table/Body";

export interface HomepageBlock {
  table: {
    header: HeaderProps,
    body: BodyProps,
    loading: boolean,
  };
}

interface HomepageBlockProps {
  blocks: HomepageBlock[];
}

export const HomepageBlocks: FC<HomepageBlockProps> = ({
  blocks,
}) => (
  <Row>
    {blocks.map(({ table }, index) => {
      return (
        <Col md={6} key={index} className="homepage_block_col">
          <Table
            header={table.header}
            body={table.body}
            loading={table.loading}
          />
        </Col>
      );
    })}
  </Row>
);
