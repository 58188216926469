// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResetTokenDataSuccess,
    ResetTokenDataSuccessFromJSON,
    ResetTokenDataSuccessFromJSONTyped,
    ResetTokenDataSuccessToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResetTokenOutputSuccess
 */
export interface ResetTokenOutputSuccess {
    /**
     * 
     * @type {ResetTokenDataSuccess}
     * @memberof ResetTokenOutputSuccess
     */
    data: ResetTokenDataSuccess;
    /**
     * There are not any errors.
     * @type {string}
     * @memberof ResetTokenOutputSuccess
     */
    errors: string;
}

export function ResetTokenOutputSuccessFromJSON(json: any): ResetTokenOutputSuccess {
    return ResetTokenOutputSuccessFromJSONTyped(json, false);
}

export function ResetTokenOutputSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetTokenOutputSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ResetTokenDataSuccessFromJSON(json['data']),
        'errors': json['errors'],
    };
}

export function ResetTokenOutputSuccessToJSON(value?: ResetTokenOutputSuccess): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ResetTokenDataSuccessToJSON(value.data),
        'errors': value.errors,
    };
}


