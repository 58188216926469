// tslint:disable
/**
 * API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HeatmapNgram,
    HeatmapNgramFromJSON,
    HeatmapNgramFromJSONTyped,
    HeatmapNgramToJSON,
} from './';

/**
 * 
 * @export
 * @interface HeatmapData
 */
export interface HeatmapData {
    /**
     * 
     * @type {HeatmapNgram}
     * @memberof HeatmapData
     */
    metadata: HeatmapNgram;
    /**
     * Analyzed text.
     * @type {string}
     * @memberof HeatmapData
     */
    text: string;
}

export function HeatmapDataFromJSON(json: any): HeatmapData {
    return HeatmapDataFromJSONTyped(json, false);
}

export function HeatmapDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeatmapData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': HeatmapNgramFromJSON(json['metadata']),
        'text': json['text'],
    };
}

export function HeatmapDataToJSON(value?: HeatmapData): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': HeatmapNgramToJSON(value.metadata),
        'text': value.text,
    };
}


