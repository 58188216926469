import React, { PureComponent, ReactNode } from "react";
import { Button, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { isNotEmptyObject } from "../../../utility/element";
import Checkbox from "../../common/form/Checkbox";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FilterColors } from "../../../constants";

interface Analysis {
  cs: string;
  dictionary: string;
  sentiment: string;
  weight: number;
}

interface MetadataBit {
  start: number;
  end: number;
  analysis: Analysis[];
}

interface ChangeSpec extends MetadataBit {
  ngram: number;
}

enum Gram {
  ONE = "_1",
  TWO = "_2",
  THREE = "_3",
  FOUR = "_4",
  FIVE = "_5",
}

const PRETTY_GRAM_NAMES: { [k in Gram]: string } = {
  [Gram.ONE]: "1",
  [Gram.TWO]: "2",
  [Gram.THREE]: "3",
  [Gram.FOUR]: "4",
  [Gram.FIVE]: "5",
};

enum Sentiment {
  SEED = "seed",
  POSITIVE = "positive",
  NEGATIVE = "negative",
}

interface HeatmapState {
  checkedGrams: Set<string>;
  checkedSentiment: Set<string>;
  checkedCses: Set<string>;
  allCses: string[];
  colors: {
    [name: string]: string;
  };
  isVisible: boolean;
}

interface HeatmapProps {
  data: {
    text: string,
    metadata: {
      [i: string]: MetadataBit[],
    },
  };
}

enum FilterShow {
  SHOW = "show",
  HIDE = "hide",
}

export class Heatmap extends PureComponent<HeatmapProps, HeatmapState> {
  constructor(props: HeatmapProps) {
    super(props);

    this.state = {
      checkedGrams: new Set(Object.values(Gram)),
      checkedSentiment: new Set(Object.values(Sentiment)),
      checkedCses: new Set(),
      allCses: [],
      colors: {},
      isVisible: true,
    };
  }

  public componentDidUpdate(prevProps: HeatmapProps) {
    if (this.props.data !== prevProps.data) {
      const cses = new Set(Object.values(this.props.data.metadata)
        .flatMap((e) => e.flatMap((i) => i.analysis.flatMap((j) => j.cs))));
      const colorCount = Object.values(FilterColors).length;

      const truncatedSortedNames: string[] = [];
      const colors: { [name: string]: string } = {};
      let colorIndex = 0;
      for (const cs of cses) {
        truncatedSortedNames.push(Heatmap.truncateName(cs));
        colorIndex = colorIndex === colorCount ? 0 : colorIndex;
        colors[Heatmap.truncateName(cs)] = FilterColors[colorIndex++];
      }

      // FIXME: insert into array in desired order
      truncatedSortedNames.sort();

      this.setState({
        checkedCses: new Set(truncatedSortedNames),
        allCses: truncatedSortedNames,
        colors,
      });
    }
  }

  public handleShow = (show: boolean) => {
    const checkedGrams = show ? new Set(Object.values(Gram)) : new Set();
    const checkedSentiment = show ? new Set(Object.values(Sentiment)) : new Set();
    const checkedCses: any = show ? new Set(this.state.allCses) : new Set();

    this.setState({
      checkedGrams,
      checkedSentiment,
      checkedCses,
      isVisible: show,
    });
    this.forceUpdate();
  }

  public handleSentiment = (e: any) => {
    const sentimentItem = e.currentTarget.name;
    const checkedSentiment = this.state.checkedSentiment;
    if (checkedSentiment.has(sentimentItem)) {
      checkedSentiment.delete(sentimentItem);
    } else {
      checkedSentiment.add(sentimentItem);
    }
    this.setState({
      checkedSentiment,
    });
    this.forceUpdate();
  }

  public handleGram = (gram: Gram) => {
    const checkedGrams = this.state.checkedGrams;
    if (checkedGrams.has(gram)) {
      checkedGrams.delete(gram);
    } else {
      checkedGrams.add(gram);
    }
    this.setState({
      checkedGrams,
    });
    this.forceUpdate();
  }

  private handleCs(cs: string) {
    const { checkedCses } = this.state;
    if (checkedCses.has(cs)) {
      checkedCses.delete(cs);
    } else {
      checkedCses.add(cs);
    }
    this.setState({
      checkedCses,
    });
    this.forceUpdate();
  }

  private checkAndSetAnalysis(ngram: MetadataBit) {
    const { checkedSentiment, checkedCses } = this.state;
    const passed = [];

    for (const a of ngram.analysis) {
      if (checkedCses.has(Heatmap.truncateName(a.cs)) && checkedSentiment.has(a.sentiment)) {
        passed.push(a);
      }
    }
    ngram.analysis = passed;
    return !!passed.length;
  }

  public render() {
    const { text, metadata } = this.props.data;
    const { checkedGrams, checkedSentiment, allCses, checkedCses, colors }: any = this.state;

    const changeSpecByBoundaries: { [k: string]: ChangeSpec } = {};

    if (checkedSentiment.has(Sentiment.SEED)) {
      for (const n of checkedGrams) {
        const ngram = metadata[n];
        if (ngram && checkedGrams.has(n)) {
          for (const metadataBit of ngram) {
            const metadataBitCopy = { ...metadataBit };
            if (this.checkAndSetAnalysis(metadataBitCopy)) {
              const start = metadataBitCopy.start;
              const end = metadataBitCopy.end;
              const key = `${start}_${end}`;

              changeSpecByBoundaries[key] = {
                start,
                end,
                ngram: n,
                analysis: metadataBitCopy.analysis,
              };
          }
        }
      }
    }
    }

    const result: any = [];
    const filterColors: ReactNode[] = [];
    const filterGrams: ReactNode[] = [];
    let tip = 0;

    const sortedSpecs = Object.values(changeSpecByBoundaries).sort((a, b) => a.start - b.start || b.end - a.end);
    for (let i = 0; i < sortedSpecs.length; ++i) {
      const spec = sortedSpecs[i];
      if (tip < spec.start) {
        result.push(text.slice(tip, spec.start));
      }

      const info = spec.analysis.map(Heatmap.formatAnalysisForTooltip).join("\n");

      const firstColor = spec.analysis.find((item) => checkedCses.has(Heatmap.truncateName(item.cs)));
      const backgroundColor = firstColor ? colors[Heatmap.truncateName(firstColor.cs)] : "";
      const border = firstColor && firstColor.weight === -1 ? "1px solid black" : "";

      if (tip > spec.start) {
        const item = result[result.length - 1];
        const word = item.props.children[0];
        const key = item.key;
        const style = {...item.props.style, padding: "2px"};

        const innerWord = text.slice(spec.start, spec.end + 1);

        const hint = `${item.props.children[1].props.children}\n\nAnother matches:\nToken:${innerWord}\n${info}`;

        const startWord = word.slice(0, word.indexOf(innerWord));
        const endWord = word.slice(startWord.length + innerWord.length);

        result[result.length - 1] = <span
          key={key}
          className="seed"
          style={style}
        >
          {startWord}
            <span
              key={`span-${spec.start}-${spec.end}`}
              className="seed"
              style={{backgroundColor, border, padding: 0}}
            >
              {`${innerWord}`}
            </span>
          {endWord}
          <span className="hint">{hint}</span>
        </span>;
        continue;
      }

      tip = spec.end + 1;
      result.push(
        <span
          key={`span-${spec.start}-${spec.end}`}
          className="seed"
          style={{ backgroundColor, border }}
        >
            {`${text.slice(spec.start, tip)}`}
          <span className="hint">{info}</span>
        </span>,
      );
    }

    if (tip < text.length - 1) {
      result.push(text.slice(tip, text.length));
    }

    for (const g of Object.values(Gram)) {
      const prettyName = PRETTY_GRAM_NAMES[g as Gram];

      filterGrams.push(
        <Checkbox
          key={`checkbox-${prettyName}`}
          checked={checkedGrams.has(g)}
          label={`${prettyName}-grams`}
          name={`grams${prettyName}`}
          required={false}
          handleChange={() => this.handleGram(g)}
        />,
      );
    }

    for (const cs of allCses) {
      filterColors.push(
        <Checkbox
          color={colors[cs]}
          key={`checkbox-${cs}`}
          checked={checkedCses.has(cs)}
          label={cs}
          name={`cses_${cs}`}
          required={false}
          handleChange={() => this.handleCs(cs)}
        />,
      );
    }

    return isNotEmptyObject(result) && (
      <Row>
        <Col md={9}>{result}</Col>
        <Col md={3}>
          <ListGroup>
            <ListGroupItem variant="secondary">Filter</ListGroupItem>
            <ListGroupItem>{filterColors}</ListGroupItem>
            <ListGroupItem>
              <Checkbox
                checked={checkedSentiment.has(Sentiment.SEED)}
                label="Seed words"
                name={Sentiment.SEED}
                required={false}
                handleChange={this.handleSentiment}
              />
              <Checkbox
                checked={checkedSentiment.has(Sentiment.POSITIVE)}
                label="Positive seed words"
                name={Sentiment.POSITIVE}
                required={false}
                handleChange={this.handleSentiment}
              />
              <Checkbox
                checked={checkedSentiment.has(Sentiment.NEGATIVE)}
                label="Negative seed words"
                name={Sentiment.NEGATIVE}
                required={false}
                handleChange={this.handleSentiment}
              />
            </ListGroupItem>
            <ListGroupItem>{filterGrams}</ListGroupItem>
            <ListGroupItem className="option_buttons sentiment">
              <Button
                size="sm"
                variant="outline-secondary"
                name={FilterShow.SHOW}
                onClick={() => this.handleShow(true)}
              ><FaEye /> Show all</Button>
              <Button
                size="sm"
                name={FilterShow.HIDE}
                variant="outline-secondary"
                onClick={() => this.handleShow(false)}
              ><FaEyeSlash /> Hide all</Button>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
    );
  }

  private static truncateName(name: string) {
    if (name != null) {
      return name.split(/\d+. /)[1];
    }

    return "";
  }

  private static formatAnalysisForTooltip(analysis: Analysis) {
    return `CS: ${analysis.cs}\nDictionary: ${analysis.dictionary}\nWeight: ${Number(analysis.weight).toFixed(1)}`;
  }
}
